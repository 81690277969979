import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAppInitialized: false,
  initializingStatus: {
    fetchedAcademicYears: false,
    fetchedZones: false,
    fetchedBranches: false,
    fetchedBatches: false,
    fetchCounsellors: false,
    fetchedInstituteConfig: false,
    // fetchedFeePlans: false,
    fetchedAllExams: false,
  },
  initalizationErrors: [],
  academicYear: null,
  currentAcademicYear: null,
  alertInfo: { alertOpen: false, msg: "No Message" },
  accessLevels: ["organization", "zonal", "branch"],
  academicYears: [],
  error: "",
  inputs: {},
  isProcessing: false,
  processStatus: {},
  processType: "",
  count: 0,
  adminRoute: "",
  instituteId: null,
  layout: "main",
  operations: [],
  employees: [],
  loginId: "",
  loginUser: {},
};

const appSlice = createSlice({
  name: "APP",
  initialState,
  reducers: {
    fetchBranchesAndBatchesStart() {},
    setIfAppInitialized(state, action) {
      state.isAppInitialized = action.payload || true;
    },
    setInitializationStatus(state, action) {
      state.initializingStatus = {
        ...state.initializingStatus,
        ...action.payload,
      };
    },
    setInitializationErrors(state, action) {
      if (action.payload === "reset") state.initalizationErrors = [];
      state.initalizationErrors = [
        ...state.initalizationErrors,
        action.payload,
      ];
    },
    setAcademicYear(state, action) {
      state.academicYear = action.payload;
    },
    setLoginId(state, action) {
      state.loginId = action.payload;
    },
    setCurrentAcademicYear(state, action) {
      state.currentAcademicYear = action.payload;
    },
    setProcessType(state, action) {
      state.processType = action.payload;
    },
    setStartOrEndOfBackdropProcess(state, action) {
      state.isProcessing = action.payload;
      if (action.payload === false) state.processType = null;
    },
    setProcessStatus(state, action) {
      state.processStatus = { ...action.payload };
    },
    resetBackdropProcess(state) {
      state.processStatus = {};
      state.processName = "";
      state.processType = "";
      state.query = {};
    },
    setAdminRoute(state, action) {
      state.adminRoute = action.payload;
    },
    setInstituteId(state, action) {
      state.instituteId = action.payload;
    },
    setAppAlertInfo(state, action) {
      state.alertInfo = action.payload;
    },
    resetAlertInfo(state) {
      state.alertInfo = { alertOpen: false, msg: "No Message" };
    },
    resetApp(state) {
      state.isAppInitialized = false;
      state.initializingStatus = {
        fetchedAcademicYears: false,
        fetchedZones: false,
        fetchedBranches: false,
        fetchedBatches: false,
        fetchCounsellors: false,
      };
    },
    setLayoutType(state, action) {
      state.layout = action.payload;
    },
    approveConcessionReqStart() {},
    approveConcessionReqSuccess() {},
    intializeIndexedDb() {},
    startFetchingEmployees() {},
    fetchingEmployeesSuccess(state, action) {
      state.employees = action.payload;
    },
    clearEmployees(state) {
      state.employees = [];
    },
    setLoginUser(state, action) {
      state.loginUser = action.payload;
    },
    // TODO: Add reducer to clear login user
    clearLoginUser(state) {
      state.loginUser = {};
    },
  },
});

export const {
  setIfAppInitialized,
  setAcademicYear,
  setInitializationStatus,
  setInitializationErrors,
  setStartOrEndOfBackdropProcess,
  setProcessStatus,
  resetBackdropProcess,
  setCurrentAcademicYear,
  startQuery,
  setProcessType,
  setAdminRoute,
  setInstituteId,
  setAppAlertInfo,
  resetAlertInfo,
  resetApp,
  setLayoutType,
  approveConcessionReqStart,
  approveConcessionReqSuccess,
  intializeIndexedDb,
  startFetchingEmployees,
  fetchingEmployeesSuccess,
  clearEmployees,
  setLoginId,
  setLoginUser,
  clearLoginUser,
  fetchBranchesAndBatchesStart,
} = appSlice.actions;

export default appSlice.reducer;
