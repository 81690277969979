// respective modules
import organizationConfig from "./modules/organization";
import addmissionsConfig from "./modules/admissions";
import settingsConfig from "./modules/settings";
import financeConfig from "./modules/finance";
import attendanceConfig from "./modules/attendance";
import academics from "./modules/academics";
// import academics from "./modules/academics";
import communications from "./modules/communications";
import analysis from "./modules/analysis";
import questions from "./modules/questions";
import liveClasses from "./modules/liveClasses";
import offLine from "./modules/offLine";
import Expenses from "./modules/Expenses";
import hostelConfig from "./modules/hostel";
import transportMang from "./modules/transportMang";
import payrollAndHr from "./modules/payrollAndHr";
import InventoryMgt from "./modules/InventoryMgt";
import dashboard from "./modules/dashboard";
// admin portal
// test
// import test from "./test";

export default [
  dashboard,
  organizationConfig,
  addmissionsConfig,
  financeConfig,
  attendanceConfig,
  liveClasses,
  questions,
  analysis,
  communications,
  academics,
  offLine,
  payrollAndHr,
  InventoryMgt,
  transportMang,
  Expenses,
  hostelConfig,
  settingsConfig,
];
