import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  query: null,
  temp1: null,
  temp2: null,
  temp3: null,
};

const tempSlice = createSlice({
  name: "TEMP",
  initialState,
  reducers: {
    setTempData(state, action) {
      const { key, data } = action.payload;
      state[key || "temp1"] = data;
    },
    clearTempData(state, action) {
      if (action.payload) state[action.payload] = "";
      else {
        state.query = null;
        state.temp1 = null;
        state.temp2 = null;
        state.temp3 = null;
      }
    },
    fetchTempData(state, action) {
      state.query = action.payload;
    },
    fetchStatesAndDistricts() {},
  },
});

export const {
  setTempData,
  clearTempData,
  fetchTempData,
  fetchStatesAndDistricts,
} = tempSlice.actions;

export default tempSlice.reducer;
