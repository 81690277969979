import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import store from "store";
// formik
import { useFormik } from "formik";
import CacheBuster from "react-cache-buster";
// @mui material myComponents
import { useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
// Soft UI Dashboard React myComponents
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftNotification from "myComponents/SoftNotification";
import SoftTypography from "myComponents/SoftTypography";
// Authentication layout myComponents
import CoverLayout from "layout/LayoutComponents/CoverLayout";
// import BasicLayout from "layout/LayoutComponents/BasicLayout";
// password valiation
import signinSchema from "validation/signinSchema";
// apis
import { userLogin } from "APIs/UserServices";
// custom hook
import useFetch from "hooks/useFetch";
// actions
import { setLoginUser } from "redux/app/app.slice";
// Images
// import curved6 from "assets/images/curved-images/curved-6.jpg";
import indexImg from "assets/images/indexImg.png";
import onesaz from "assets/onesaz_mini.png";
import Spinner from "components/Spinner";
import logoURLMap from "layout/primeLayout/logoURLMap";
import { institutesMap } from "views/Live/ZoomApp/institutes";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import packageObj from "../../../package.json";
import KeyIcon from "./KeyIcon";
import MPINInput from "./MPINInput";
// import Socials from "./socials";

const initialValues = {
  employeeId: "",
  password: "",
};

function SignIn() {
  // const [rememberMe, setRememberMe] = useState(true);
  const { hostname } = window.location;
  const ifExpense = hostname.includes("expenses");
  const inst = institutesMap[hostname];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [myHost, setMyHost] = useState("");
  const sessionExpired = store.get("sessionExpired");
  const employeeId_MPIN = store.get("employeeId");
  const institute = store.get("instituteId");
  const ifMpinLoginRequired =
    employeeId_MPIN &&
    ["5d679d49c136660a09596d85", "65772522ee64cfa8dc9851f2"].includes(
      institute
    );
  const logoObj = logoURLMap[institute];
  // const [checked, setChecked] = useState(true);
  // const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [touched, setIfTouched] = useState(false);
  const [enteredMPIN, setEnteredMPIN] = useState("");

  const [isUserLoading, userError, response, fetchUser, resetFetch] =
    useFetch(userLogin);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const redirectPath =
    location.state?.path && location.state?.path !== "/change-password"
      ? location.state.path
      : ifExpense
      ? "/app/expense-management"
      : "/app";
  // initialize formik and set initial values
  const formik = useFormik({
    initialValues,
    validationSchema: signinSchema,
    onSubmit: async (credentials) => {
      setIfTouched(true);
      fetchUser({
        ...credentials,
        employeeCode: `${myHost ? institutesMap[myHost] : inst}_${
          credentials.employeeId
        }`,
      });
    },
  });

  const msgs = {
    prevPath: "Password changed successfully, Sign in with new password",
    error: userError || "something went wrong...",
    success: "Successfully logged in",
    expired: "Token expired, Login again",
    logingIn: "Logging In Pleas wait...",
    mismatch: "Invalid MPIN!",
    offline: "Please check your internet connection and try again.",
  };

  useEffect(() => {
    // console.log(isUserLoading);
    if (response) {
      const { user, accessToken } = response;
      // for admin purposes
      const { branch, phone, fullName, employeeId, instituteId } = user || {};
      store.set("user", {
        userId: employeeId,
        name: fullName,
        phoneno: phone,
        branchId: branch,
        ...user,
      });
      dispatch(
        setLoginUser({
          userId: employeeId,
          name: fullName,
          assignedBranches: user.assignedBranches,
          assignedZones: user.assignedZones,
          accessLevel: user.accessLevel,
          designation: user.designation,
        })
      );
      // dispatch(setLoginId(employeeId));
      store.set("instituteId", instituteId);
      store.set("sessionExpired", false);
      if (accessToken) {
        store.set("accessToken", accessToken);
        if (
          user.mPin ||
          !["5d679d49c136660a09596d85", "65772522ee64cfa8dc9851f2"].includes(
            instituteId
          )
        ) {
          navigate(redirectPath, { replace: true });
        } else if (
          !user.addedPrivileges ||
          !user.addedPrivileges.includes("enable_MPIN")
        ) {
          navigate(redirectPath, { replace: true });
        } else {
          navigate("/set-mpin", { replace: true });
        }
      } else navigate("/change-password", { replace: true });
    }
    if ((touched && !isUserLoading) || isUserLoading) setOpen(true);
  }, [response, isUserLoading]);

  useEffect(() => {
    if (location.state?.path === "/change-password") setOpen(true);
  }, [location.state?.path]);

  useEffect(() => {
    if (sessionExpired) setOpen(true);
  }, [sessionExpired]);

  const handleClose = () => {
    resetFetch();
    if (sessionExpired) store.set("sessionExpired", false);
    setOpen(false);
  };
  const getFeedback = () => {
    if (isUserLoading) return { msg: msgs.logingIn, type: "info" };
    if (sessionExpired) return { msg: msgs.expired, type: "error" };
    if (!touched && location.state?.path === "/change-password")
      return { msg: msgs.prevPath, type: "success" };
    if (response) return { msg: msgs.success, type: "success" };
    if (!isOnline) return { msg: msgs.offline, type: "warning" };
    // if (enteredMPIN.length !== 4) return { msg: msgs.mismatch, type: "error" };

    return { msg: msgs.error, type: "error" };
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleMPIN = (mpin) => {
    setEnteredMPIN(mpin);
  };
  const handleValidateMPIN = () => {
    if (enteredMPIN.length === 4) {
      fetchUser({
        mPin: enteredMPIN,
        employeeCode: `${inst}_${employeeId_MPIN}`,
      });
    } else {
      setOpen(true);
    }
  };

  const renderSignInFields = () => {
    if (ifMpinLoginRequired) {
      return (
        <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
          {/* <SoftBox mb={2} mt={2} display="flex"> */}
          <SoftBox
            component="img"
            src={logoObj ? logoObj.url : onesaz}
            alt="brand_logo"
            width="5rem"
          />
          <SoftTypography variant="subtitle1" fontWeight="bold">
            {logoObj ? logoObj.title : "ONESAZ"}
          </SoftTypography>
          {/* </SoftBox> */}
          <MPINInput
            title={
              <>
                Enter your MPIN&nbsp;
                <KeyIcon />
              </>
            }
            onMPINChange={handleMPIN}
          />
          <br />
          <SoftBox>
            <Typography variant="caption">
              Forgot your MPIN?&nbsp;
              <Typography
                component="span"
                color="#08a1c4"
                variant="caption"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forgot-mpin")}
              >
                Click here
              </Typography>
            </Typography>
          </SoftBox>
          <br />
          <SoftButton
            variant="contained"
            color="info"
            onClick={handleValidateMPIN}
            fullWidth
          >
            Validate
          </SoftButton>
        </SoftBox>
      );
    }
    return (
      <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
        <SoftBox
          mb={2}
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SoftBox
            component="img"
            src={onesaz}
            alt="UI Logo"
            width="8rem"
            // height="3rem"
          />
          {/* <Socials /> */}
        </SoftBox>
        <SoftBox component="form" role="form" onSubmit={formik.handleSubmit}>
          {process.env.NODE_ENV === "development" ||
          window.location.hostname === "t-admin.onesaz.com" ? (
            <SoftBox mb={1}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Hostname
              </SoftTypography>
              <SoftAutoComplete
                simpleOptions
                list={Object.keys(institutesMap)}
                selected={myHost}
                onChange={(e, i) => setMyHost(i)}
              />
            </SoftBox>
          ) : null}
          <SoftBox mb={1}>
            <SoftBox mb={0.5} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Employee Id
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="employeeId"
              type="text"
              placeholder="EmployeeId"
              value={formik.values.employeeId}
              error={
                formik.touched.employeeId && Boolean(formik.errors.employeeId)
              }
              onChange={formik.handleChange}
            />
            {formik.touched.employeeId && Boolean(formik.errors.employeeId) ? (
              <SoftTypography
                variant="caption"
                color={
                  formik.touched.employeeId && Boolean(formik.errors.employeeId)
                }
              >
                {formik.errors.employeeId}
              </SoftTypography>
            ) : null}
          </SoftBox>
          <SoftBox mb={1}>
            <SoftBox mb={0.5} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="password"
              type="password"
              placeholder="Password"
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              onChange={formik.handleChange}
            />
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <SoftTypography
                variant="caption"
                color={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              >
                {formik.errors.password}
              </SoftTypography>
            ) : null}
          </SoftBox>
          {/* <SoftBox display="flex" alignItems="center">
      <Switch checked={rememberMe} onChange={handleSetRememberMe} />
      <SoftTypography
        variant="button"
        fontWeight="regular"
        onClick={handleSetRememberMe}
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        &nbsp;&nbsp;Remember me
      </SoftTypography>
    </SoftBox> */}
          <SoftBox>
            <Typography variant="caption">
              Forgot Password?&nbsp;
              <Typography
                component="span"
                color="#08a1c4"
                variant="caption"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forgot-password")}
              >
                Click here
              </Typography>
            </Typography>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              type="submit"
              variant="contained"
              color="info"
              fullWidth
            >
              sign in
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    );
  };
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageObj.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Spinner />}
    >
      <CoverLayout
        ifLogoRequired={ifMpinLoginRequired}
        title={
          ifMpinLoginRequired ? `Hello ${employeeId_MPIN}` : "Welcome back"
        }
        description={
          ifMpinLoginRequired ? (
            <>
              Enter your secure MPIN key&nbsp;
              <KeyIcon />
              &nbsp;here.
            </>
          ) : (
            "Enter your employee Id and password to sign in"
          )
        }
        image={indexImg}
      >
        <SoftNotification
          open={isOpen}
          close={handleClose}
          color={getFeedback().type}
        >
          {getFeedback().msg}
        </SoftNotification>
        {isMobile ? (
          renderSignInFields()
        ) : (
          <Card
            sx={{
              width: ifMpinLoginRequired ? "max-content" : "initial",
            }}
          >
            {renderSignInFields()}
          </Card>
        )}
      </CoverLayout>
    </CacheBuster>
  );
}

export default SignIn;
