import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== "cellBorder",
})(({ theme, cellBorder }) => {
  const borderColor = "#e9ecef";
  const styles = {
    border: "none",
    "& .custom-style-row-summary": {
      backgroundColor: theme.palette.info.lightest,
      ":hover": {
        backgroundColor: theme.palette.info.lightest,
      },
    },

    "& .MuiDataGrid-colCell": {
      textTransform: "capitalize",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      display: "block",
      // color: theme.palette.secondary.main,
      color: "#31456A",
      // borderBottom: "0.0625rem solid #e9ecef",
      fontSize: "0.65rem",
      fontWeight: 700,
      textTransform: "uppercase",
      opacity: 0.7,
      userSelect: "none",
      margin: 0,
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      "& .MuiDataGrid-cellContent": {
        margin: 0,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "0.75rem",
        lineHeight: 1.25,
        letterSpacing: "0.03333em",
        opacity: 1,
        textTransform: "none",
        verticalAlign: "unset",
        textDecoration: "none",
        color: "rgb(103, 116, 142)",
        fontWeight: 500,
      },
      "& .MuiDataGrid-cellValue": {
        display: "flex",
        alignItems: "center",
      },
      "& .MuiSelect-icon": {
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& .dataGrid-options-container:empty::before": {
        color: "gray",
      },
    },
    "& .MuiDataGrid-row": {
      "&:nth-child(even)": {
        backgroundColor: theme.palette.grey[100],
      },
      "&.Mui-selected": {
        backgroundColor: "#E7F7FD",
        "&:hover": {
          backgroundColor: theme.palette.info.lightest,
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },

    "& .MuiDataGrid-virtualScroller": {
      overflowX: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          "& .MuiDataGrid-cell": {
            "& .MuiInputBase-root.MuiDataGrid-editInputCell": {
              height: "95% !important",
              overflowX: "auto",
            },
          },
        },
      },
    },

    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiInputBase-root": {
            width: 100,
          },
        },
      },
    },

    "& .MuiInputBase-root-MuiTablePagination-select": {
      width: "10%",
    },
  };

  if (cellBorder) {
    styles["& .MuiDataGrid-cell"] = {
      border: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    };
    styles["& .MuiDataGrid-columnHeader"] = {
      border: `1px solid ${borderColor}`,
    };
    styles["& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell"] = {
      borderBottomColor: `${borderColor} !important`,
    };
  }
  return styles;
});

export default StyledDataGrid;
