import store from "store";
import { SELECT_TYPES, FIELD_TYPES } from "../../config/globalConsts";

export const phaseDetails = ({ selectType = SELECT_TYPES.SINGLE }) => ({
  label2: "Phase",
  key: "label2",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  getList: () => [
    { label1: "Enquiry", label2: "Enquires", value: "enquiry" },
    { label1: "Appilication", label2: "Applications", value: "application" },
    { label1: "Admission", label2: "Admissions", value: "admission" },
  ],
});

export const gender = ({ name }) => ({
  name,
  label2: "Gender",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ],
});

export const caste = ({ name }) => ({
  name,
  label2: "Caste",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "OC", value: "OC" },
    { label: "OBC", value: "OBC" },
    { label: "SC", value: "SC" },
    { label: "ST", value: "ST" },
  ],
});
export const subCaste = ({ name }) => ({
  name,
  label2: "sub Caste",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "OC", value: "OC" },
    { label: "OBC", value: "OBC" },
    { label: "BC-A", value: "BC-A" },
    { label: "BC-B", value: "BC-B" },
    { label: "BC-C", value: "BC-C" },
    { label: "BC-D", value: "BC-D" },
    { label: "BC-E", value: "BC-E" },
    { label: "SC-B", value: "SC-B" },
    { label: "SC-C", value: "SC-C" },
    { label: "ST", value: "ST" },
  ],
});

export const admissionType = ({ name }) => ({
  name,
  label2: "Admission Type",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "Resdential", value: "resdential" },
    { label: "Dayscholar", value: "dayscholar" },
    { label: "Semi Residential", value: "semi_resdential" },
  ],
});

export const applicationStatus = ({ name }) => ({
  name,
  label2: "Application Status",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "Pending", value: true },
    { label: "Completed", value: false },
  ],
});

export const source = ({ name }) => {
  const inst = store.get("instituteId");
  const options =
    inst === "65772522ee64cfa8dc9851f2"
      ? [
          { label: "Campus walk-in", value: "Campus walk-in" },
          {
            label: "CAMPAIGN",
            value: "CAMPAIGN",
          },
        ]
      : [
          { label: "Campus walk-in", value: "Campus walk-in" },
          { label: "Website", value: "Website" },
          {
            label: "Others",
            value: "Others",
          },
          {
            label: "CAMPAIGN",
            value: "CAMPAIGN",
          },
        ];
  return {
    name,
    label2: "source",
    key: "label",
    type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
    getList: () => options,
  };
};

export const religion = ({ name }) => ({
  name,
  label2: "Religion",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "Hinduism", value: "Hinduism" },
    { label: "Islam", value: "Islam" },
    { label: "Christianity", value: "Christianity" },
    { label: "Sikhism", value: "Sikhism" },
    { label: "Buddhism", value: "Buddhism" },
    { label: "Jainism", value: "Jainism" },
    { label: "Other", value: "Other" },
  ],
});
export const nationality = ({ name }) => ({
  name,
  label2: "Nationality",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [{ label: "Indian", value: "Indian" }],
});

export const bloodGroup = ({ name }) => ({
  name,
  label2: "Blood Group",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B", value: "B" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
  ],
});

export const motherTounge = ({ name }) => ({
  name,
  label2: "Mother Tounge",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "Telugu", value: "Telugu" },
    { label: "Hindi", value: "Hindi" },
    { label: "Lambada", value: "Lambada" },
    { label: "Mathura", value: "Mathura" },
    { label: "Rajasthani", value: "Rajasthani" },
    { label: "Bhojpuri", value: "Bhojpuri" },
    { label: "Odia", value: "Odia" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Nepali", value: "Nepali" },
    { label: "Chhattisgarhi", value: "Chhattisgarhi" },
    { label: "Sinhala", value: "Sinhala" },
    { label: "Romani", value: "Romani" },
    { label: "Bengali", value: "Bengali" },
    { label: "Maithili", value: "Maithili" },
    { label: "Nepalese", value: "Nepalese" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Tamil", value: "Tamil" },
    { label: "Urdu", value: "Urdu" },
    { label: "Assamese", value: "Assamese" },
    { label: "Dogri", value: "Dogri" },
    { label: "Kannada", value: "Kannada" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Bodo", value: "Bodo" },
    {
      label: "Manipur (also known as Meitei)",
      value: "Manipur (also known as Meitei)",
    },
    { label: "Oriya", value: "Oriya" },
    { label: "Marathi", value: "Marathi" },
    { label: "Santali", value: "Santali" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Konkani", value: "Konkani" },
    { label: "Kashmiri", value: "Kashmiri" },
    { label: "Marathi", value: "Marathi" },
    { label: "Mathura", value: "patkari" },
  ],
});
export const groupBy = ({ name }) => ({
  name,
  label2: "Group By",
  key: "label",
  idName: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [{ label: "class", value: "class" }],
});

export const punch = ({ name }) => ({
  name,
  label2: "Log Type",
  key: "label",
  idName: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "in", value: "in" },
    { label: "out", value: "out" },
  ],
});

export const duration = ({ name, time }) => ({
  name,
  label2: "Duration",
  key: "label",
  idName: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => {
    const setStartOfDay = (date) =>
      time ? date.setHours(0, 0, 0, 0).getTime() : date.setHours(0, 0, 0, 0);

    const setEndOfDay = (date) =>
      time
        ? date.setHours(23, 59, 59, 999).getTime()
        : date.setHours(23, 59, 59, 999);

    const today = new Date();
    const date = new Date();
    function pad(number) {
      return (number < 10 ? "0" : "") + number;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // for one week
    const formattedDate = `${year}-${pad(month)}-${pad(day)}`;
    const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const twoWeekAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
    function formatDate(wdate) {
      return `${wdate.getFullYear()}-${(wdate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${wdate.getDate().toString().padStart(2, "0")}`;
    }
    return [
      {
        label: "TODAY",
        fromDate: new Date(formattedDate).setHours(0, 0, 0, 0),
        toDate: new Date(formattedDate).setHours(23, 59, 59, 999),
        // fromDate: setStartOfDay(new Date()),
        // toDate: setEndOfDay(new Date()),
      },
      {
        label: "PRESENT WEEK",
        // fromDate: setStartOfDay(
        //   new Date(
        //     today.getFullYear(),
        //     today.getMonth() + 1,
        //     today.getDate() - today.getDay()
        //   )
        // ),
        // toDate: setEndOfDay(new Date()),

        fromDate: new Date(formatDate(oneWeekAgo)).setHours(23, 59, 59, 999),
        toDate: new Date(formatDate(today)).setHours(0, 0, 0, 0),
      },
      {
        label: "LAST TWO WEEKS",
        fromDate: new Date(formatDate(twoWeekAgo)).setHours(23, 59, 59, 999),
        toDate: new Date(formatDate(today)).setHours(0, 0, 0, 0),
        // fromDate: setStartOfDay(
        //   new Date(
        //     today.getFullYear(),
        //     today.getMonth(),
        //     today.getDate() - today.getDay() - 7
        //   )
        // ),
        // toDate: setEndOfDay(
        //   new Date(
        //     today.getFullYear(),
        //     today.getMonth(),
        //     today.getDate() - today.getDay() - 1
        //   )
        // ),
      },
      {
        label: "THIS MONTH",
        fromDate: setStartOfDay(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ),
        toDate: setEndOfDay(new Date()),
      },
      {
        label: "LAST MONTH",
        fromDate: setStartOfDay(
          new Date(today.getFullYear(), today.getMonth() - 1, 1)
        ),
        toDate: setEndOfDay(new Date(today.getFullYear(), today.getMonth(), 0)),
      },
      {
        label: "LAST 3 MONTHS",
        fromDate: setStartOfDay(
          new Date(today.getFullYear(), today.getMonth() - 3, 1)
        ),
        toDate: setEndOfDay(new Date()),
      },
      {
        label: "LAST 6 MONTHS",
        fromDate: setStartOfDay(
          new Date(today.getFullYear(), today.getMonth() - 6, 1)
        ),
        toDate: setEndOfDay(new Date()),
      },
      {
        label: "LAST 12 MONTHS",
        fromDate: setStartOfDay(
          new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
        ),
        toDate: setEndOfDay(new Date()),
      },
      {
        label: "IN BETWEEN DATES",
      },
    ];
  },
});

export const days = ({ name }) => ({
  name,
  label2: "Days",
  key: "label",
  type: `${FIELD_TYPES.DROPDOWN}_${SELECT_TYPES.SINGLE}`,
  getList: () => [
    { label: "MONDAY", value: "MON" },
    { label: "TUESDAY", value: "TUE" },
    { label: "WEDNESDAY", value: "WED" },
    { label: "THURSDAY", value: "THU" },
    { label: "FRIDAY", value: "FRI" },
    { label: "SATURDAY", value: "SAT" },
    { label: "SUNDAY", value: "SUN" },
  ],
});
