import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  createBranchSuccess,
  fetchBranchesSuccess,
  updateBranchSuccess,
  deleteBranchSuccess,
  resetBranchProcess,
  fetchBranchesAySuccess,
} from "./branches.slice";
import { setInitializationStatus } from "../../app/app.slice";
// apis
import {
  createBranch,
  fetchBranchesList,
  updateBranch,
  updateBranchImages,
  deleteBranch,
} from "../../../APIs/organization/BranchServices";
// utils
import { fetch, create, update, remove } from "../../app/app.utils";
import { getState } from "../../selector.utils";
// selectors
import { selectBranch, selectQuery } from "./branches.selectors";
import { selectIsAppInitialized } from "../../app/app.selectors";
import { fetchBatchesSuccess } from "../batches/batches.slice";

export function* createBranchAsync({ payload }) {
  yield create({
    callName: "create branch",
    inputs: payload,
    api: createBranch,
    *handleRes(item) {
      yield put(createBranchSuccess(item));
      yield put(resetBranchProcess());
    },
    resName: "savedBranch",
  });
}

export function* fetchBranchesAsync() {
  const { showFeedback = true, flag, ...rest } = selectQuery(getState());

  yield fetch({
    callName: "fetch branch[s]",
    query: { ...rest },
    api: fetchBranchesList,
    *handleRes(items) {
      if (flag) {
        yield put(fetchBranchesAySuccess(items.branch));
      }
      yield put(fetchBranchesSuccess(items.branch));
      yield put(fetchBatchesSuccess(items.batch));
      const isAppInitialized = selectIsAppInitialized(getState());
      if (!isAppInitialized)
        yield put(
          setInitializationStatus({
            fetchedBranches: true,
            fetchedBatches: true,
          })
        );
      yield put(resetBranchProcess());
    },
    showFeedback,
    targetPath: "result",
  });
}

export function* updateBranchAsync({ payload }) {
  const { multipart, branchCode, formData } = payload;
  if (multipart) {
    const headers = { "x-image": multipart };
    yield update({
      callName: "update branch",
      updates: { branchCode, headers, formData },
      api: updateBranchImages,
      *handleRes(res) {
        yield put(updateBranchSuccess(res));
        yield put(resetBranchProcess());
      },
      updatedName: "updatedBranch",
    });
  } else {
    yield update({
      callName: "update branch",
      updates: payload,
      api: updateBranch,
      *handleRes(res) {
        yield put(updateBranchSuccess(res));
        yield put(resetBranchProcess());
      },
      updatedName: "updatedBranch",
    });
  }
}

export function* deleteBranchAsync() {
  const branch = selectBranch(getState());

  yield remove({
    callName: "delete branch",
    id: branch._id,
    api: deleteBranch,
    *handleRes(res) {
      yield put(deleteBranchSuccess(res));
      yield put(resetBranchProcess());
    },
    deletedName: "deletedBranch",
  });
}

export function* onFetchBranchesStart() {
  yield takeLatest("BRANCH/fetchBranchesStart", fetchBranchesAsync);
}

export function* onBrancheCreationStart() {
  yield takeLatest("BRANCH/createBranchStart", createBranchAsync);
}

export function* onUpdateBranchStart() {
  yield takeLatest("BRANCH/updateBranchStart", updateBranchAsync);
}

export function* onDeleteBranchStart() {
  yield takeLatest("BRANCH/deleteBranchStart", deleteBranchAsync);
}

export default function* branchSaga() {
  yield all([
    call(onBrancheCreationStart),
    call(onFetchBranchesStart),
    call(onUpdateBranchStart),
    call(onDeleteBranchStart),
  ]);
}
