import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import {
  fetchTimeTables,
  createTimeTable,
  updateTimeTable,
} from "APIs/academics/timetable";
import { fetch, create, update } from "../../app/app.utils";
// actions
import {
  fetchTimeTableSuccess,
  createTimeTableSuccess,
  updateTimeTableSuccess,
} from "./timetable.slice";

export function* fetchTimeTableAsync({ payload }) {
  yield fetch({
    callName: "fetch_timetable",
    query: { ...payload },
    api: fetchTimeTables,
    *handleRes(items) {
      yield put(fetchTimeTableSuccess(items));
    },
  });
}

export function* createTimeTebleAsync({ payload }) {
  yield create({
    callName: "save_timetable",
    inputs: payload,
    api: createTimeTable,
    *handleRes(res) {
      yield put(createTimeTableSuccess(res));
    },
    resName: "savedTimeTable",
  });
}

export function* updateTimeTableAsync({ payload }) {
  yield update({
    callName: "update_timetable",
    updates: payload,
    api: updateTimeTable,
    *handleRes(res) {
      yield put(updateTimeTableSuccess(res));
    },
    updatedName: "updatedTimeTable",
    needAdditionalInfo: true,
  });
}

export function* onFetchTimeTableStart() {
  yield takeLatest("TIMETABLE/fetchTimeTablesStart", fetchTimeTableAsync);
}

export function* onCreateTimeTableStart() {
  yield takeLatest("TIMETABLE/createTimeTableStart", createTimeTebleAsync);
}

export function* onUpdateTimeTableStart() {
  yield takeLatest("TIMETABLE/updateTimeTableStart", updateTimeTableAsync);
}

export default function* timeTableSaga() {
  yield all([
    call(onFetchTimeTableStart),
    call(onCreateTimeTableStart),
    call(onUpdateTimeTableStart),
  ]);
}
