import store from "store";
// eslint-disable-next-line import/no-cycle
import baseAxios from "APIs/baseAxios";
import { getAPIUrlConstants } from "./constants";

const API_CONST = getAPIUrlConstants("ADMIN");

export async function userLogin(body) {
  try {
    return (await baseAxios.post("/employee/login", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logout(userId = "") {
  try {
    return (await baseAxios.post(`/employee/logout/${userId}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function setMPin(body) {
  try {
    return (await baseAxios.post("/employee/set-mPin", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function changeMyPassword(body) {
  try {
    const { data } = await baseAxios.post("employee/change-password", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function forgotMyPassword(body) {
  try {
    const { data } = await baseAxios.post("employee/forgot-password", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUsersList(params) {
  try {
    if (params.export && params.export === "CSV") {
      let str = "?";
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const element = params[key];
          str = `${str + key}=${element}&`;
        }
      }

      return window.open(`${API_CONST.BASE_URL}/users${str}`, "-blank");
      // return data
    }
    const { data } = await baseAxios.get("/users/", {
      params,
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStudentExamStatus(params) {
  try {
    const { data } = await baseAxios.get("/users/examstatus", {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
}

export async function getBranchesPerLevel(params) {
  try {
    const { data } = await baseAxios.post("/users/getBranchesPerLevel", params);
    store.set("branchAndLevel", data);

    return data;
  } catch (error) {
    return error;
  }
}

export async function getSectionsPerBranch(params) {
  try {
    const { data } = await baseAxios.post(
      "/users/getSectionsPerBranch",
      params
    );
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStudentProfile(params) {
  try {
    const { data } = await baseAxios.get("/users/studentprofile", {
      params,
    });

    return data;
  } catch (error) {
    return error;
  }
}

export async function updateStudentExamDetails(params) {
  try {
    const { data } = await baseAxios.post("/users/examstatus", params);
    return data;
  } catch (e) {
    return e;
  }
}

export async function exportStudentExcel(params) {
  try {
    const { data } = await baseAxios.post("/users/excel", params);
    return data;
  } catch (error) {
    return error;
  }
}

export async function updateStudentExcel(params) {
  try {
    const { data } = await baseAxios.post("/users/updateExcel", params);
    return data;
  } catch (error) {
    return error;
  }
}

export async function createUser(body) {
  try {
    const { data } = await baseAxios.post("/users", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function updateUser(body) {
  try {
    const { data } = await baseAxios.put(`/users/${body.userId}`, body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function resetUserLoginSession(body) {
  try {
    const { data } = await baseAxios.post("/users/reset", body);
    return data;
  } catch (error) {
    return error;
  }
}
// admin users
export async function createAdminUser(body) {
  try {
    const data = await baseAxios.post("/users/createadminuser", body);
    return data;
  } catch (error) {
    return error;
  }
}
export async function updateAdminRoles(params) {
  try {
    const data = await baseAxios.post("/users/updateAdminRoles", params);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getEMPDetails({ empId, ...rest }) {
  try {
    const { data } = await baseAxios.get(`/employee/emp/${empId}`, {
      params: {
        ...rest,
      },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getEMPAccessDetails({ id }) {
  try {
    const { data } = await baseAxios.get(`/employee/access/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getEMPSubscriptionDetails({ id }) {
  try {
    const { data } = await baseAxios.get(`/employee/subscription/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchNoficationParams(body) {
  try {
    const { data } = await baseAxios.post(`/employee/noti-params/`, body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getUserForOMR(body) {
  try {
    const { data } = await baseAxios.post(`/users/omrStudent`, body);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
}
