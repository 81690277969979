import { useEffect, useRef } from "react";
import lottie from "lottie-web";
// mui
import Box from "@mui/material/Box";
// lottie file
import CheckMark from "../assets/animations/success-tick.json";
// import Loading from "../assets/animations/loading.json";
import FluidLoading from "../assets/animations/fluid-loading.json";
import Warning from "../assets/animations/failure.json";
// import Data from "../assets/animations/data.json";
import Error from "../assets/animations/error.json";
import OffLine from "../assets/animations/no-connection.json";
// import ld from "../assets/animations/140468-bound-loading.json";

const JSONAnimation = ({ type }) => {
  const anime = useRef(null);
  const data = (() => {
    if (type === "success") return CheckMark;
    if (type === "loading") return FluidLoading;
    if (type === "warning") return Warning;
    if (type === "error") return Error;
    if (type === "offLine") return OffLine;
    return CheckMark;
  })();
  useEffect(() => {
    lottie.loadAnimation({
      name: "lottie-animation",
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: data,
      animationSpeed: "1.5x",
    });

    return () => lottie.destroy("lottie-animation");
  }, []);

  return <Box ref={anime} height={250} width={250} />;
};

export default JSONAnimation;
