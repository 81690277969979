import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// compoents
import JSONAnimation from "animations";

const Offline = ({ fullScreen }) => (
  <Box
    width={fullScreen ? "100vw" : "100%"}
    height={fullScreen ? "100vh" : "100%"}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <JSONAnimation type="offLine" />
    <Typography>Check Your Internet Connection</Typography>
    <Button
      variant="text"
      color="primary"
      onClick={() => window.location.reload()}
    >
      Retry
    </Button>
  </Box>
);

export default Offline;
