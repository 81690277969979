import { createSlice } from "@reduxjs/toolkit";
// utils
// import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  hostels: [],
  rooms: [],
  stuffToSave: [],
  bulkOpSummary: {},
};
const hostelSlice = createSlice({
  name: "hostel",
  initialState,
  reducers: {
    createHostelStart() {},
    createHostelSuccess(state, action) {
      state.hostels = [...state.hostels, action.payload];
    },
    fetchHostelBuildingDetailsStart() {},
    fetchRoomsStart() {},
    fetchHostelBuildingDetailsSuccess(state, action) {
      state.hostels = action.payload;
    },
    fetchRoomsSuccess(state, action) {
      state.report = action.payload;
    },

    resetHostel(state) {
      state.params = null;
      state.stuffToSave = null;
    },

    clearHostel(state) {
      state.hostels = [];
      state.rooms = [];
      state.stuffToSave = [];
    },
    bulkUpload() {},
    bulkUpdate() {},
    hostelBulkOpSuccess(state, action) {
      state.bulkOpSummary = action.payload;
    },
    clearBulkOpSummary(state) {
      state.bulkOpSummary = {};
    },
    resetHostelProcess(state) {
      state.updates = "";
      state.bulkData = "";
    },
  },
});

export const {
  fetchHostelBuildingDetailsStart,
  fetchRoomsStart,
  createHostelStart,
  fetchRoomsSuccess,
  createHostelSuccess,
  fetchHostelBuildingDetailsSuccess,
  hostelBulkOpSuccess,
  resetHostelProcess,
  bulkUpload,
  bulkUpdate,
} = hostelSlice.actions;

export default hostelSlice.reducer;
