import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  instituteConfig: {},
  configId: null,

  AyInfo: {}, // Make sure AyInfo is initialized properly
};

const settingsSlice = createSlice({
  name: "SETTINGS",
  initialState,
  reducers: {
    createConfigStart() {},
    createConfigSuccess(state, action) {
      state.instituteConfig = action.payload;
      state.configId = action.payload._id;
    },

    updateConfigStart() {},
    updateConfigSuccess(state, action) {
      state.instituteConfig = action.payload;
    },
    fetchConfigStart() {},
    fetchConfigSuccess(state, action) {
      if (action.payload) {
        state.instituteConfig = action.payload;
        state.configId = action.payload._id;
      }
    },
    clearInstituteConfig(state) {
      state.instituteConfig = {};
      state.configId = null;
    },
    fetchAyStart() {},
    fetchAySuccess(state, action) {
      if (action.payload) {
        state.AyInfo = action.payload;
      }
    },
  },
});

export const {
  updateConfigStart,
  updateConfigSuccess,
  fetchConfigStart,
  fetchConfigSuccess,
  createConfigStart,
  createConfigSuccess,
  clearInstituteConfig,
  fetchAyStart,
  fetchAySuccess,
} = settingsSlice.actions;

export default settingsSlice.reducer;
