import baseAxios from "../baseAxios";

export async function fetchInstituteDetails(obj) {
  const { instituteId } = obj;
  try {
    return (await baseAxios.get(`/institute/${instituteId}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchesPerLevel(params) {
  try {
    return (await baseAxios.post("/users/getBranchesPerLevel", params)).data;
  } catch (error) {
    return error;
  }
}

export async function fetchAllExams(params) {
  try {
    return (
      await baseAxios.get("/exam", {
        params,
      })
    ).data;
  } catch (error) {
    return error;
  }
}

export async function fetchLevelsFromS3(instituteId) {
  try {
    return (
      await baseAxios.get(
        `https://onesaz-new.s3.ap-south-1.amazonaws.com/domain_config/${instituteId}.json`
      )
    ).data;
  } catch (error) {
    return error;
  }
}
