import baseAxios from "../baseAxios";

const COMMON = "/admissions/sequence";

export async function createSequence(obj) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchSequences(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateSequences(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.patch(`${COMMON}/${_id}`, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
