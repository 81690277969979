import flatten from "lodash/flatten";
// mui
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// layout
import BodyWrapper from "../../layout/bodyWrapper";
// component
const ManageLive = lazy(() => import("../../views/Live/ManageLive"));
const VideoList = lazy(() => import("../../views/Live/Videos"));
const ZoomApp = lazy(() => import("../../views/Live/ZoomApp"));

export default {
  component: <BodyWrapper />,
  title: "Live Classes",
  basePath: "app",
  path: "live-classes",
  authorizedRoles: [],
  icon: <LiveTvRoundedIcon />,
  features: flatten(Object.values(features["live-classes"])),
  children: [
    {
      component: <ManageLive />,
      title: "Manage Live Classes",
      basePath: "live-classes",
      path: "manage-live",
      features: features["live-classes"].manage_live,
      authorizedRoles: [],
    },
    {
      component: <ZoomApp />,
      title: "Zoom Meetings",
      basePath: "live-classes",
      path: "zoom-live",
      features: features["live-classes"].zoom_live,
      authorizedRoles: [],
    },
    {
      component: <VideoList />,
      title: "Videos & Live Classes",
      basePath: "live-classes",
      path: "videos",
      features: features["live-classes"].videos,
      authorizedRoles: [],
    },
    // {
    //   component: <AdminPortalComponent />,
    //   title: "Update Video URL",
    //   basePath: "live-classes",
    //   adminPath: true,
    //   path: "update-video-url",
    //   icon: <LinkRounded />,
    //   authorizedRoles: [],
    // },
  ],
};
