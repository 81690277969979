import axios from "axios";
import baseAxios from "APIs/baseAxios";

const USERNAME = "harsha_919";
const BASEPATH = "http://api.geonames.org/";
const COMMON = "public";

const URL =
  "https://onesazerpnew.s3.ap-south-1.amazonaws.com/Utilities/statesAndDistricts.json";
const INDIA_GEONAME_ID = 1269750;

const commonParamas = {
  formatted: true,
  username: USERNAME,
  style: "full",
};

export async function getStatesAndDistrictsOfIndia() {
  try {
    return (await axios.get(URL)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getStatesOfIndia() {
  const path = `${BASEPATH}childrenJSON`;
  const params = { ...commonParamas, geonameId: INDIA_GEONAME_ID };
  try {
    return (await axios.get(path, { params })).data.geonames;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDistrictsOfState(geonameId) {
  const path = `${BASEPATH}childrenJSON`;
  const params = { ...commonParamas, geonameId };
  try {
    return (await axios.get(path, { params })).data.geonames;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCitiesOfPostalCode(postalcode) {
  const path = `${BASEPATH}postalCodeLookupJSON`;
  const params = { ...commonParamas, postalcode, country: "IN" };
  try {
    return (await axios.get(path, { params })).data.postalcodes;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getTransaction(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/fetch-txn`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
