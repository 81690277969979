import { createSelector } from "@reduxjs/toolkit";

const selectHostels = (state) => state.hostels;
const selectRooms = (state) => state.rooms;
export const selectHostelParams = createSelector(
  [selectHostels],
  (app) => app.params
);
export const selectRoomsParams = createSelector(
  [selectRooms],
  (app) => app.params
);
