import { createSlice } from "@reduxjs/toolkit";
// uitils
import {
  getUpdatedList,
  getUpdateUnionOfLists,
} from "../../../utils/general.utils/array.utils";

const initialState = {
  data: null,
  plans: [],
  maps: [],
  feePlanId: null,
  reservationPlans: [],
  applicationNoRanges: [],
  feeAssignSummary: {},
};

const feePlanSlice = createSlice({
  name: "FEE_PLAN",
  initialState,
  reducers: {
    createFeePlanStart: (state, action) => {
      state.data = action.payload;
    },
    createFeePlanSuccess: (state, action) => {
      state.plans = [...state.plans, action.payload];
    },
    fetchFeePlanStart: (state, action) => {
      state.data = action.payload;
    },
    fetchFeePlanSuccess: (state, action) => {
      const { plans = [], maps = [] } = action.payload || {};
      state.plans = plans;
      state.maps = maps;
    },
    setBranchFeeMaps: (state, action) => {
      const oldList = state.maps;
      const newList = action.payload;
      state.maps = getUpdateUnionOfLists(oldList, newList);
    },
    updateFeePlanStart: (state, action) => {
      state.data = action.payload;
    },
    updateFeePlanSuccess: (state, action) => {
      state.plans = getUpdatedList(action.payload, state.plans);
    },
    resetFeePlanData: (state) => {
      state.data = null;
      state.feePlanId = null;
    },
    setIdToEditFeePlan: (state, action) => {
      state.feePlanId = action.payload;
    },
    assignFeePlansStart() {},
    clearFeePlansList(state) {
      state.plans = [];
      state.maps = [];
    },
    fetchReservationPlansStart() {},
    fetchReservationPlansSuccess(state, action) {
      state.reservationPlans = action.payload;
    },
    createReservationPlanStart() {},
    createReservationPlanSuccess(state, action) {
      state.reservationPlans = [...state.reservationPlans, action.payload];
    },
    updateReservationPlanStart() {},
    updateReservationPlanSuccess(state, action) {
      state.reservationPlans = getUpdatedList(
        action.payload,
        state.reservationPlans
      );
    },
    clearReservationPlansList(state) {
      state.reservationPlans = [];
    },
    createApplicationNoRangeStart() {},
    createApplicationNoRangeSuccess(state, action) {
      state.applicationNoRanges = action.payload;
    },
    fetchApplicationNoRangesStart() {},
    fetchApplicationNoRangesSuccess(state, action) {
      state.applicationNoRanges = action.payload;
    },
    clearApplicationNoRangesList(state) {
      state.applicationNoRanges = [];
    },
    updateApplicationNoRangeStart() {},
    updateApplicationNoRangeSuccess(state, action) {
      state.applicationNoRanges = getUpdatedList(
        action.payload,
        state.applicationNoRanges
      );
    },
    additionalFeeAssignmentStart() {},
    additionalFeeAssignmentSuccess(state, action) {
      state.feeAssignSummary = action.payload;
    },
    clearAssignmentSummary(state) {
      state.feeAssignSummary = {};
    },
  },
});

export const {
  createFeePlanStart,
  createFeePlanSuccess,
  fetchFeePlanStart,
  fetchFeePlanSuccess,
  updateFeePlanStart,
  updateFeePlanSuccess,
  resetFeePlanData,
  setIdToEditFeePlan,
  assignFeePlansStart,
  clearFeePlansList,
  createReservationPlanStart,
  createReservationPlanSuccess,
  updateReservationPlanStart,
  updateReservationPlanSuccess,
  fetchReservationPlansStart,
  fetchReservationPlansSuccess,
  createApplicationNoRangeStart,
  createApplicationNoRangeSuccess,
  fetchApplicationNoRangesStart,
  fetchApplicationNoRangesSuccess,
  clearApplicationNoRangesList,
  updateApplicationNoRangeStart,
  updateApplicationNoRangeSuccess,
  setBranchFeeMaps,
  additionalFeeAssignmentStart,
  additionalFeeAssignmentSuccess,
  clearAssignmentSummary,
} = feePlanSlice.actions;

export default feePlanSlice.reducer;
