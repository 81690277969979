import { createSlice } from "@reduxjs/toolkit";
// utils
import {
  getUpdatedList,
  getUpdateUnionOfLists,
} from "../../../utils/general.utils/array.utils";

const initialState = {
  branch: null,
  query: null,
  branchesList: [],
  branchId: "",
  editPageIndex: "",
};

const branchSlice = createSlice({
  name: "BRANCH",
  initialState,
  reducers: {
    setEditPageIndex(state, action) {
      state.editPageIndex = action.payload;
    },
    setBranchId(state, action) {
      state.branchId = action.payload;
    },
    resetBranchProcess(state) {
      state.editPageIndex = null;
      state.query = null;
      state.branch = null;
    },
    createBranchStart(state, action) {
      state.branch = action.payload;
    },
    createBranchSuccess(state, action) {
      const list = state.branchesList;
      state.branchesList = [...list, action.payload];
    },
    fetchBranchesStart(state, action) {
      state.query = action.payload;
    },
    fetchBranchesSuccess(state, action) {
      // const oldList = state.branchesList;
      // const newList = action.payload;
      // state.branchesList = getUpdateUnionOfLists(oldList, newList);
      state.branchesList = action.payload;
    },
    fetchBranchesAySuccess(state, action) {
      state.branchesList = action.payload;
    },
    updateBranchStart() {},
    updateBranchSuccess(state, action) {
      const list = state.branchesList;
      state.branchesList = getUpdatedList(action.payload, list);
    },
    deleteBranchStart(state, action) {
      state.branch = action.payload;
    },
    deleteBranchSuccess(state, action) {
      const list = state.branchesList;
      state.branchesList = getUpdatedList(action.payload, list, "delete");
    },
    clearBranchesList(state) {
      state.branchesList = [];
    },
  },
});

export const {
  setBranchId,
  setEditPageIndex,
  resetBranchProcess,
  createBranchStart,
  createBranchSuccess,
  createBranchFailure,
  fetchBranchesStart,
  fetchBranchesSuccess,
  fetchBranchesFailure,
  updateBranchStart,
  updateBranchSuccess,
  updateBranchFailure,
  deleteBranchStart,
  deleteBranchSuccess,
  clearBranchesList,
  fetchBranchesAySuccess,
} = branchSlice.actions;

export default branchSlice.reducer;
