function maskEmailAddress(email = "") {
  if (email.length <= 1) {
    return "NA";
  }
  // Split the email address into the local part and domain
  const [localPart, domain] = email.split("@");

  // Get the first two characters of the local part
  const firstTwoChars = localPart.slice(0, 2);

  // Get the last character of the local part
  const lastChar = localPart.charAt(localPart.length - 1);

  // Mask the characters in the middle of the local part with asterisks
  const maskedMiddlePart = localPart.slice(2, -1).replace(/./g, "*");

  // Reconstruct the email address with the masked local part
  return `${firstTwoChars}${maskedMiddlePart}${lastChar}@${domain}`;
}
export default maskEmailAddress;
