import { createSelector } from "@reduxjs/toolkit";

const selectApp = (state) => state.app;

export const selectIsAppInitialized = createSelector(
  [selectApp],
  (app) => app.isAppInitialized
);

export const selectCurrentAcademicYear = createSelector(
  [selectApp],
  (app) => app.currentAcademicYear
);

export const selectInitializingStatus = createSelector(
  [selectApp],
  (app) => app.initializingStatus
);

export const selectProcessStatus = createSelector(
  [selectApp],
  (app) => app.processStatus
);

export const selectIsProcessing = createSelector(
  [selectApp],
  (app) => app.isProcessing
);

export const selectAdminRoute = createSelector(
  [selectApp],
  (app) => app.adminRoute
);

export const selectInstituteId = createSelector(
  [selectApp],
  (app) => app.instituteId
);

export const selectProcessType = createSelector(
  [selectApp],
  (app) => app.processType
);

export const selectAcademicYear = createSelector(
  [selectApp],
  (app) => app.academicYear
);

export const selectAlertInfo = createSelector(
  [selectApp],
  (app) => app.alertInfo
);

export const selectLayoutType = createSelector(
  [selectApp],
  (app) => app.layout
);

export const selectEmployees = createSelector(
  [selectApp],
  (app) => app.employees
);
export const selectLoginId = createSelector([selectApp], (app) => app.loginId);

export const selectLoginUser = createSelector(
  [selectApp],
  (app) => app.loginUser
);

export const selectAccessLevels = createSelector(
  [selectApp],
  (app) => app.accessLevels
);
