import { takeLatest, put, all, call } from "redux-saga/effects";
// apis
import {
  createFeePlan,
  fetchFeePlans,
  updateFeePlan,
  // assignFeePlans,
  fetchReservationFees,
  createReservationFee,
  updateReservationFee,
  createApplicationNoRange,
  fetchApplicationNoRange,
  updateApplicationNoRange,
  assignAdditionalFee,
} from "APIs/finance/feePlan";
import { updateBranchFeeMap } from "APIs/finance/batchFeeMaps";
// actions
import {
  createFeePlanSuccess,
  resetFeePlanData,
  fetchFeePlanSuccess,
  updateFeePlanSuccess,
  fetchReservationPlansSuccess,
  createReservationPlanSuccess,
  updateReservationPlanSuccess,
  createApplicationNoRangeSuccess,
  fetchApplicationNoRangesSuccess,
  updateApplicationNoRangeSuccess,
  setBranchFeeMaps,
  additionalFeeAssignmentSuccess,
} from "redux/finance/feePlan/feePlan.slice";
import { setInitializationStatus } from "redux/app/app.slice";
// utils
import { create, fetch, update } from "../../app/app.utils";
import { getState } from "../../selector.utils";
// selectors
import { selectFeePlanData } from "./feePlan.selectors";

export function* createFeePlanAsync() {
  const data = selectFeePlanData(getState());
  yield create({
    callName: "create fee plan",
    inputs: data,
    api: createFeePlan,
    *handleRes(r) {
      yield put(createFeePlanSuccess(r));
      yield put(resetFeePlanData());
    },
    resName: "saved",
  });
}

export function* fetchFeePlansAsync({ payload }) {
  const { initial, ...data } = payload;
  yield fetch({
    callName: "fetch fee plan[s]",
    query: data,
    api: fetchFeePlans,
    *handleRes(items) {
      yield put(fetchFeePlanSuccess(items));
      yield put(resetFeePlanData());
      if (initial)
        yield put(setInitializationStatus({ fetchedFeePlans: true }));
    },
    initial,
    targetPath: "result",
    readLength: false,
  });
}

export function* updateFeePlanAsync() {
  const data = selectFeePlanData(getState());
  yield update({
    callName: "update fee plan",
    updates: data,
    api: updateFeePlan,
    *handleRes(res) {
      yield put(updateFeePlanSuccess(res));
      yield put(resetFeePlanData());
    },
    updatedName: "updatedFeePlan",
  });
}

export function* assignFeePlansAsync({ payload }) {
  yield update({
    callName: "assign fee plan[s]",
    updates: payload,
    api: updateBranchFeeMap,
    *handleRes(items) {
      yield put(setBranchFeeMaps(items));
      yield put(resetFeePlanData());
    },
    updatedName: "updatedList",
  });
}

export function* fetchReservationFeePlansAsync({ payload }) {
  yield fetch({
    callName: "fetch reservation fee plan[s]",
    query: payload,
    api: fetchReservationFees,
    *handleRes(items) {
      yield put(fetchReservationPlansSuccess(items));
    },
    targetPath: "result",
  });
}

export function* createReservationFeeAsync({ payload }) {
  yield create({
    callName: "create_reservation_fee",
    inputs: payload,
    api: createReservationFee,
    *handleRes(res) {
      yield put(createReservationPlanSuccess(res));
    },
    resName: "saved",
  });
}

export function* updateReservationFeeAsync({ payload }) {
  yield update({
    callName: "update_reservation_fee",
    updates: payload,
    api: updateReservationFee,
    *handleRes(res) {
      yield put(updateReservationPlanSuccess(res));
    },
    updatedName: "updated",
  });
}

export function* createApplicationNoRangesAsync({ payload }) {
  yield create({
    callName: "create_application_no_ranges",
    inputs: payload,
    api: createApplicationNoRange,
    *handleRes(res) {
      yield put(createApplicationNoRangeSuccess(res));
    },
    resName: "saved",
  });
}

export function* fetchApplicationNoRangesAsync({ payload }) {
  const { initial, ...data } = payload || {};
  yield fetch({
    callName: "fetch_application_no_ranges",
    query: data,
    api: fetchApplicationNoRange,
    *handleRes(items) {
      yield put(fetchApplicationNoRangesSuccess(items));
    },
    targetPath: "result",
    initial: !!initial,
  });
}

export function* updateApplicationNoRangesAsync({ payload }) {
  yield update({
    callName: "update_application_no_ranges",
    updates: payload,
    api: updateApplicationNoRange,
    *handleRes(res) {
      yield put(updateApplicationNoRangeSuccess(res));
    },
    updatedName: "updated",
  });
}

export function* assignAdditionalFeeAsync({ payload }) {
  yield create({
    callName: "assign_additional_fee",
    inputs: payload,
    api: assignAdditionalFee,
    *handleRes(res) {
      yield put(additionalFeeAssignmentSuccess(res));
    },
    resName: "result",
  });
}

export function* onCreateFeePlanStart() {
  yield takeLatest("FEE_PLAN/createFeePlanStart", createFeePlanAsync);
}

export function* onFetchFeePlanStart() {
  yield takeLatest("FEE_PLAN/fetchFeePlanStart", fetchFeePlansAsync);
}

export function* onFeePlanUpdateStart() {
  yield takeLatest("FEE_PLAN/updateFeePlanStart", updateFeePlanAsync);
}

export function* onAssignFeePlansStart() {
  yield takeLatest("FEE_PLAN/assignFeePlansStart", assignFeePlansAsync);
}

export function* onFetchReservationFeePlansStart() {
  yield takeLatest(
    "FEE_PLAN/fetchReservationPlansStart",
    fetchReservationFeePlansAsync
  );
}

export function* onCreateReservationFeeStart() {
  yield takeLatest(
    "FEE_PLAN/createReservationPlanStart",
    createReservationFeeAsync
  );
}

export function* onUpdateReservationFeeStart() {
  yield takeLatest(
    "FEE_PLAN/updateReservationPlanStart",
    updateReservationFeeAsync
  );
}

export function* onCreateApplicationNoRangeStart() {
  yield takeLatest(
    "FEE_PLAN/createApplicationNoRangeStart",
    createApplicationNoRangesAsync
  );
}

export function* onFetchApplicationNoRangeStart() {
  yield takeLatest(
    "FEE_PLAN/fetchApplicationNoRangesStart",
    fetchApplicationNoRangesAsync
  );
}

export function* onUpdateApplicationNoRangeStart() {
  yield takeLatest(
    "FEE_PLAN/updateApplicationNoRangeStart",
    updateApplicationNoRangesAsync
  );
}

export function* onAssignAdditionalFeeStart() {
  yield takeLatest(
    "FEE_PLAN/additionalFeeAssignmentStart",
    assignAdditionalFeeAsync
  );
}

export default function* feePlanSaga() {
  yield all([
    call(onCreateFeePlanStart),
    call(onFetchFeePlanStart),
    call(onFeePlanUpdateStart),
    call(onAssignFeePlansStart),
    call(onFetchReservationFeePlansStart),
    call(onCreateReservationFeeStart),
    call(onUpdateReservationFeeStart),
    call(onCreateApplicationNoRangeStart),
    call(onFetchApplicationNoRangeStart),
    call(onUpdateApplicationNoRangeStart),
    call(onAssignAdditionalFeeStart),
  ]);
}
