import { createSelector } from "@reduxjs/toolkit";

const selectStudentsState = (state) => state.student;

export const selectRegistrationParams = createSelector(
  [selectStudentsState],
  (s) => s.regParams
);

export const selectStudentEditParams = createSelector(
  [selectStudentsState],
  (s) => s.studentEditParamas
);

export const selectStudents = createSelector(
  [selectStudentsState],
  (s) => s.students
);

export const selectAdmissionTxnHistory = createSelector(
  [selectStudentsState],
  (s) => s.txnHistory
);

export const selectBulkOpSummary = createSelector(
  [selectStudentsState],
  (s) => s.bulkOpSummary
);
export const selectStudentBedStatus = createSelector(
  [selectStudentsState],
  (s) => s.studentBedStatus
);
export const selectApplicationCommissionItems = createSelector(
  [selectStudentsState],
  (s) => s.application_commissions
);
