import { useState } from "react";
import moment from "moment";
import SoftTypography from "myComponents/SoftTypography";
import store from "store";
import SoftButton from "myComponents/SoftButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default (batchesMap) => {
  const instituteId = store.get("instituteId");

  const [open, setOpen] = useState(false);
  const [dialogDocs, setDialogDocs] = useState([]);

  const handleClickOpen = (docs) => {
    setDialogDocs(docs);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return [
    {
      field: "index",
      headerName: "S No",
      editable: false,
      width: 80,
      valueGetter: (params) => {
        const ids = params.api.getAllRowIds();
        return ids.indexOf(params.id) + 1;
      },
    },

    { field: "admissionNo", headerName: "Admission No", width: 120 },

    {
      field: "fullName",
      headerName:
        instituteId === "645b4332b98a5dc4ce54256d" ? "SurName" : "First Name",
      editable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName:
        instituteId === "645b4332b98a5dc4ce54256d" ? "Name" : "Last Name",
      editable: true,
      flex: 1,
      minWidth: 120,
    },

    {
      field: "batch",
      headerName: "Batch",
      editable: true,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) => batchesMap?.[params.row.batch]?.batchName,
    },

    {
      field: "gender",
      headerName: "Gender",
      editable: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "dateOfAdmission",
      headerName: "Date of Admission",
      editable: true,
      flex: 1,
      minWidth: 120,
      valueGetter: (params) =>
        moment(params.row.lastModified).format("MM-DD-YYYY"),
    },
    {
      field: "documents",
      headerName: "Documents Submitted",
      width: 320,
      flex: 2,
      valueGetter: (params) => {
        const docs = params.row.documents;

        return (params.row.documents && docs?.join(",")) || "";
      },
      renderCell: (params) => {
        const docs = params.row.documents;

        return (
          docs && (
            <div>
              <SoftTypography variant="caption" color="primary">
                {docs.slice(0, 1)?.join(", ") || ""}
                {docs.length > 1 && (
                  <>
                    {" "}
                    <SoftButton
                      variant="text"
                      size="small"
                      color="info"
                      onClick={() => handleClickOpen(docs)}
                      fontSize="0.75rem"
                    >
                      View More
                    </SoftButton>
                  </>
                )}
              </SoftTypography>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Documents Submitted</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {dialogDocs.map((doc, index) => (
                      <div key={index}>{doc}</div>
                    ))}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <SoftButton onClick={handleClose} color="primary">
                    Close
                  </SoftButton>
                </DialogActions>
              </Dialog>
            </div>
          )
        );
      },
    },
  ];
};
