import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const Wrapper = styled(Stack)(({ theme }) => ({
  borderRadius: "0.5rem",
  margin: theme.spacing(1),
  height: "100%",
  minHeight: "calc(100vh - 108px)",
  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(1),
    paddingLeft: 0,
  },
  [theme.breakpoints.down("sm")]: {
    margin: 0,
  },
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    margin: "0.5rem",
  },
}));
