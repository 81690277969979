import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import { fetch, create } from "../app/app.utils";
// actions
import {
  //   fetchHostelBuildingDetailsStart,
  //   fetchRoomsStart,
  fetchRoomsSuccess,
  fetchHostelBuildingDetailsSuccess,
  hostelBulkOpSuccess,
  createHostelSuccess,
  resetHostelProcess,
} from "./hostel.slice";
// apis
import { bulkUpload, bulkUpdate, createHostel } from "../../APIs/hostel/hostel";
// // utils
import { getState } from "../selector.utils";
import { selectRoomsParams, selectHostelParams } from "./hostel.selectors";
// import getTimeFromDate from "../../../utils/getTime";
// import useGetFilterSections from "./useGetFilterSections";

export function* createHostelAsync({ payload }) {
  yield create({
    callName: "create_hostel",
    inputs: payload,
    api: createHostel,
    *handleRes(item) {
      yield put(createHostelSuccess(item));
    },
    resName: "saved",
  });
}
export function* fetchHostelsAsync() {
  const searchHostelParams = yield selectHostelParams(getState());

  yield fetch({
    callName: "fetch hostels",
    query: { ...searchHostelParams },
    api: fetchAttendance,
    *handleRes(items) {
      yield put(fetchHostelBuildingDetailsSuccess(items.hostels));
    },
    targetPath: "result",
  });
}

export function* fetchRoomsAsync() {
  const searchRoomsParams = yield selectRoomsParams(getState());

  yield fetch({
    callName: "fetch hostels",
    query: { ...searchRoomsParams },
    // api: fetchAttendance,
    *handleRes(items) {
      yield put(fetchRoomsSuccess(items.rooms));
    },
    targetPath: "result",
  });
}
export function* bulkUploadStudentsAsync({ payload }) {
  yield create({
    callName: "bulk upload student[s]",
    inputs: payload,
    api: bulkUpload,
    *handleRes(res) {
      yield put(hostelBulkOpSuccess(res.result));
      yield put(resetHostelProcess());
    },
    needAdditionalInfo: true,
  });
}

export function* bulkUpdateStudentsAsync({ payload }) {
  yield create({
    callName: "bulk update student[s]",
    inputs: payload,
    api: bulkUpdate,
    *handleRes(res) {
      yield put(hostelBulkOpSuccess(res.result));
      yield put(resetHostelProcess());
    },
    needAdditionalInfo: true,
  });
}
export function* onFetchHostelsStart() {
  yield takeLatest("hostel/fetchHostelBuildingDetailsStart", fetchHostelsAsync);
}
export function* onCreateHostelStart() {
  yield takeLatest("hostel/createHostelStart", createHostelAsync);
}
export function* onFetchRoomsStart() {
  yield takeLatest("hostel/fetchRoomsStart", fetchRoomsAsync);
}

export default function* hostelSaga() {
  yield all([
    call(onFetchHostelsStart),
    call(onFetchRoomsStart),
    call(onCreateHostelStart),
  ]);
}
