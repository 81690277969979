// icon
import GiteRoundedIcon from "@mui/icons-material/GiteRounded";
// Helpers
import lazy from "helpers/LazyImport";
// roles
import roles from "../roles";
// body layout
import BodyWrapper from "../../layout/bodyWrapper";
import Container from "../../layout/simpleContainer";

export const HostelBuildingSettings = lazy(() =>
  import("../../views/HostelManagement/HostelBuildingSettings")
);

export const CreateHostelForm = lazy(() =>
  import(
    "../../views/HostelManagement/HostelBuildingSettings/AddUpdateBuildingDetails"
  )
);
// export const AssignStudentsToRooms = lazy(() =>
//   import(
//     "../../views/HostelManagement/HostelBuildingSettings/AutoAllocationOfStudents"
//   )
// );
export const AssignStudentsToRooms = lazy(() =>
  import(
    "../../views/HostelManagement/HostelBuildingSettings/AutoAssigingStudents"
  )
);

export const AssignSingleStudent = lazy(() =>
  import(
    "../../views/HostelManagement/HostelBuildingSettings/AutoAllocationOfStudents/ViewStudentsDetials"
  )
);
export const AddUpdateRoomsDetails = lazy(() =>
  import("../../views/HostelManagement/HostelBuildingSettings/UseMnageRooms")
);
export const HostelWarden = lazy(() =>
  import("../../views/HostelManagement/HostelWarden")
);
export const HostelAbsentRemarks = lazy(() =>
  import("../../views/HostelManagement/HostelAbsentRemarks")
);
export const HostelObservations = lazy(() =>
  import("../../views/HostelManagement/HostelObservations")
);
export const HostelAttendance = lazy(() =>
  import("../../views/HostelManagement/HostelAttendance")
);

export default {
  component: <BodyWrapper />,
  title: "Hostel Management",
  basePath: "app",
  path: "hostel",
  icon: <GiteRoundedIcon />,
  authorizedRoles: ["hostel", roles.organization.SUPER_ADMIN],
  children: [
    {
      component: <Container />,
      title: "Hostel Creation",
      basePath: "hostel",
      path: "hostel-settings",
      index: <HostelBuildingSettings />,
      // authorizedRoles: ["hostel-settings", roles.organization.SUPER_ADMIN],
      children: [
        {
          component: <CreateHostelForm />,
          title: "hostel-building-settings",
          basePath: "hostel-settings",
          path: "create",

          authorizedRoles: [],
        },
        {
          component: <AddUpdateRoomsDetails />,
          title: "hostel-rooms-settings",
          basePath: "hostel-settings",
          path: "rooms-settings",
          authorizedRoles: [],
        },
      ],
    },
    {
      component: <Container />,
      title: "Allocation of Students",
      basePath: "hostel",
      path: "assign-students",
      index: <AssignStudentsToRooms />,
      authorizedRoles: ["assign-students", roles.organization.SUPER_ADMIN],
      children: [
        {
          component: <AssignSingleStudent />,
          title: "Assign Single Student",
          basePath: "assign-students",
          path: "single-Student-Assign",
          authorizedRoles: [],
        },
      ],
    },
    {
      component: <Container />,
      title: "Assign Warden To Hostel ",
      basePath: "hostel",
      path: "hostel-warden",
      index: <HostelWarden />,
      authorizedRoles: ["hostel-warden", roles.organization.SUPER_ADMIN],
      children: [{}],
    },
    {
      component: <Container />,
      title: "Hostel Absent Remarks ",
      basePath: "hostel",
      path: "hostel-absent-remarks",
      index: <HostelAbsentRemarks />,
      authorizedRoles: ["hostel-warden", roles.organization.SUPER_ADMIN],
      children: [{}],
    },
    {
      component: <Container />,
      title: "Hostel Observations ",
      basePath: "hostel",
      path: "hostel-observations",
      index: <HostelObservations />,
      authorizedRoles: ["hostel-warden", roles.organization.SUPER_ADMIN],
      children: [{}],
    },
    {
      component: <Container />,
      title: "Hostel Attendance ",
      basePath: "hostel",
      path: "hostel-attendance",
      index: <HostelAttendance />,
      authorizedRoles: ["hostel-attendance", roles.organization.SUPER_ADMIN],
      children: [{}],
    },
  ],
};
