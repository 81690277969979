import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/organization/batches";

export async function createBatches(body) {
  try {
    return (await baseAxios.post(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBatchesList(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (
      await baseAxios.get(path, {
        params: { ...rest, instituteId: store.get("instituteId") },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateBatch(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.patch(`${COMMON}/${_id}`, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteBatch(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBatchesOfBranch(body) {
  try {
    return (await baseAxios.get(`${COMMON}/batches-of-branch`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
