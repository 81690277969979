import { createSlice } from "@reduxjs/toolkit";
// uitils
import {
  getUnionOfLists,
  getUpdatedList,
} from "../../../utils/general.utils/array.utils";

const initialState = {
  batchesData: null,
  initialInputs: {
    groupType: "",
    courseType: "",
    batchWithNumberOfSections: [{ id: 0, batchName: "" }],
  },
  instituteBatches: [],
};
const batchesSlice = createSlice({
  name: "BATCHES",
  initialState,
  reducers: {
    setBatchesData: (state, action) => {
      state.batchesData = action.payload;
    },
    createBatchesStart: (state, action) => {
      state.batchesData = action.payload;
    },
    createBatchesSuccess: (state, action) => {
      const ls1 = state.instituteBatches;
      state.instituteBatches = [...ls1, ...action.payload];
    },
    fetchBatchesStart: (state, action) => {
      state.batchesData = action.payload;
    },
    fetchBatchesAySuccess(state, action) {
      state.instituteBatches = action.payload;
    },
    fetchBatchesSuccess: (state, action) => {
      // state.instituteBatches = getUnionOfLists(
      //   state.instituteBatches,
      //   action.payload
      // );
      state.instituteBatches = action.payload;
    },
    updateBatchStart: (state, action) => {
      state.batchesData = action.payload;
    },
    updateBatchSuccess: (state, action) => {
      state.instituteBatches = getUpdatedList(
        action.payload,
        state.instituteBatches
      );
    },
    deleteBatchStart: (state, action) => {
      state.batchesData = action.payload;
    },
    deleteBatchSuccess: (state, action) => {
      state.instituteBatches = getUpdatedList(
        action.payload,
        state.instituteBatches,
        "delete"
      );
    },
    resetBatchesData: (state) => {
      state.batchesData = null;
    },
    clearInstituteBatches: (state) => {
      state.instituteBatches = [];
    },
  },
});

export const {
  setBatchesData,
  resetBatchesData,
  createBatchesStart,
  createBatchesSuccess,
  fetchBatchesSuccess,
  fetchBatchesStart,
  updateBatchStart,
  updateBatchSuccess,
  deleteBatchStart,
  deleteBatchSuccess,
  clearInstituteBatches,
  fetchBatchesAySuccess,
} = batchesSlice.actions;

export default batchesSlice.reducer;
