// @mui material components
import MenuItem from "@mui/material/MenuItem";
// import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
// actions
// components
import ZTypography from "myComponents/SoftTypography";
// utils
import useSignOut from "utils/auth/useSignOut";
// custom styles for the NotificationItem
import menuItem from "./styles";

const AccountMenu = ({ open, handleClose }) => {
  const signOut = useSignOut();
  return (
    <Menu
      anchorEl={open}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(open)}
      onClose={handleClose}
      sx={{ mt: 2 }}
    >
      <MenuItem
        onClick={() => signOut({ handleClose })}
        sx={(theme) => menuItem(theme)}
      >
        <Icon fontSize="small">logout</Icon>

        <ZTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
          Log out
        </ZTypography>
      </MenuItem>
      <MenuItem onClick={handleClose} sx={(theme) => menuItem(theme)}>
        <Icon fontSize="small">settings</Icon>

        <ZTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
          Settings
        </ZTypography>
      </MenuItem>
    </Menu>
  );
};

export default AccountMenu;
