import { createSelector } from "@reduxjs/toolkit";

const selectStatic = (state) => state.static;

export const selectClasses = createSelector([selectStatic], (s) => s.classes);
export const selectGroups = createSelector([selectStatic], (s) => s.groups);
export const selectCourses = createSelector([selectStatic], (s) => s.courses);
export const selectHostelType = createSelector(
  [selectStatic],
  (s) => s.hostelType
);
export const selectAccessLevels = createSelector(
  [selectStatic],
  (s) => s.accessLevels
);
export const selectDesignationList = createSelector(
  [selectStatic],
  (s) => s.designationList
);
export const studentFeeCollectionStatus = createSelector(
  [selectStatic],
  (s) => s.statusofPaymentFollowup
);
export const selectContactWithPerson = createSelector(
  [selectStatic],
  (s) => s.contactWithPersonOptions
);
export const concessionTypeList = createSelector(
  [selectStatic],
  (s) => s.concessionTypeList
);
export const SelectFeeTypeList = createSelector(
  [selectStatic],
  (s) => s.feeTypeList
);

export const selectPalnApplicableTo = createSelector(
  [selectStatic],
  (s) => s.palnApplicableTo
);
export const selectReservationType = createSelector(
  [selectStatic],
  (s) => s.reservationType
);
export const selectPaymentMode = createSelector(
  [selectStatic],
  (s) => s.paymentMode
);
export const SelectCancelReciptLogics = createSelector(
  [selectStatic],
  (s) => s.cancelReciptLogics
);
export const SelectConcessionNames = createSelector(
  [selectStatic],
  (s) => s.concessionNames
);
export const SelectStatus = createSelector([selectStatic], (s) => s.status);
export const SelectSource = createSelector([selectStatic], (s) => s.source);
export const SelectApprovedFromList = createSelector(
  [selectStatic],
  (s) => s.ApprovedFromList
);
export const SelectStage = createSelector([selectStatic], (s) => s.stage);
export const SelectResponse = createSelector([selectStatic], (s) => s.response);
export const SelectRemark = createSelector([selectStatic], (s) => s.remarks);
export const selectAdmissionTypes = createSelector(
  [selectStatic],
  (s) => s.admissionTypes
);

export const selectAdmissionToAcademicYear = createSelector(
  [selectStatic],
  (s) => s.admissionToAcademicYear
);

export const selectBoardSubjects = createSelector(
  [selectStatic],
  (s) => s.boardSubjects
);
export const selectStudentStatus = createSelector(
  [selectStatic],
  (s) => s.studentStatus
);
export const selectStudentJoiningYearList = createSelector(
  [selectStatic],
  (s) => s.joiningYear
);
export const selectAccountType = createSelector(
  [selectStatic],
  (s) => s.accountType
);
export const selectBiometricDeviceType = createSelector(
  [selectStatic],
  (s) => s.biometricDeviceType
);
export const selectConcessionType = createSelector(
  [selectStatic],
  (s) => s.concessionType
);
export const selectDaysOfWeek = createSelector(
  [selectStatic],
  (s) => s.daysOfWeek
);
export const selectHallTicketType = createSelector(
  [selectStatic],
  (s) => s.hallTicketTypes
);
