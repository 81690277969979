import { Card, useMediaQuery } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import * as React from "react";
import indexImg from "assets/images/indexImg.png";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import useFetch from "hooks/useFetch";
import { sendOtpEmail } from "APIs/organization/UserManagement";
import SoftNotification from "myComponents/SoftNotification";
import VerifyOTP from "./VerifyOTP";

export default function RequestOTP({ response }) {
  const [value, setValue] = React.useState("email");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [Loading, error, otp, sendMail, reset] = useFetch(sendOtpEmail);
  const [isOpen, setOpen] = React.useState(false);

  const msgs = {
    error: error || "something went wrong.",
    success: "User details fetched.",
    otp: `OTP sent to your ${value}`,
    loading: "Loading please wait...",
  };
  const getFeedback = () => {
    if (Loading) return { msg: msgs.loading, type: "info" };
    if (otp) return { msg: otp.message || msgs.success, type: "success" };
    return { msg: msgs.error, type: "error" };
  };

  React.useEffect(() => {
    if (Loading) setOpen(true);
  }, [Loading]);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleSendOTP = () => {
    if (value === "email") {
      const email = response && response.email ? response.email : "";
      sendMail({ email });
    }
  };
  const handleOTPResent = () => {
    handleSendOTP();
  };
  const renderRadioBtn = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            disabled
            value="mobile"
            control={<Radio />}
            label="Send OTP to mobile number"
          />
          <FormControlLabel
            value="email"
            control={<Radio />}
            label="Send OTP to email id"
          />
        </RadioGroup>
      </FormControl>
      <br />
      <SoftButton
        variant="contained"
        color="info"
        fullWidth
        onClick={handleSendOTP}
      >
        Send OTP
      </SoftButton>
    </SoftBox>
  );

  if (otp && otp.update)
    return (
      <VerifyOTP
        email={response.email}
        userId={response.employeeCode}
        value={value}
        handleOTPResent={handleOTPResent}
      />
    );

  return (
    <CoverLayout
      ifLogoRequired
      title={`Hello ${response.employeeId}`}
      description="Select a method to receive the OTP to reset your MPIN"
      image={indexImg}
    >
      {isMobile ? renderRadioBtn() : <Card>{renderRadioBtn()}</Card>}
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </CoverLayout>
  );
}
