import { Card, Typography, useMediaQuery } from "@mui/material";
import { setMPin } from "APIs/UserServices";
import indexImg from "assets/images/indexImg.png";
import useFetch from "hooks/useFetch";
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftNotification from "myComponents/SoftNotification";
import { useEffect, useState } from "react";
import store from "store";
import MPINInput from "./MPINInput";

const ResetMPIN = ({ userId }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [enteredMPIN, setEnteredMPIN] = useState("");
  const [confirmMPIN, setConfirmMPIN] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isUserLoading, userError, response, createMPIN, resetFetch] =
    useFetch(setMPin);
  const handleMPINChange = (mpin) => {
    setEnteredMPIN(mpin);
  };

  const handleMPINConfirm = (mpin) => {
    setConfirmMPIN(mpin);
  };

  const handleSetMPin = () => {
    if (enteredMPIN === confirmMPIN && confirmMPIN.length === 4) {
      createMPIN({
        employeeId: userId,
        mPin: enteredMPIN,
      });
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (response && response.message) {
      const instituteId = store.get("instituteId");
      store.clearAll();
      store.set("sessionExpired", false);
      store.set("employeeId", userId);
      store.set("instituteId", instituteId);
      const redirectUrl = window.location.origin;
      window.location.replace(redirectUrl);
    }
    if (isUserLoading) setOpen(true);
  }, [response, isUserLoading]);

  const msgs = {
    prevPath: "MPIN created successfully, Sign in with MPIN",
    error: userError || "something went wrong...",
    success: "MPIN created successfully, Sign in with MPIN",
    expired: "Token expired, Login again",
    loggingIn: "Creating MPIN Please wait...",
    mismatch: "MPIN does not  matches.",
  };

  const handleClose = () => {
    resetFetch();
    setOpen(false);
  };

  const getFeedback = () => {
    if (isUserLoading) return { msg: msgs.loggingIn, type: "info" };
    if (response) return { msg: msgs.success, type: "success" };
    if (confirmMPIN !== enteredMPIN)
      return { msg: msgs.mismatch, type: "error" };

    return { msg: msgs.error, type: "error" };
  };
  const renderResetMPIN = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <MPINInput
        title="Enter new MPIN"
        onMPINChange={handleMPINChange}
        ifMatching={
          confirmMPIN.length === 4
            ? enteredMPIN === confirmMPIN
              ? "matched"
              : "notMatched"
            : false
        }
      />
      <MPINInput
        title="Re enter to confirm MPIN"
        onMPINChange={handleMPINConfirm}
        ifMatching={
          confirmMPIN.length === 4
            ? enteredMPIN === confirmMPIN
              ? "matched"
              : "notMatched"
            : false
        }
      />
      <br />
      <Typography
        variant="caption"
        sx={{ display: "block", maxWidth: "320px" }}
      >
        <b>Tips:</b>
        <br /> Change your MPIN regularly for added security. Avoid using easily
        guessable combinations like &quot;1234&quot; or &quot;0000&quot;.
      </Typography>
      <SoftBox mt={4} mb={4}>
        <SoftButton
          type="submit"
          variant="contained"
          color="info"
          onClick={handleSetMPin}
        >
          Reset
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
  return (
    <CoverLayout
      ifLogoRequired
      title={`Hello ${userId}`}
      description="Please, create your new MPIN by typing below"
      image={indexImg}
    >
      {isMobile ? (
        renderResetMPIN()
      ) : (
        <Card sx={{ width: "max-content" }}>{renderResetMPIN()}</Card>
      )}
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </CoverLayout>
  );
};

export default ResetMPIN;
