import { forwardRef } from "react";
import moment from "moment";
// @mui material
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Avatar from "@mui/material/Avatar";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
// my components
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
// custom styles for the NotificationItem
import { menuItem } from "./styles";

export const cat = {
  FINANCE: {
    color: "#A0E9FF",
    icon: <CurrencyRupeeRoundedIcon sx={{ color: "#00A9FF" }} />,
    iconColor: "#00A9FF",
  },
  DOWNLOAD: {
    color: "#A2FF86",
    icon: <FileDownloadRoundedIcon sx={{ color: "#164B60" }} />,
    iconColor: "#164B60",
  },
  STUDENT: {
    color: "#FBEEAC",
    icon: <GroupRoundedIcon sx={{ color: "#1D5D9B" }} />,
    iconColor: "#1D5D9B",
  },
  ADMISSIONS: {
    color: "#F2FFE9",
    icon: <PeopleAltIcon sx={{ color: "#FA7070" }} />,
    iconColor: "#FA7070",
  },
};

const NotificationItem = forwardRef(
  (
    {
      _id,
      url,
      title,
      message,
      channel,
      createdAt,
      handleNotificationClick,
      ...rest
    },
    ref
  ) => (
    <MenuItem
      {...rest}
      ref={ref}
      sx={(theme) => menuItem(theme)}
      onClick={() => handleNotificationClick(_id, url)}
    >
      <SoftBox
        width="2.25rem"
        height="2.25rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        // sx={(theme) => menuImage(theme, { color })}
      >
        <Avatar variant="rounded" sx={{ bgcolor: cat[channel]?.color }}>
          {cat[channel].icon}
        </Avatar>
      </SoftBox>

      <SoftBox>
        <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <SoftTypography
              variant="button"
              textTransform="capitalize"
              fontWeight="regular"
            >
              <strong>{title}</strong>
            </SoftTypography>
          </SoftBox>

          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <Icon color="warning">
              <AccessTimeRoundedIcon />
            </Icon>
            &nbsp;
            <SoftTypography color="secondary" variant="caption">
              {moment(createdAt).format("DD MMM YYYY")}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox>
          <SoftTypography
            variant="button"
            textTransform="capitalize"
            fontWeight="regular"
            sx={{ textWrap: "wrap" }}
            width="100%"
          >
            {message}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </MenuItem>
  )
);

export default NotificationItem;
