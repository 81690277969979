import { Card, Typography, useMediaQuery } from "@mui/material";
import { verifyOTP } from "APIs/organization/UserManagement";
import indexImg from "assets/images/indexImg.png";
import useFetch from "hooks/useFetch";
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftNotification from "myComponents/SoftNotification";
import * as React from "react";
import maskEmailAddress from "utils/maskEmail";
import MPINInput from "./MPINInput";
import ResetMPIN from "./ResetMPIN";

export default function VerifyOTP({ email, userId, value, handleOTPResent }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [Loading, error, result, verify, reset] = useFetch(verifyOTP);
  const [isOpen, setOpen] = React.useState(false);
  const [confirmMPIN, setConfirmMPIN] = React.useState("");
  const handleMPINConfirm = (mpin) => {
    setConfirmMPIN(mpin);
  };
  const msgs = {
    error: error || "something went wrong.",
    success: "OTP verified.",
    loading: "Loading please wait...",
  };
  const getFeedback = () => {
    if (Loading) return { msg: msgs.loading, type: "info" };
    if (result) return { msg: msgs.success, type: "success" };
    return { msg: msgs.error, type: "error" };
  };

  React.useEffect(() => {
    if (Loading) setOpen(true);
  }, [Loading]);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const handleVerifyOTP = () => {
    verify({ otp: confirmMPIN, email });
  };
  const handleResendOTP = () => {
    handleOTPResent();
  };
  const renderRadioBtn = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <MPINInput
        title="Enter your OTP"
        onMPINChange={handleMPINConfirm}
        ifMatching={confirmMPIN.length === 4 ? "matched" : false}
      />
      <br />
      <SoftBox>
        <Typography variant="caption">
          Not yet received?&nbsp;
          <Typography
            component="span"
            color="#08a1c4"
            variant="caption"
            style={{ cursor: "pointer" }}
            onClick={handleResendOTP}
          >
            Resend OTP
          </Typography>
        </Typography>
      </SoftBox>
      <br />
      <SoftButton
        variant="contained"
        color="info"
        fullWidth
        onClick={handleVerifyOTP}
      >
        Verify
      </SoftButton>
    </SoftBox>
  );

  if (result) return <ResetMPIN userId={userId} />;

  return (
    <CoverLayout
      ifLogoRequired
      title={`Hello ${userId}`}
      description={`Please enter OTP sent to your ${value}: ${maskEmailAddress(
        email
      )}`}
      image={indexImg}
    >
      {isMobile ? (
        renderRadioBtn()
      ) : (
        <Card sx={{ width: "max-content" }}>{renderRadioBtn()}</Card>
      )}
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </CoverLayout>
  );
}
