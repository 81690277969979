import baseAxios from "../baseAxios";

const COMMON = "/admissions/followups";

export async function createFollowup(obj) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchFollowups(obj) {
  const { id, ...rest } = obj;
  const path = `${COMMON}/fetch`;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFollowup(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.patch(`${COMMON}/${_id}`, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
