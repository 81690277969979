import baseAxios from "../baseAxios";

const COMMON = "/academics/BMdevice";

export async function createAttendanceDevice(obj) {
  try {
    return (await baseAxios.post(COMMON, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendanceDevice(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateAttendanceDevice(obj) {
  const { _id, ...rest } = obj;
  const path = _id ? `${COMMON}/${_id}` : COMMON;
  try {
    return (await baseAxios.patch(path, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAttendanceDevice(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
