import { SvgIcon } from "@mui/material";
import { ReactComponent as MKey } from "../../assets/fluent-emoji_old-key.svg";

const KeyIcon = () => (
  <SvgIcon sx={{ verticalAlign: "middle" }}>
    <MKey />
  </SvgIcon>
);

export default KeyIcon;
