import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  followups: [],
  counsellorsByFollowups: [],
};
const followupsSlice = createSlice({
  name: "FOLLOWUP",
  initialState,
  reducers: {
    fetchFollowsStart() {},
    fetchFollowupsSuccess(state, action) {
      state.followups = action.payload;
    },
    fetchFollowsStartByCounsellor() {},
    fetchFollowupsByCounsellorSuccess(state, action) {
      state.counsellorsByFollowups = action.payload;
    },
    createFollowupStart() {},
    createFollowupSuccess(state, action) {
      state.followups = [...state.followups, action.payload];
    },
    clearFollowups(state) {
      state.followups = [];
    },
    updateFollowupStart() {},
    updateFollowupSuccess(state, action) {
      state.followups = getUpdatedList(action.payload, state.followups);
    },
  },
});

export const {
  createFollowupStart,
  createFollowupSuccess,
  updateFollowupStart,
  updateFollowupSuccess,
  fetchFollowsStart,
  fetchFollowupsSuccess,
  fetchFollowsStartByCounsellor,
  fetchFollowupsByCounsellorSuccess,
  clearFollowups,
} = followupsSlice.actions;

export default followupsSlice.reducer;
