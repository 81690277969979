import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import { fetch, create } from "../../app/app.utils";
// actions
import {
  fetchAttendanceSuccess,
  resetAttendance,
  fetchStudentSuccess,
  saveAttendanceSuccess,
  saveSMSreport,
  fetchAttendanceReportSuccess,
  saveManualResult,
  fetchDailyAttendanceSuccess,
  fetchAttendanceSummarySuccess,
  fetchSMSTasksSuccess,
  fetchPuncesReportSuccess,
  fetchSinglePuncesReportSuccess,
  fetchSingleStudentManAttReportSuccess,
  fetchCumulativeAttReportSuccess,
} from "./attendance.slice";
// // apis
import {
  fetchAttendance,
  createAttendance,
  fetchAttendanceReport,
  saveManualAttendance,
  getDailyAttendance,
  fetchAttendanceSummary,
  getSMSTasks,
  fetchPuncesReport,
  fetchSinglePuncesReport,
  fetchSingleStudentManAttReport,
  fetchCumulativeAttReport,
} from "../../../APIs/academics/attendance";
// // utils
import { getState } from "../../selector.utils";
import {
  selectAttendanceParams,
  selectAttendanceToSave,
} from "./attendance.selectors";
// import getTimeFromDate from "../../../utils/getTime";
// import useGetFilterSections from "./useGetFilterSections";

export function* fetchAttendanceAsync() {
  const searchParams = yield selectAttendanceParams(getState());

  yield fetch({
    callName: "fetch attendance",
    query: { ...searchParams },
    api: fetchAttendance,
    *handleRes(items) {
      yield put(fetchStudentSuccess(items.students));
      if (!searchParams.onlyStudents)
        yield put(fetchAttendanceSuccess(items.absentees));
      yield put(resetAttendance());
    },
    targetPath: "result",
  });
}

export function* fetchAttendanceReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_attendance_report",
    query: payload,
    api: fetchAttendanceReport,
    *handleRes(items) {
      yield put(fetchAttendanceReportSuccess(items));
    },
    targetPath: "report",
  });
}
export function* fetchAttendanceSummaryAsync({ payload }) {
  yield fetch({
    callName: "fetch_attendance_Summary",
    query: payload,
    api: fetchAttendanceSummary,
    *handleRes(items) {
      yield put(fetchAttendanceSummarySuccess(items));
    },
    targetPath: "result",
  });
}

export function* fetchDailyReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_daily_attendance",
    query: payload,
    api: getDailyAttendance,
    *handleRes(items) {
      yield put(fetchDailyAttendanceSuccess(items));
    },
    targetPath: "result",
  });
}

export function* saveAttendanceAsync() {
  const data = yield selectAttendanceToSave(getState());
  yield create({
    callName: "save attendance",
    inputs: data,
    api: createAttendance,
    *handleRes(res) {
      yield put(saveAttendanceSuccess(res.savedAttendance));
      yield put(saveSMSreport(res.smsReport));
      yield put(resetAttendance());
    },
  });
}

export function* saveManualAttendanceAsync({ payload }) {
  const { query } = payload;
  yield create({
    callName: "save_manual_attendance",
    inputs: payload,
    api: saveManualAttendance,
    *handleRes(res) {
      yield put(saveManualResult(res));
      const report = yield getDailyAttendance(query);
      if (report) yield put(fetchDailyAttendanceSuccess(report.result));
    },
    resName: "results",
  });
}

export function* fetchSMSTasksAsync() {
  yield fetch({
    callName: "fetch_sms_tasks",
    api: getSMSTasks,
    *handleRes(res) {
      yield put(fetchSMSTasksSuccess(res));
    },
    targetPath: "result",
  });
}

export function* fetchPuncesReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_punches_report",
    query: payload,
    api: fetchPuncesReport,
    *handleRes(items) {
      yield put(fetchPuncesReportSuccess(items));
    },
    targetPath: "result",
  });
}
export function* fetchSinglePuncesReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_punches_report",
    query: payload,
    api: fetchSinglePuncesReport,
    *handleRes(items) {
      yield put(fetchSinglePuncesReportSuccess(items));
    },
    targetPath: "result",
  });
}
export function* fetchSingleStudentManAttReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_single_att_report",
    query: payload,
    api: fetchSingleStudentManAttReport,
    *handleRes(items) {
      yield put(fetchSingleStudentManAttReportSuccess(items));
    },
    targetPath: "result",
  });
}
export function* fetchCumulativeAttReportAsync({ payload }) {
  yield fetch({
    callName: "fetch_cumulative_att_report",
    query: payload,
    readLength: false,
    api: fetchCumulativeAttReport,
    *handleRes(items) {
      yield put(fetchCumulativeAttReportSuccess(items));
    },
    targetPath: "results",
  });
}

export function* onFetchAttendaceStart() {
  yield takeLatest("ATTENDANCE/fetchAttendanceStart", fetchAttendanceAsync);
}

export function* onSaveAttendanceStart() {
  yield takeLatest("ATTENDANCE/saveAttendanceStart", saveAttendanceAsync);
}

export function* onFetchAttendaceReportStart() {
  yield takeLatest(
    "ATTENDANCE/fetchAttendanceReportStart",
    fetchAttendanceReportAsync
  );
}
export function* onFetchAttendaceSummaryStart() {
  yield takeLatest(
    "ATTENDANCE/fetchAttendanceSummaryStart",
    fetchAttendanceSummaryAsync
  );
}
export function* onSaveManualAttendanceStart() {
  yield takeLatest(
    "ATTENDANCE/saveManualAttendanceStart",
    saveManualAttendanceAsync
  );
}

export function* onFetchDailyAttendanceStart() {
  yield takeLatest(
    "ATTENDANCE/fetchDialyAttendanceStart",
    fetchDailyReportAsync
  );
}

export function* onFetchSMSTasksStart() {
  yield takeLatest("ATTENDANCE/fetchSMSTasksStart", fetchSMSTasksAsync);
}

export function* onFetchPuncesReportStart() {
  yield takeLatest("ATTENDANCE/fetchPuncesReportStart", fetchPuncesReportAsync);
}
export function* onFetchSinglePuncesReportStart() {
  yield takeLatest(
    "ATTENDANCE/fetchSinglePuncesReportStart",
    fetchSinglePuncesReportAsync
  );
}
export function* onfetchSingleStudentManAttReportStart() {
  yield takeLatest(
    "ATTENDANCE/fetchSingleStudentManAttReportStart",
    fetchSingleStudentManAttReportAsync
  );
}
export function* onfetchCumulativeAttReportStart() {
  yield takeLatest(
    "ATTENDANCE/fetchCumulativeAttReportStart",
    fetchCumulativeAttReportAsync
  );
}

export default function* attendanceSaga() {
  yield all([
    call(onFetchAttendaceStart),
    call(onSaveAttendanceStart),
    call(onFetchAttendaceReportStart),
    call(onFetchAttendaceSummaryStart),
    call(onSaveManualAttendanceStart),
    call(onFetchDailyAttendanceStart),
    call(onFetchSMSTasksStart),
    call(onFetchPuncesReportStart),
    call(onFetchSinglePuncesReportStart),
    call(onfetchSingleStudentManAttReportStart),
    call(onfetchCumulativeAttReportStart),
  ]);
}
