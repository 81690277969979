import { takeLatest, put, all, call } from "redux-saga/effects";
// apis
import {
  createBatches,
  fetchBatchesList,
  updateBatch,
  deleteBatch,
} from "../../../APIs/organization/batches";
// actions
import {
  createBatchesSuccess,
  resetBatchesData,
  fetchBatchesSuccess,
  updateBatchSuccess,
  deleteBatchSuccess,
  fetchBatchesAySuccess,
} from "./batches.slice";
import { setInitializationStatus } from "../../app/app.slice";
// utils
import { fetch, create, update, remove } from "../../app/app.utils";
import { getState } from "../../selector.utils";
// selectors
import { selectBatchesData } from "./batches.selectors";
import { selectIsAppInitialized } from "../../app/app.selectors";

export function* createBatchesAsync() {
  const batchesData = selectBatchesData(getState());
  yield create({
    callName: "create batch",
    inputs: batchesData,
    api: createBatches,
    *handleRes(data) {
      yield put(createBatchesSuccess(data));
      yield put(resetBatchesData());
    },
    resName: "savedBatches",
  });
}

export function* fetchBatchesAsync() {
  const {
    showFeedback = true,
    flag,
    ...rest
  } = yield selectBatchesData(getState());

  yield fetch({
    callName: "fetch batches[s]",
    query: { ...rest },
    api: fetchBatchesList,
    *handleRes(items) {
      if (flag) yield put(fetchBatchesAySuccess(items));
      else {
        yield put(fetchBatchesSuccess(items));
      }
      const isAppInitialized = selectIsAppInitialized(getState());
      if (!isAppInitialized)
        yield put(setInitializationStatus({ fetchedBatches: true }));
      yield put(resetBatchesData());
    },
    showFeedback,
  });
}

export function* updateBatchAsync() {
  const updates = yield selectBatchesData(getState());

  yield update({
    callName: "update batch",
    updates,
    api: updateBatch,
    *handleRes(batch) {
      yield put(updateBatchSuccess(batch));
      yield put(resetBatchesData());
    },
    updatedName: "updatedBatch",
  });
}

export function* deleteBatchAsync() {
  const stuff = yield selectBatchesData(getState());

  yield remove({
    callName: "delete batch",
    id: stuff._id,
    api: deleteBatch,
    *handleRes(batch) {
      yield put(deleteBatchSuccess(batch));
      yield put(resetBatchesData());
    },
    deletedName: "deletedBatch",
  });
}

export function* onBatchesCreationStart() {
  yield takeLatest("BATCHES/createBatchesStart", createBatchesAsync);
}

export function* onFetchBatchesStart() {
  yield takeLatest("BATCHES/fetchBatchesStart", fetchBatchesAsync);
}

export function* onUpdateBatchStart() {
  yield takeLatest("BATCHES/updateBatchStart", updateBatchAsync);
}

export function* onDeleteBatchStart() {
  yield takeLatest("BATCHES/deleteBatchStart", deleteBatchAsync);
}

export default function* batchesSaga() {
  yield all([
    call(onBatchesCreationStart),
    call(onFetchBatchesStart),
    call(onUpdateBatchStart),
    call(onDeleteBatchStart),
  ]);
}
