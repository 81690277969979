import { useState } from "react";

export default function useFetch(APIMethod = Promise) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (payload) => {
    try {
      setIsLoading(true);
      const response = await APIMethod(payload);

      setData(response);
    } catch (err) {
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFetch = () => {
    setData(null);
    setError(null);
    setIsLoading(false);
  };

  return [isLoading, error, data, fetchData, resetFetch];
}
