import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";
import notFound from "assets/notFound.png";
import JSONAnimation from "animations";
import { useSelector } from "react-redux";
import { selectIsAppInitialized } from "redux/app/app.selectors";

const NotFound = () => {
  const isAppInitialized = useSelector(selectIsAppInitialized);
  if (!isAppInitialized)
    return (
      <SoftBox
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <JSONAnimation type="loading" />
      </SoftBox>
    );
  return (
    <>
      <SoftBox justifyContent="center" alignItems="center" p={7}>
        <SoftTypography
          color="error"
          align="center"
          variant="h2"
          textAlign="center"
        >
          This page is inaccessible due to restricted permissions
        </SoftTypography>
      </SoftBox>
      &nbsp;
      <SoftBox
        sx={{
          justifyContent: "center",
          display: "flex",
          height: "20vh",
          alignItems: "center",
        }}
        p={3}
      >
        <img src={notFound} alt="Not Found" />
      </SoftBox>
    </>
  );
};
export default NotFound;
