import SoftTypography from "myComponents/SoftTypography";

const FormFieldError = ({ error, ...styles }) =>
  error ? (
    <SoftTypography
      variant="caption"
      color="error"
      sx={{
        ...styles,
      }}
    >
      *{error}
    </SoftTypography>
  ) : null;

export default FormFieldError;
