import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
// react-router components
import { useLocation } from "react-router-dom";
// api
// import { fetchInstituteAyConfig } from "APIs/settings/configrationServices";
// mui
import Icon from "@mui/material/Icon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "@mui/material/Toolbar";

import IconButton from "@mui/material/IconButton";
// Soft UI Dashboard React components
import SoftBox from "myComponents/SoftBox";
// hooks
// import useFetch from "hooks/useFetch";
// Soft UI Dashboard React context
import {
  setOpenConfigurator,
  useSoftUIController,
  setMiniSidenav,
} from "context";
// selectors
import { selectAcademicYear } from "redux/app/app.selectors";
import { selectAcademicYears } from "redux/organization/academicYear/academicYear.selectors";
// actions
import {
  setAcademicYear,
  fetchBranchesAndBatchesStart,
} from "redux/app/app.slice";

import {
  // fetchSummaryStart,
  fetchBasicSummaryStart,
} from "redux/dashboard/Summary/dashboard.slice";
import { fetchAyStart } from "redux/settings/settings.slice";
// Soft UI Dashboard React examples
import AccountMenu from "./AccountMenu";
import AcademicYearMenu from "./AcademicYearMenu";
import Breadcrumbs from "./BreadCrumbs";
import Notifications from "./Notifications";
import { AppBar } from "../styles";
// // utils
// import getTimeFromDate from "../../../utils/general.utils/getTime";
// Custom styles for DashboardNavbar
import NotificationBell from "./Notifications/NotificationBell";
import { navbarContainer, navbarIconButton, navbarRow } from "./styles";
// selectors

function DashboardNavbar({ light, isMini = false }) {
  const isMobile = useMediaQuery("(max-width:992px)");
  const dispatchReduxAction = useDispatch();
  const { pathname } = useLocation();
  const academicYears = useSelector(selectAcademicYears);
  const ay = useSelector(selectAcademicYear);
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [ayOpen, setAyOpen] = useState(false);
  // const [year, setYear] = useState({});
  const route = useLocation().pathname.split("/").slice(1);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // console.log(location.pathname, year);

  useEffect(() => {
    if (ay) {
      const getTime = (date) => new Date(date).getTime();
      const { startDate, endDate, code } = ay;
      const period = `${getTime(startDate)}-${getTime(endDate)}`;
      store.set("academicYear", period);
      store.set("ay", code);

      dispatchReduxAction(fetchAyStart({ ay: ay.code }));

      const query = {
        // start: getTimeFromDate(ay.startDate),
        // end: getTimeFromDate(ay.endDate),
        showFeedback: false,
        noTimeFilter: true,
        ay: ay?.code,
        flag: true,
      };
      dispatchReduxAction(fetchBranchesAndBatchesStart({ ...query }));
    }
  }, [ay]);
  useEffect(() => {
    if (ay && pathname === "/app") {
      const getTime = (date) => new Date(date).getTime();
      const { startDate, endDate, code } = ay;
      const period = `${getTime(startDate)}-${getTime(endDate)}`;
      store.set("academicYear", period);
      store.set("ay", code);

      const actions = [];
      actions.push(
        dispatchReduxAction(
          fetchBasicSummaryStart({ ay: ay.code, type: "ATTENDACE" })
        )
      );
      actions.push(
        dispatchReduxAction(
          fetchBasicSummaryStart({ ay: ay.code, type: "ADMISSIONS" })
        )
      );
      actions.push(
        dispatchReduxAction(
          fetchBasicSummaryStart({ ay: ay.code, type: "FINANCE" })
        )
      );
      actions.push(
        dispatchReduxAction(
          fetchBasicSummaryStart({ ay: ay.code, type: "CURRENT_ADMISSIONS" })
        )
      );

      if (actions.length > 0) {
        Promise.all(actions)
          .then((results) => {
            console.log("All actions executed:", results);
          })
          .catch((error) => {
            console.error("Error in executing actions:", error);
          });
      }
    }
  }, [pathname, ay]);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <AccountMenu open={openMenu} handleClose={handleCloseMenu} />
  );

  const handleAyClick = (a) => {
    dispatchReduxAction(setAcademicYear(a));
    // setYear(a);
    // dispatchReduxAction(fetchSummaryStart({ ay: a.code, noTimeFilter: true }));

    if (isMobile) setAyOpen(false);
  };

  return (
    <AppBar color="dark" open={miniSidenav}>
      <Toolbar sx={(theme) => navbarContainer(theme)} variant="dense">
        <SoftBox
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
          color={light ? "white" : "inherit"}
        >
          {!isMobile && !miniSidenav ? (
            <IconButton
              color={light ? "white" : "inherit"}
              onClick={handleMiniSidenav}
              sx={(theme) => navbarIconButton(theme, { isMini })}
            >
              <Icon>{!miniSidenav ? "menu" : "menu_open"}</Icon>
            </IconButton>
          ) : null}

          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </SoftBox>
        <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
          {isMobile && !miniSidenav ? (
            <IconButton
              color={light ? "white" : "inherit"}
              onClick={handleMiniSidenav}
              sx={(theme) => navbarIconButton(theme, { isMini })}
            >
              <Icon>{!miniSidenav ? "menu" : "menu_open"}</Icon>
            </IconButton>
          ) : null}
          <SoftBox
            color={light ? "white" : "inherit"}
            sx={{
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SoftBox>
              <AcademicYearMenu
                academicYears={academicYears}
                open={ayOpen}
                handleAyClick={handleAyClick}
                ayItem={ay}
                color={light ? "white" : "inherit"}
              />
            </SoftBox>
            <Notifications />
            {/* <NotificationBell /> */}

            <IconButton
              color="inherit"
              onClick={() => {
                dispatchReduxAction({ type: "RE_INITIALIZE_APP" });
              }}
              sx={(theme) => navbarIconButton(theme)}
            >
              <Icon>refresh</Icon>
            </IconButton>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleConfiguratorOpen}
              sx={(theme) => navbarIconButton(theme)}
            >
              <Icon>tune</Icon>
            </IconButton>
            {renderMenu()}
          </SoftBox>
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

export default DashboardNavbar;
