import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import { fetch, create, update, remove } from "../../app/app.utils";
// actions
import {
  fetchAttendanceDeviceSuccess,
  updateAttendanceDeviceSuccesss,
  saveAttendanceDeviceSuccess,
  deleteAttendanceDeviceSuccess,
} from "./BmDevice.slice";
// // apis
import {
  fetchAttendanceDevice,
  createAttendanceDevice,
  updateAttendanceDevice,
  deleteAttendanceDevice,
} from "../../../APIs/academics/bmDevice";

export function* fetchAttendanceDeviceAsynce({ payload }) {
  yield fetch({
    callName: "fetch_attendance_Device",
    query: payload,
    api: fetchAttendanceDevice,
    *handleRes(items) {
      yield put(fetchAttendanceDeviceSuccess(items));
    },
  });
}

export function* saveAttendanceDeviceAsync({ payload }) {
  yield create({
    callName: "save_attendance_Device",
    inputs: payload,
    api: createAttendanceDevice,
    *handleRes(res) {
      yield put(saveAttendanceDeviceSuccess(res.saved));
    },
  });
}
export function* deleteAttendanceDeviceAsync({ payload }) {
  yield remove({
    callName: "delete_attendance_Device",
    inputs: payload,
    api: deleteAttendanceDevice,
    *handleRes(res) {
      yield put(deleteAttendanceDeviceSuccess(res));
    },
    deletedName: "deleteDevice",
  });
}

export function* updateAttendanceDeviceAsync({ payload }) {
  yield update({
    callName: "update_attendance_Device",
    updates: payload,
    api: updateAttendanceDevice,
    *handleRes(item) {
      yield put(updateAttendanceDeviceSuccesss(item));
    },
    updatedName: "updatedDevice",
  });
}

export function* onFetchAttendaceDeviceStart() {
  yield takeLatest(
    "BM_DEVICE_DETAILS/fetchAttendanceDeviceStart",
    fetchAttendanceDeviceAsynce
  );
}

export function* onSaveAttendanceDeviceStart() {
  yield takeLatest(
    "BM_DEVICE_DETAILS/saveAttendanceDeviceStart",
    saveAttendanceDeviceAsync
  );
}
export function* onDeleteAttendanceDeviceStart() {
  yield takeLatest(
    "BM_DEVICE_DETAILS/deleteAttendanceDeviceStart",
    deleteAttendanceDeviceAsync
  );
}

export function* onUpdateAttendanceDeviceStart() {
  yield takeLatest(
    "BM_DEVICE_DETAILS/updateAttendanceDeviceStart",
    updateAttendanceDeviceAsync
  );
}

export default function* attendanceSaga() {
  yield all([
    call(onFetchAttendaceDeviceStart),
    call(onSaveAttendanceDeviceStart),
    call(onUpdateAttendanceDeviceStart),
    call(onDeleteAttendanceDeviceStart),
  ]);
}
