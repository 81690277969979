import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FieldArray } from "formik";
import { useState } from "react";
import FormIndex from "../../../../../../components/FormIndex";
import fieldsMap from "../../../../../../utils/Fields/fieldsMap";
import FormField from "../../../../../../components/FormIndex/FormField";
import {
  FIELD_NAMES,
  SELECT_TYPES,
} from "../../../../../../config/globalConsts";
import {
  concessionTypeList,
  SelectConcessionNames,
  SelectApprovedFromList,
} from "../../../../../../redux/static/static.selectors";
import {
  FieldDeleteButton,
  FieldArrayAddButton,
  FormTag,
} from "../../../../../../components/FormikFieldArrayComponents";
import { getObjectValues } from "../../../../../../utils/general.utils/object.utils";

const CreateFeePlan = () => {
  const concessionTypes = useSelector(concessionTypeList);
  const concessionNames = useSelector(SelectConcessionNames);
  const approvedFromList = useSelector(SelectApprovedFromList);
  const location = useLocation();
  const [formikValues, setFormikValues] = useState(null);
  const handleFormikValues = (values) => {
    setFormikValues(values);
    console.log(values);
  };

  // for edit particular row props
  const {
    // _id,
    editRow,
    editMode,
  } = location.state;
  //   console.log(editRow);
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "8px",
        p: 1,
      }}
    >
      <FormIndex
        initialValues={{
          branch: editRow?.branch || null,
          concessionType: editRow?.concessionType || "",
          concessionFeeName: editRow?.concessionFeeName || null,
          approvedLevels: editRow?.approvedLevels || [{ level1: "" }],
        }}
        title="concession Approval Levels"
        link
        mode={editMode}
        // editModeValues={editRow || null}
        // handleSubmit={handleSubmit}
        handleFormikValues={handleFormikValues}
      >
        <FormField
          details={fieldsMap[FIELD_NAMES.BRANCH]({
            selectType: SELECT_TYPES.SINGLE,
            name: "branch",
            idName: "branchId",
          })}
          //   required
        />

        <FormField
          details={fieldsMap[FIELD_NAMES.DROPDOWN_FIELD]({
            selectType: SELECT_TYPES.SINGLE,
            name: "concessionType",
            idName: "value",
          })}
          options={getObjectValues(concessionTypes)}
          required
        />
        <FormField
          details={fieldsMap[FIELD_NAMES.DROPDOWN_FIELD]({
            selectType: SELECT_TYPES.MULTI,
            name: "concessionFeeName",
            idName: "value",
          })}
          options={getObjectValues(concessionNames)}
          required
        />

        <FormTag title="Add Approval Levels">
          <FieldArray name="approvedLevels">
            {({ remove, push }) => (
              <>
                <Grid
                  container
                  display="flex"
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {formikValues !== null
                    ? formikValues.approvedLevels.map((_, index) => (
                        <>
                          <FormField
                            details={fieldsMap[FIELD_NAMES.DROPDOWN_FIELD]({
                              selectType: SELECT_TYPES.SINGLE,
                              name: `approvedLevels.${index}.level${index + 1}`,
                              idName: "value",
                            })}
                            options={getObjectValues(approvedFromList)}
                            required
                          />

                          <FieldDeleteButton index={index} remove={remove} />
                        </>
                      ))
                    : null}
                </Grid>

                <FieldArrayAddButton
                  label="Add Level"
                  handlePush={() => push()}
                />
              </>
            )}
          </FieldArray>
        </FormTag>
      </FormIndex>
    </Box>
  );
};
export default CreateFeePlan;
