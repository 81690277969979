import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/organization/branches";

export async function createBranch(obj) {
  const { headers, body } = obj;

  try {
    return (await baseAxios.post(COMMON, body, { headers })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function branchExists(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/exists`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchesList(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (
      await baseAxios.get(path, {
        params: { ...rest, instituteId: store.get("instituteId") },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateBranch(obj) {
  const { branchCode, ...rest } = obj;
  try {
    return (
      await baseAxios.patch(`organization/branches/${branchCode}`, { ...rest })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateBranchImages(obj) {
  const { branchCode, headers, formData } = obj;
  try {
    return (
      await baseAxios.patch(
        `organization/branches/images/${branchCode}`,
        formData,
        {
          headers,
        }
      )
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteBranch(id) {
  try {
    return (await baseAxios.delete(`organization/branches/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBranchesList(params) {
  try {
    return (await baseAxios.get(`organization/branches/list`, { params })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createSimpleBranch(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/simple`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editSimpleBranch(obj) {
  try {
    return (await baseAxios.patch(`${COMMON}/simple`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
