// import { lazy } from "react";
// icons
import ChromeReaderModeRoundedIcon from "@mui/icons-material/ChromeReaderModeRounded";
// body layout
// roles
// import roles from "../roles";
import flatten from "lodash/flatten";
// Helpers
import lazyWithRetry from "helpers/LazyImport";
import Container from "../../layout/simpleContainer";

// features
import features from "../features";
// modules
const StaffRegistration = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffRegistration")
);
const StaffUpdate = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffUpdate")
);
const StaffRegistrationForm = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffRegistration/form")
);
const StaffDeptForm = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffRegistration/AddDepartments")
);
const StaffQuaForm = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffRegistration/AddQulaification")
);
const StaffDesignationForm = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffRegistration/AddDesignations")
);
const ShiftManagement = lazyWithRetry(() =>
  import("../../views/HrPayRoll/Shifts")
);
const CreateShift = lazyWithRetry(() =>
  import("../../views/HrPayRoll/Shifts/createShift")
);
const UpdateShift = lazyWithRetry(() =>
  import("../../views/HrPayRoll/Shifts/updateShift")
);
const ScheduleMgnt = lazyWithRetry(() =>
  import("../../views/HrPayRoll/ScheduleMgnt")
);
const CreateSchedule = lazyWithRetry(() =>
  import("../../views/HrPayRoll/ScheduleMgnt/createSchedule")
);
const StaffProfile = lazyWithRetry(() =>
  import("../../views/HrPayRoll/StaffProfile")
);

export default {
  component: <Container />,
  title: "Payroll and HR",
  basePath: "app",
  path: "payroll-and-hr",
  icon: <ChromeReaderModeRoundedIcon />,
  features: flatten(Object.values(features.payroll_and_hr)),

  children: [
    {
      component: <Container />,
      index: <StaffRegistration />,
      title: "Staff Registration",
      basePath: "payroll-and-hr",
      path: "staff-registration",
      features: features.payroll_and_hr?.staff,
      children: [
        {
          component: <StaffRegistrationForm />,
          title: "Create Staff",
          basePath: "staff-registration",
          path: "create",
          features: ["staff_write"],
        },
        {
          component: <StaffDeptForm />,
          title: "Add Staff Departments",
          basePath: "staff-registration",
          path: "add-dept",
          features: ["departments_read", "departments_write"],
        },
        {
          component: <StaffQuaForm />,
          title: "Add Staff Qualification",
          basePath: "staff-registration",
          path: "add-qua",
          features: ["qualifications_read", "qualifications_write"],
        },
        {
          component: <StaffDesignationForm />,
          title: "Add Staff Designation",
          basePath: "staff-registration",
          path: "add-desig",
          features: ["designations_read", "designations_write"],
        },
      ],
    },
    {
      component: <StaffUpdate />,
      title: "Staff Profile",
      basePath: "payroll-and-hr",
      path: "staff-update",
      features: ["staff_profile", "staff_write"],
      children: [],
      // noUiLink: true,
    },
    {
      component: <Container />,
      index: <ShiftManagement />,
      title: "Shifts",
      basePath: "payroll-and-hr",
      path: "shifts",
      features: features.payroll_and_hr?.staff_shifts,
      children: [
        {
          component: <CreateShift />,
          title: "Create Shift",
          basePath: "shifts",
          path: "create",
          features: ["shifts_write"],
        },
        {
          component: <UpdateShift />,
          title: "Update Shift",
          basePath: "shifts",
          path: "update",
          features: ["shifts_write"],
        },
      ],
    },

    {
      component: <Container />,
      index: <ScheduleMgnt />,
      title: "Schedule Management",
      basePath: "payroll-and-hr",
      path: "schedule-mgnt",
      features: features.payroll_and_hr?.staff_schedule,
      children: [
        {
          component: <CreateSchedule />,
          title: "Create Schedule",
          basePath: "schedule-mgnt",
          path: "create",
          features: features.payroll_and_hr?.staff_schedule,
        },
      ],
    },
    // {
    //   component: <StaffProfile />,
    //   title: "Staff Profile",
    //   basePath: "payroll-and-hr",
    //   path: "staff-profile",
    //   features: ["staff_read"],
    //   children: [],
    // },
  ],
};
