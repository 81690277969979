import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import { fetch, create, update } from "../../app/app.utils";
// actions
import {
  fetchAttendanceTimingsSuccess,
  updateAttendanceTimingsSuccesss,
  saveAttendanceTimingsSuccess,
} from "./timings.slice";
// // apis
import {
  fetchAttendanceTimings,
  createAttendanceTimings,
  updateAttendanceTimings,
} from "../../../APIs/academics/timings";

export function* fetchAttendanceTimingsAsynce({ payload }) {
  yield fetch({
    callName: "fetch_attendance_timings",
    query: payload,
    api: fetchAttendanceTimings,
    *handleRes(items) {
      yield put(fetchAttendanceTimingsSuccess(items));
    },
  });
}

export function* saveAttendanceTimingsAsync({ payload }) {
  yield create({
    callName: "save_attendance_timings",
    inputs: payload,
    api: createAttendanceTimings,
    *handleRes(res) {
      yield put(saveAttendanceTimingsSuccess(res));
    },
    resName: "saved",
  });
}

export function* updateAttendanceTimingsAsync({ payload }) {
  yield update({
    callName: "update_attendance_timings",
    updates: payload,
    api: updateAttendanceTimings,
    *handleRes(item) {
      yield put(updateAttendanceTimingsSuccesss(item));
    },
    updatedName: "updatedTimings",
  });
}

export function* onFetchAttendaceTimingsStart() {
  yield takeLatest(
    "ATTENDANCE_TIMINGS/fetchAttendanceTimingsStart",
    fetchAttendanceTimingsAsynce
  );
}

export function* onSaveAttendanceTimingsStart() {
  yield takeLatest(
    "ATTENDANCE_TIMINGS/saveAttendanceTimingsStart",
    saveAttendanceTimingsAsync
  );
}

export function* onUpdateAttendanceTimingsStart() {
  yield takeLatest(
    "ATTENDANCE_TIMINGS/updateAttendanceTimingsStart",
    updateAttendanceTimingsAsync
  );
}

export default function* attendanceSaga() {
  yield all([
    call(onFetchAttendaceTimingsStart),
    call(onSaveAttendanceTimingsStart),
    call(onUpdateAttendanceTimingsStart),
  ]);
}
