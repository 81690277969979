import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

const Feedback = ({
  open,
  handleClose,
  msg,
  position,
  type = "success",
  duration,
  aletStyles,
}) => {
  const vertical = position ? position.x : "top";
  const horizontal = position ? position.y : "center";
  const auto = duration === null ? null : 3000;
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={auto}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        bodyStyle={{ color: "#ffff" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={type}
          onClose={handleClose}
          sx={{ ...aletStyles }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Feedback;
