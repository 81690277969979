import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/hostel_management/hostel";

export async function createHostel(obj) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchHostels(obj) {
  try {
    return (await baseAxios.get(COMMON, { params: { ...obj } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function bulkUpload(obj) {
  const { phase, body, verified } = obj;
  try {
    return (
      await baseAxios.post(
        `${COMMON}/add?phase=${phase}&instituteId=${store.get(
          "instituteId"
        )}&verified=${verified}`,
        body
      )
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function bulkUpdate(obj) {
  const { phase, body } = obj;

  try {
    const res = await baseAxios.post(
      `${COMMON}/update?phase=${phase}&instituteId=${store.get("instituteId")}`,
      body
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
