import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { alpha, styled } from "@mui/material/styles";

const ODD_OPACITY = 0.2;
const StyledDataGrid = styled(DataGridPro, {
  shouldForwardProp: (prop) => prop !== "cellBorder",
})(({ theme, cellBorder }) => {
  const borderColor = "#e9ecef";
  const styles = {
    border: "none",
    "& .custom-style-row-summary": {
      backgroundColor: theme.palette.info.lightest,
      ":hover": {
        backgroundColor: theme.palette.info.lightest,
      },
    },

    "& .MuiDataGrid-colCell": {
      textTransform: "capitalize",
    },
    "& .MuiDataGrid-columnHeaders": {
      color: theme.palette.secondary.main,
      fontSize: "0.75rem",
      lineHeight: 3.25,
      fontWeight: "bold",
    },
    "& .MuiDataGrid-root, .MuiDataGrid-treeDataGroupingCell": {
      color: "#31456A",
      fontSize: "13px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      "& .MuiDataGrid-cellContent": {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "0.75rem",
        lineHeight: 1.25,
        letterSpacing: "0.03333em",
        opacity: 1,
        textTransform: "none",
        verticalAlign: "unset",
        textDecoration: "none",
        color: "rgb(103, 116, 142)",
        fontWeight: 500,
      },
      "& .MuiDataGrid-cellValue": {
        display: "flex",
        alignItems: "center",
      },
      "& .MuiSelect-icon": {
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& .dataGrid-options-container:empty::before": {
        color: "gray",
      },
    },

    "& .MuiDataGrid-iconSeparator": {
      color: "red",
      fontSize: "50px" /* Adjust the size as needed */,
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowX: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          "& .MuiDataGrid-cell": {
            "& .MuiInputBase-root.MuiDataGrid-editInputCell": {
              height: "95% !important",
              overflowX: "auto",
            },
          },
        },
      },
    },

    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          paddingTop: 2,
          "& .MuiInputBase-root": {
            width: 70,
          },
        },
      },
    },

    "& .MuiInputBase-root-MuiTablePagination-select": {
      width: "5%",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#FAFAFA",
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.info.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.info.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.info.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  };
  if (cellBorder) {
    styles["& .MuiDataGrid-cell"] = {
      border: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderBottom: `1px solid ${borderColor}`,
    };
    styles["& .MuiDataGrid-columnHeader"] = {
      border: `1px solid ${borderColor}`,
    };
    styles["& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell"] = {
      borderBottomColor: `${borderColor} !important`,
    };
  }

  return styles;
});

export default StyledDataGrid;
