import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allExams: [],
};

const examsSlice = createSlice({
  name: "EXAMS",
  initialState,
  reducers: {
    setAllExams(state, action) {
      state.allExams = action.payload;
    },
    clearAllExams(state) {
      state.allExams = [];
    },
  },
});

export const { setAllExams, clearAllExams } = examsSlice.actions;

export default examsSlice.reducer;
