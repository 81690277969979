import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectAcademicYearState = (state) => state.academicYearsManagement;

export const selectAcademicYears = createSelector(
  [selectAcademicYearState],
  (state) => state.academicYears
);

const academicYearSelectors = () => {
  // pulling stuff from redux state
  // academic years list
  const academicYears = useSelector((state) => {
    const yearsState = state.academicYearsManagement;
    const list = yearsState.academicYears;
    return list;
  });
  // error message from api-call on CRUD ops on  academic year
  const errorMsg = useSelector((state) => {
    const msg = state.academicYearsManagement.error;
    return msg;
  });
  // status of api-call could be
  // "info" - call made
  // "success" - call is successful
  // "error" - something went wrong
  const status = useSelector((state) => {
    const res = state.academicYearsManagement.status;
    return res;
  });
  // type of api-call --> fetch, update, delete
  const process = useSelector((state) => {
    const res = state.academicYearsManagement.process;
    return res;
  });
  // count is incremented by 1 after each update made
  // so list could be re fetched from redux state
  const count = useSelector((state) => {
    const num = state.academicYearsManagement.updateCount;
    return num;
  });

  return { academicYears, errorMsg, status, process, count };
};

export default academicYearSelectors;
