import { createSelector } from "@reduxjs/toolkit";

export const selectFeePlanState = (state) => state.feePlan;

export const selectFeePlans = createSelector(
  [selectFeePlanState],
  (s) => s.plans
);

export const selectFeePlansMap = createSelector([selectFeePlanState], (s) =>
  (s?.plans || []).reduce((a, c) => {
    a[c._id] = c;
    return a;
  }, {})
);

export const selectBranchFeesList = createSelector(
  [selectFeePlanState],
  (s) => s.maps
);

export const selectMapOfBranchAndFees = createSelector(
  [selectBranchFeesList],
  (s) =>
    s?.reduce((a, c) => {
      a[c?.branch] = c.map || {};
      return a;
    }, {}) || {}
);

export const selectFeePlanData = createSelector(
  [selectFeePlanState],
  (s) => s.data
);

export const selectIdToEditFeePlan = createSelector(
  [selectFeePlanState],
  (s) => s.feePlanId
);

export const selectReservationFees = createSelector(
  [selectFeePlanState],
  (s) => s.reservationPlans
);

export const selectApplicationNoRanges = createSelector(
  [selectFeePlanState],
  (s) => s.applicationNoRanges
);

export const selectAddAssignSummary = createSelector(
  [selectFeePlanState],
  (s) => s.feeAssignSummary
);
