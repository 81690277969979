// react
import { useState, useEffect, useMemo } from "react";
// mui
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";

// import Popper from "@mui/material/Popper";
// import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

// react router
import { Link, useLocation } from "react-router-dom";
// my components
import SoftButton from "myComponents/SoftButton";
// components
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogPage from "components/Dialog";
import Close from "@mui/icons-material/Close";
import Filters from "../Filters/FilterForMenu";

const transformData = (data) => {
  const buttonsData1 = {};
  data?.forEach((item) => {
    const category = item.category || "";
    if (!buttonsData1[category]) {
      buttonsData1[category] = {
        label: category,
        menuItems: [],
        category,
      };
    }
    buttonsData1[category].menuItems.push({
      label: item.name,
      ...item,
    });
  });

  return Object.values(buttonsData1);
};

const MyAppBar = ({
  actions,
  simpleActions,
  Component,
  fields,
  getValues,
  handleGetClick,
  runGetClickOnFirstRender = true,
  runGetClick = false,
  haveGetButton = true,
  buttonLabel,
  initialValues = {},
  handleGetExcludedClick,
  checkedExcluded,
  excludeCheckBox = false,
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  const buttonsData = transformData(actions);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openMenuItemIndex, setOpenMenuItemIndex] = useState(null);
  const openFilter = Boolean(anchorElFilter);
  const isMobile = useMediaQuery("(max-width:1000px)");

  const handleClose = () => {
    setAnchorElFilter(null);
  };
  const [anchorEl, setAnchorEl] = useState({});
  const handleMenuClick = (event, button) => {
    setAnchorEl({ ...anchorEl, [button.category]: event.currentTarget });
  };
  const handleMenuClose = (category) => {
    setAnchorEl({ ...anchorEl, [category]: null });
  };

  const MyItem = useMemo(() => {
    if (openMenuItemIndex) {
      return buttonsData[openMenuItemIndex.i1].menuItems[openMenuItemIndex.i2];
    }
    return null;
  }, [openMenuItemIndex]);

  useEffect(() => {
    setAnchorElFilter(document.getElementById("menu-button"));
  }, []);

  const location = useLocation();
  const { pathname = "" } = location || {};
  const size = "small";

  const keepMountedOnPaths = ["/app/admissions/records"];

  const keepFiltersMounted = useMemo(
    () => keepMountedOnPaths.includes(pathname),
    [location]
  );

  const handleModalCose = () => {
    setOpenMenuItemIndex(null);
  };

  return (
    <>
      <SoftBox
        display="flex"
        direction="row"
        alignItems="center"
        sx={{
          position: "absolute",
          top: isMobile ? 70 : 40,
          right: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#E6EBF0",
          height: isMobile ? "auto" : Component ? "45px" : "30px",
        }}
      >
        <Grid container paddingLeft={1}>
          <Grid
            item
            xs={12}
            md={Component ? 8 : 11}
            sm={Component ? 8 : 11}
            container
            padding={0.5}
            paddingTop={Component ? 0.8 : 0.5}
            // paddingBottom={Component ? 0.8 : 0.5}
          >
            {buttonsData.map((button, index) => (
              <>
                <SoftBox
                  key={index}
                  alignItems="center"
                  size="small"
                  padding={Component ? 0.5 : 0.5}
                  color={anchorEl[button.category] ? "info" : "dark"}
                  sx={{
                    borderRadius: "8px",
                    fontSize: "15px",
                    fontWeight: 500,
                    width: "max-content",
                    "&:hover": {
                      color: "#3BBDED",
                      cursor: "pointer",
                    },
                  }}
                  onClick={(event) => handleMenuClick(event, button)}
                >
                  {button.label}
                </SoftBox>
                &nbsp; &nbsp;
                <Menu
                  elevation={0}
                  anchorEl={anchorEl[button.category]}
                  open={Boolean(anchorEl[button.category])}
                  onClose={() => handleMenuClose(button.category)}
                  slotProps={{
                    paper: {
                      sx: {
                        elevation: 0,

                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px;",

                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                >
                  {button.menuItems.map((menuItem, menuItemIndex) => (
                    <MenuItem key={menuItemIndex}>
                      {menuItem.button && (
                        <SoftBox
                          color="secondary"
                          variant="text"
                          sx={{ height: "20px" }}
                          size="small"
                          onClick={menuItem.onClick}
                        >
                          {menuItem.icon} {menuItem.name}
                        </SoftBox>
                      )}

                      {menuItem.link && (
                        <Link
                          to={menuItem.path}
                          state={{ path: location.pathname }}
                        >
                          <SoftBox
                            color="secondary"
                            variant="text"
                            size="small"
                            sx={{ height: "20px" }}
                            onClick={menuItem.handleClick}
                          >
                            {menuItem.icon} {menuItem.name}
                          </SoftBox>
                        </Link>
                      )}
                      {menuItem.modal ? (
                        <SoftButton
                          color="secondary"
                          variant="text"
                          size="small"
                          sx={{ height: "20px" }}
                          onClick={() =>
                            setOpenMenuItemIndex({
                              i1: index,
                              i2: menuItemIndex,
                            })
                          }
                        >
                          {menuItem.name}
                        </SoftButton>
                      ) : null}
                      {menuItem.popper ? (
                        <SoftButton
                          color="secondary"
                          variant="text"
                          size="small"
                          sx={{ height: "20px" }}
                          onClick={() =>
                            setOpenMenuItemIndex({
                              i1: index,
                              i2: menuItemIndex,
                            })
                          }
                        >
                          {menuItem.name}
                        </SoftButton>
                      ) : null}
                      {menuItem.dialog ? (
                        <SoftBox>
                          <DialogPage
                            content={menuItem.name}
                            Component={menuItem.component}
                            fullScreen={menuItem.fullScreen}
                            componentProps={menuItem.componentProps}
                            linkProps={{
                              color: "secondary",
                              variant: "text",
                              size,
                              sx: { height: "20px" },
                              startIcon: menuItem.icon,
                            }}
                            {...menuItem.dialogProps}
                          />
                        </SoftBox>
                      ) : null}
                      {menuItemIndex !== button.menuItems.length - 1 && (
                        <Divider sx={{ m: "0.5px" }} />
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ))}
            {simpleActions && simpleActions.length > 0 ? (
              <>
                {simpleActions.map((action, index) => (
                  <>
                    <SoftBox
                      key={`${action.lable}-${index}`}
                      alignItems="center"
                      size="small"
                      padding={0.5}
                      color="dark"
                      sx={{
                        borderRadius: "8px",
                        fontSize: "15px",
                        fontWeight: 500,
                        width: "max-content",
                        "&:hover": {
                          color: "#3BBDED",
                          cursor: "pointer",
                        },
                      }}
                      onClick={action.onClick}
                    >
                      {action.label}
                    </SoftBox>
                    &nbsp; &nbsp;
                  </>
                ))}
              </>
            ) : null}
          </Grid>

          {fields && fields?.length !== 0 && (
            <Grid
              item
              xs={12}
              md={Component && buttonsData ? 4 : 12}
              sm={Component && buttonsData ? 4 : 12}
              justifyContent={isMobile ? "flex-end" : "flex-end"}
              alignItems={isMobile ? "flex-end" : "flex-end"}
              alignContent={isMobile ? "flex-end" : "flex-end"}
              container
              paddingRight={2}
              paddingLeft={1}
            >
              <SoftBox display="flex" alignItems="center">
                {Component && Component}
                &nbsp; &nbsp; &nbsp;
                <Tooltip title="Filters">
                  <SoftBox
                    alignItems="center"
                    alignContent="center"
                    paddingBottom={0.5}
                  >
                    <SoftButton
                      variant="outlined"
                      size="medium"
                      iconOnly
                      id="menu-button"
                      color="dark"
                      aria-controls={
                        openFilter ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openFilter ? "true" : undefined}
                      onClick={(event) => {
                        setAnchorElFilter(event.currentTarget);
                      }}
                      sx={{
                        "&.MuiButtonBase-root": {
                          width: "29px",
                          height: "29px",
                          minWidth: "30px",
                          minHeight: "30px",
                        },
                      }}
                    >
                      <Icon>
                        <FilterAltIcon />
                      </Icon>
                    </SoftButton>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
            </Grid>
          )}
        </Grid>
      </SoftBox>
      {fields?.length !== 0 && (
        <Menu
          id="fade-menu"
          anchorEl={anchorElFilter}
          // open={anchorElFilter}
          open={Boolean(anchorElFilter)} // Set open to true by default
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          keepMounted={keepFiltersMounted}
        >
          <SoftBox minWidth={300} maxWidth={500}>
            <Grid container>
              <Grid item xs={4}>
                <SoftTypography variant="button"> Filters</SoftTypography>
              </Grid>
              <Grid item xs={6} container justifyContent="center">
                {excludeCheckBox && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedExcluded}
                          onChange={handleGetExcludedClick}
                        />
                      }
                      label="Excluded Batches"
                    />
                  </FormGroup>
                )}
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <Icon onClick={handleClose}>
                  <Close />
                </Icon>
              </Grid>
            </Grid>

            <Filters
              fields={fields}
              getValues={getValues}
              handleGetClick={handleGetClick}
              runGetClickOnFirstRender={runGetClickOnFirstRender}
              haveGetButton={haveGetButton}
              buttonLabel={buttonLabel}
              runGetClick={runGetClick}
              initialValues={initialValues}
              handleClose={handleClose}
            />
          </SoftBox>
        </Menu>
      )}
      {MyItem && (
        <Modal
          open={Boolean(openMenuItemIndex)}
          onClose={handleModalCose}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Slide direction="down" in={Boolean(openMenuItemIndex)} timeout={500}>
            <SoftBox
              position="relative"
              width={!matches ? "fit-content" : MyItem?.width}
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
            >
              <SoftBox
                p={2}
                sx={{
                  maxHeight: "calc(100vh - 100px)",
                  maxWidth: "calc(100vw - 50px)",
                  overflow: "auto",
                }}
              >
                <MyItem.Component
                  handleOpen={handleModalCose}
                  fade={Boolean(openMenuItemIndex)}
                  {...(MyItem?.componentProps || {})}
                />
              </SoftBox>
            </SoftBox>
          </Slide>
        </Modal>
      )}
    </>
  );
};

export default MyAppBar;
