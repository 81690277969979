// material ui
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
// my components
import SoftBox from "myComponents/SoftBox";
import SoftDataGrid from "myComponents/SoftDataGrid";
// utils
import { objectExists } from "utils/general.utils/object.utils";
// components
import SoftDataGridPro from "myComponents/SoftDataGridPro";
import Menu from "./MenuComponents";

const ModuleIndex = ({
  fields,
  getValues,
  handleGetClick,
  runGetClickOnFirstRender = true,
  runGetClick = false,
  haveGetButton = true,
  actions,
  dataSetX,
  dataSetY,
  buttonLabel,
  initialValues = {},
  dataSetInFilters = false,
  dataGridPro,
  Component,
  handleGetExcludedClick,
  checkedExcluded,
  excludeCheckBox,
  simpleActions,
}) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  return (
    <Grid container spacing={1}>
      {(fields || actions) && (
        <Grid item xs={12} paddingBottom={1}>
          <SoftBox>
            <Menu
              actions={actions}
              Component={Component}
              fields={fields}
              getValues={getValues}
              handleGetClick={handleGetClick}
              runGetClickOnFirstRender={runGetClickOnFirstRender}
              haveGetButton={haveGetButton}
              buttonLabel={buttonLabel}
              runGetClick={runGetClick}
              initialValues={initialValues}
              handleGetExcludedClick={handleGetExcludedClick}
              checkedExcluded={checkedExcluded}
              excludeCheckBox={excludeCheckBox}
              simpleActions={simpleActions}
            />
            &nbsp;&nbsp;
          </SoftBox>
        </Grid>
      )}

      {!dataSetInFilters &&
      objectExists(dataSetX) &&
      dataSetX.rows?.length > 0 ? (
        <Grid item xs={12} paddingTop={isMobile ? 4 : 1}>
          {dataGridPro ? (
            <SoftDataGridPro
              actions={{ edit: false, del: false }}
              editMode="row"
              rowHeight={38}
              getRowId={(row) => row._id}
              headerHeight={38}
              autoHeight
              toolBar
              AdditionalComponent={Typography}
              {...dataSetX}
            />
          ) : (
            <SoftDataGrid
              actions={{ edit: false, del: false }}
              editMode="row"
              rowHeight={38}
              getRowId={(row) => row._id}
              headerHeight={38}
              autoHeight
              toolBar
              AdditionalComponent={Typography}
              {...dataSetX}
            />
          )}
        </Grid>
      ) : null}

      {objectExists(dataSetY) ? (
        <Grid item xs={12} paddingTop={isMobile ? 4 : 0}>
          <SoftDataGrid
            actions={{ edit: false, del: false }}
            editMode="row"
            rowHeight={38}
            getRowId={(row) => row._id}
            headerHeight={48}
            autoHeight
            getEstimatedRowHeight={() => 150}
            getRowHeight={() => "auto"}
            {...dataSetY}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
export default ModuleIndex;
