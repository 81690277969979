import { NavLink, useLocation } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
// Soft UI Dashboard React components
import SoftBox from "myComponents/SoftBox";
// utils
import generateRandomString from "utils/getRandString";
// Custom styles for the SidenavCollapse
import { useSoftUIController, setMiniSidenav } from "context";
// styles
import { childText } from "./styles/sidenavCollapse";

function SidenavCollapse({ children, active, open }) {
  const isMobile = useMediaQuery("(max-width:992px)");
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const activeRoute = pathname.split("/").slice(1)[2];

  const closeSidenav = () => setMiniSidenav(dispatch, false);

  if (!children) return null;
  return (
    <Collapse in={open} unmountOnExit>
      <List>
        {children.map((obj, i) => {
          const { title, path, basePath, noUiLink } = obj;
          if (noUiLink) return null;
          return (
            <ListItem
              key={`${i}-${path}-${generateRandomString(3)}}`}
              disablePadding
              component={NavLink}
              to={`${basePath}/${path}`}
              onClick={(e) => {
                e.stopPropagation();
                if (isMobile) closeSidenav();
              }}
            >
              <SoftBox
                sx={(theme) =>
                  childText(theme, {
                    miniSidenav,
                    open: activeRoute === path,
                    active,
                  })
                }
              >
                <ListItemText primary={title} />
              </SoftBox>
            </ListItem>
          );
        })}
      </List>
    </Collapse>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
