import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  sequences: [],
};
const sequenceSlice = createSlice({
  name: "SEQUENCE",
  initialState,
  reducers: {
    fetchSequenceStart() {},
    fetchSequenceSuccess(state, action) {
      state.sequences = action.payload;
    },
    createSequenceStart() {},
    createSequenceSuccess(state, action) {
      state.sequences = [...state.sequences, action.payload];
    },
    updateSequenceStart() {},
    updateSequenceSuccess(state, action) {
      state.sequences = getUpdatedList(action.payload, state.sequences);
    },
  },
});

export const {
  createSequenceStart,
  createSequenceSuccess,
  updateSequenceStart,
  updateSequenceSuccess,
  fetchSequenceStart,
  fetchSequenceSuccess,
} = sequenceSlice.actions;

export default sequenceSlice.reducer;
