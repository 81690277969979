import indexImg from "assets/images/indexImg.png";
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import store from "store";
import { Chip, Typography } from "@mui/material";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import { useEffect, useState } from "react";
import SoftNotification from "myComponents/SoftNotification";
import useFetch from "hooks/useFetch";
import { setMPin } from "APIs/UserServices";
import KeyIcon from "./KeyIcon";
import MPINInput from "./MPINInput";

const SetMPIN = () => {
  const user = store.get("user");
  const [enteredMPIN, setEnteredMPIN] = useState("");
  const [confirmMPIN, setConfirmMPIN] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isUserLoading, userError, response, createMPIN, resetFetch] =
    useFetch(setMPin);
  const handleMPINChange = (mpin) => {
    setEnteredMPIN(mpin);
  };

  const handleMPINConfirm = (mpin) => {
    setConfirmMPIN(mpin);
  };

  const handleSetMPin = () => {
    if (enteredMPIN === confirmMPIN && confirmMPIN.length === 4) {
      createMPIN({
        employeeId: user.employeeCode,
        mPin: enteredMPIN,
      });
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (response && response.message) {
      const emp = store.get("user");
      store.clearAll();
      store.set("sessionExpired", false);
      store.set("employeeId", emp.userId);
      store.set("instituteId", emp.instituteId);
      const redirectUrl = window.location.origin;
      window.location.replace(redirectUrl);
    }
    if (isUserLoading) setOpen(true);
  }, [response, isUserLoading]);

  const msgs = {
    prevPath: "MPIN created successfully, Sign in with MPIN",
    error: userError || "something went wrong...",
    success: "MPIN created successfully, Sign in with MPIN",
    expired: "Token expired, Login again",
    loggingIn: "Creating MPIN Pleas wait...",
    mismatch: "MPIN does not  matches.",
  };

  const handleClose = () => {
    resetFetch();
    setOpen(false);
  };

  const getFeedback = () => {
    if (isUserLoading) return { msg: msgs.loggingIn, type: "info" };
    if (response) return { msg: msgs.success, type: "success" };
    if (confirmMPIN !== enteredMPIN)
      return { msg: msgs.mismatch, type: "error" };

    return { msg: msgs.error, type: "error" };
  };

  return (
    <CoverLayout
      ifLogoRequired
      title="Create your MPIN"
      description={
        <>
          A secure digital key&nbsp;
          <KeyIcon />
          &nbsp;code that adds extra layer of protection to your account
        </>
      }
      image={indexImg}
    >
      <Chip label="Security" size="small" color="primary" />
      &emsp;
      <Chip label="Easy access" size="small" color="warning" />
      &emsp;
      <Chip label="Peace of mind" size="small" color="info" />
      &emsp;
      <br />
      <br />
      <MPINInput
        title="Enter a 4-digit MPIN"
        onMPINChange={handleMPINChange}
        ifMatching={
          confirmMPIN.length === 4
            ? enteredMPIN === confirmMPIN
              ? "matched"
              : "notMatched"
            : false
        }
      />
      <MPINInput
        title="Confirm 4-digit MPIN"
        onMPINChange={handleMPINConfirm}
        ifMatching={
          confirmMPIN.length === 4
            ? enteredMPIN === confirmMPIN
              ? "matched"
              : "notMatched"
            : false
        }
      />
      <br />
      <Typography variant="caption">
        <b>Tips:</b>
        <br /> Change your MPIN regularly for added security. Avoid using easily
        guessable combinations like &quot;1234&quot; or &quot;0000&quot;.
      </Typography>
      <SoftBox mt={4} mb={4}>
        <SoftButton
          type="submit"
          variant="contained"
          color="info"
          onClick={handleSetMPin}
        >
          Continue
        </SoftButton>
      </SoftBox>
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </CoverLayout>
  );
};

export default SetMPIN;
