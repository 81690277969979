import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  timings: [],
};
const timingsSlice = createSlice({
  name: "ATTENDANCE_TIMINGS",
  initialState,
  reducers: {
    fetchAttendanceTimingsStart() {},
    fetchAttendanceTimingsSuccess(state, action) {
      state.timings = action.payload;
    },
    saveAttendanceTimingsStart() {},
    saveAttendanceTimingsSuccess(state, action) {
      state.timings = [...state.timings, action.payload];
    },
    updateAttendanceTimingsStart() {},
    updateAttendanceTimingsSuccesss(state, action) {
      state.timings = getUpdatedList(action.payload, state.timings);
    },
  },
});

export const {
  fetchAttendanceTimingsStart,
  fetchAttendanceTimingsSuccess,
  saveAttendanceTimingsStart,
  saveAttendanceTimingsSuccess,
  updateAttendanceTimingsStart,
  updateAttendanceTimingsSuccesss,
} = timingsSlice.actions;

export default timingsSlice.reducer;
