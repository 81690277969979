import { takeLatest, put, all, call } from "redux-saga/effects";
// apis
import {
  createFollowup,
  updateFollowup,
  fetchFollowups,
} from "APIs/admissions/followups";
// actions
import {
  createFollowupSuccess,
  updateFollowupSuccess,
  fetchFollowupsSuccess,
  fetchFollowsStartByCounsellor,
  fetchFollowupsByCounsellorSuccess,
} from "./followups.slice";
// utils
import { fetch, update, create } from "../../app/app.utils";

export function* createFollowupAsync({ payload }) {
  yield create({
    callName: "create_followup",
    inputs: payload,
    api: createFollowup,
    *handleRes(item) {
      yield put(createFollowupSuccess(item));
    },
    resName: "saved",
  });
}

export function* fetchFollowupsAsync({ payload }) {
  yield fetch({
    callName: "fetch_followups",
    query: payload,
    api: fetchFollowups,
    *handleRes(items) {
      yield put(fetchFollowupsSuccess(items));
    },
  });
}

export function* fetchFollowupByCounsellorsAsync({ payload }) {
  yield fetch({
    callName: "fetch_followups",
    query: payload,
    api: fetchFollowups,
    *handleRes(items) {
      yield put(fetchFollowupsByCounsellorSuccess(items));
    },
  });
}
export function* updateFollowupAsync({ payload }) {
  yield update({
    callName: "update_followup",
    updates: payload,
    api: updateFollowup,
    *handleRes(item) {
      yield put(updateFollowupSuccess(item));
    },
    updatedName: "updated",
  });
}

export function* onUpdateFollowupStart() {
  yield takeLatest("FOLLOWUP/updateFollowupStart", updateFollowupAsync);
}

export function* onUpdateFollowupByCounsellorStart() {
  yield takeLatest(
    "FOLLOWUP/fetchFollowsStartByCounsellor",
    fetchFollowupByCounsellorsAsync
  );
}
export function* onFetchFollowupsStart() {
  yield takeLatest("FOLLOWUP/fetchFollowsStart", fetchFollowupsAsync);
}

export function* onCreateFollowupStart() {
  yield takeLatest("FOLLOWUP/createFollowupStart", createFollowupAsync);
}

export default function* followupsSaga() {
  yield all([
    call(onUpdateFollowupStart),
    call(onFetchFollowupsStart),
    call(onCreateFollowupStart),
    call(onUpdateFollowupByCounsellorStart),
  ]);
}
