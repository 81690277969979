import { useSelector } from "react-redux";

import { createSelector } from "@reduxjs/toolkit";

const selectUsersState = (state) => state.userManagement;

export const selectCounsellors = createSelector(
  [selectUsersState],
  (usersState) => usersState.counsellors
);

export const selectEmployees = createSelector(
  [selectUsersState],
  (usersState) => usersState.users
);

const userManagementSelectors = () => {
  const result = useSelector((state) => {
    const res = state.userManagement.status;
    return res;
  });

  const errorMsg = useSelector((state) => {
    const msg = state.userManagement.error;
    return msg;
  });
  const user = useSelector((state) => {
    const userObject = state.userManagement.user;
    return userObject;
  });
  const process = useSelector((state) => {
    const res = state.userManagement.process;
    return res;
  });

  const employees = useSelector((state) => {
    const list = state.userManagement.users;
    return list;
  });
  return { result, errorMsg, process, employees, user };
};

export default userManagementSelectors;
