import baseAxios from "../baseAxios";

const COMMON = "/finance/branch_fee_map";

export async function updateBranchFeeMap(body) {
  try {
    return (await baseAxios.patch(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchFeeMaps(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/get`, { params: { ...obj } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
