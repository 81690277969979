export default {
  type: "light",
  primary: {
    main: "#39D0B8",
    light: "#F0FBFF",
  },
  secondary: {
    main: "#FAFAFA",
  },
  success: {
    light: "#E4FFFB",
    main: "#03AD92",
  },
  danger: {
    main: "#ea0606",
    primary: "#D32F2F",
    secondary: "#FF6640",
  },
  warning: {
    main: "#fbcf33",
  },
  orange: {
    main: "#FFAB00",
    focus: "#FFAB00",
  },
  info: {
    light: "#CCE6FF",
    main: "#3BBDED",
    primary: "#EEFAFF",
    secondary: "#00BAFF",
    lightest: "#3BBDED0F",
    dark: "#O62D5E",
    thick: "#31456A",
  },
  text: {
    light: "#6D87A7",
    dark: "#202040",
    contrastText: "#010722 ",
    bold: "#001624",
    secondary: "#818384",
    lighter: "#616161",
  },
  background: {
    alphaLight: "#F4F5F7",
    alpha: "#F7FAFC",
    beta: "#FCFCFC",
    gama: "#F1F1F1",
    sigma: "#DFFAF6 ",
    theta: "#F7F7F7",
    delta: "#E4E4E4",
    power: "#09BDFF",
    square: "#D3F3FF",
    lightText: "#F2F6FA",
    one: "#E3FFFB",
    two: "#E3F8FF",
  },
  neutral: {
    main: "#F0FBFF",
    contrastText: "#fff",
    secondary: "#F7FDFA",
    grey: "#F3F3F3",
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },
  border: {
    one: "#CAE0E8",
  },
  divider: "#DFE4E7",
  dividerDark: "#DFE1E6",
  inActive: "#D2CECE",
  white: "#FFFFFF",
  thick: "#FF0000",
  light: "#e9ecef",
  dark: "#344767",
  gradients: {
    primary: "#7928ca #ff0080",
    secondary: "#627594  #a8b8d8",
    info: "#2152ff  #21d4fd",
    success: "#17ad37  #98ec2d",
    warning: "#f53939  #fbcf33",
    error: "#ea0606  #ff667c",
    light: "#ced4da  #ebeff4",
    dark: "#141727  #3a416f",
  },
};
