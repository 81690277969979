// import { lazy } from "react";
// icons
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
// body layout
import Container from "../../layout/simpleContainer";
// roles
// import roles from "../roles";

export default {
  component: <Container />,
  title: "Transport Management",
  basePath: "app",
  path: "Transport-management",
  icon: <EmojiTransportationRoundedIcon />,
  authorizedRoles: [],
};
