import { MaterialDesignContent } from "notistack";
import { styled } from "@mui/material/styles";
import colors from "../../assets/theme/base/colors";

export default styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: colors.success.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: colors.error.main,
  },
}));
