import { createSlice } from "@reduxjs/toolkit";
import desList from "../../config/roles";

const initialState = {
  batches: ["spark", "IZ", "NIX"],
  branches: [
    { _id: 0, branchName: "alpha", branchCode: "SB01" },
    { _id: 1, branchName: "beta", branchCode: "SB02" },
  ],
  zones: [],
  status: [
    "Admitted",
    "Not Admitted",
    "All",
    "Application Taken but Not Joined",
  ],
  source: ["Campus walk-in", "Website", "Others", "CAMPAIGN"],
  stage: ["All", "Open", "Intrested", "walk-in"],
  counsellor: [],
  accessLevels: ["organization", "zonal", "branch"],
  desList,
  feeTypeList: ["aaaa", "bbb", "cccc"],
  expensesTypeList: ["Cash", "Bank account", "Both"],
  hostelTypeList: ["day", "residential"],
  dayOptions: [
    "monday",
    "Tuesday",
    "wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  floorsList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  attendanceTypeOptions: [],
  TimingNameOptions: [],
};

const filtersSlice = createSlice({
  name: "Filters",
  initialState,
  reducers: {},
});

// export const {} = filtersSlice.actions;

export default filtersSlice.reducer;
