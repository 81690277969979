import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  updateConfigSuccess,
  fetchConfigSuccess,
  createConfigSuccess,
  fetchAySuccess,
} from "./settings.slice";
import { setInitializationStatus } from "../app/app.slice";
// apos
import {
  updatedInstituteConfig,
  fetchInstituteConfig,
  createInsConfig,
  fetchInstituteAyConfig,
} from "../../APIs/settings/configrationServices";
// utils
import { fetch, update, create } from "../app/app.utils";

export function* createInstituteConfigAsync({ payload }) {
  yield create({
    callName: "create_institute_config",
    inputs: payload,
    api: createInsConfig,
    *handleRes(item) {
      yield put(createConfigSuccess(item));
    },
    resName: "savedConfig",
  });
}

export function* fetchInstituteConfigAsync({ payload }) {
  const { initial, ...rest } = payload;
  yield fetch({
    callName: "fetch_institute_config",
    query: { ...rest },
    api: fetchInstituteConfig,
    *handleRes(items) {
      yield put(fetchConfigSuccess(items[0]));
      if (initial)
        yield put(setInitializationStatus({ fetchedInstituteConfig: true }));
    },
    initial,
  });
}

export function* fetchAyAsync({ payload }) {
  try {
    const response = yield call(fetchInstituteAyConfig, payload); // Assuming fetchInstituteAyConfig is a function returning a promise

    yield put(fetchAySuccess(response));
  } catch (error) {
    console.error("Error fetching Ay:", error);
  }
}

export function* updateInstituteConfigAsync({ payload }) {
  yield update({
    callName: "update_institue_config",
    updates: payload,
    api: updatedInstituteConfig,
    *handleRes(item) {
      yield put(updateConfigSuccess(item));
    },
    updatedName: "updatedConfig",
  });
}

export function* onUpdateInstitueConfig() {
  yield takeLatest("SETTINGS/updateConfigStart", updateInstituteConfigAsync);
}

export function* onFetchInstitueConfig() {
  yield takeLatest("SETTINGS/fetchConfigStart", fetchInstituteConfigAsync);
}
export function* onFetchAyAsync() {
  yield takeLatest("SETTINGS/fetchAyStart", fetchAyAsync);
}
export function* onCreateConfigStart() {
  yield takeLatest("SETTINGS/createConfigStart", createInstituteConfigAsync);
}

export default function* settingsSaga() {
  yield all([
    call(onUpdateInstitueConfig),
    call(onFetchInstitueConfig),
    call(onCreateConfigStart),
    call(onFetchAyAsync),
  ]);
}
