import moment from "moment";

export default [
  {
    field: "index",
    headerName: "S No",
    editable: false,
    width: 80,
    valueGetter: (params) => {
      const ids = params.api.getAllRowIds();
      return ids.indexOf(params.id) + 1;
    },
  },

  { field: "academic_year", headerName: "Academic Year", width: 150 },
  { field: "approved_by", headerName: "Approved By", width: 150 },
  {
    field: "approved_date",
    headerName: "Approved Date",
    width: 150,
    valueGetter: (params) =>
      moment(params.row.approvedDate).format("MM-DD-YYYY"),
  },
  { field: "class_text", headerName: "Class", width: 120 },
  {
    field: "current_admission_no",
    headerName: "Current Admission No",
    width: 180,
  },
  { field: "from_admission_no", headerName: "From Admission No", width: 180 },

  { field: "from_branch_name", headerName: "From Branch Name", width: 250 },
  { field: "remark1", headerName: "Remark 1", width: 200 },
  { field: "remark2", headerName: "Remark 2", width: 200 },
  { field: "remark3", headerName: "Remark 3", width: 250 },
  { field: "request_by", headerName: "Requested By", width: 150 },
  {
    field: "request_date",
    headerName: "Request Date",
    width: 150,
    valueGetter: (params) =>
      moment(params.row.request_date).format("MM-DD-YYYY"),
  },
  { field: "section_text", headerName: "Section", width: 120 },
  { field: "to_admission_no", headerName: "To Admission No", width: 180 },
  { field: "to_branch_name", headerName: "To Branch Name", width: 250 },
];
