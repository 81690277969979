import baseAxios from "APIs/baseAxios";

const COMMON = "/organization/notifications";

export async function createNotification(body) {
  try {
    return (await baseAxios.post(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchNotificationList(obj = {}) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateReadBy(id = "") {
  try {
    return (await baseAxios.patch(`${COMMON}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUnreadNotificationCount() {
  try {
    const res = (await baseAxios.get(`${COMMON}/unread-count`)).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
