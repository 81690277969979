import { createSelector } from "@reduxjs/toolkit";

const selectAttendance = (state) => state.attendance;

export const selectAttendanceParams = createSelector(
  [selectAttendance],
  (app) => app.params
);

export const selectSaveAttendance = createSelector(
  [selectAttendance],
  (app) => app.attendance
);

export const selectAbsentees = createSelector(
  [selectSaveAttendance],
  (absentees) =>
    absentees.filter(
      (stu) => stu?.studentId && !stu.studentId?.includes("dummy")
    )
);

export const selectStudentForAttendance = createSelector(
  [selectAttendance],
  (app) => app.students
);

export const selectStudentMapForAttendance = createSelector(
  [selectStudentForAttendance],
  (arr) =>
    arr.reduce((a, c) => {
      a[c._id] = c;
      return a;
    }, {})
);

export const selectAttendanceToSave = createSelector(
  [selectAttendance],
  (app) => app.stuffToSave
);

export const selectAttendanceReport = createSelector(
  [selectAttendance],
  (a) => a.report
);

export const selectAttendanceSummary = createSelector(
  [selectAttendance],
  (a) => a.summary
);

export const selectDailyAttendance = createSelector(
  [selectAttendance],
  (a) => a.daily_att
);

export const selectSMSTasks = createSelector(
  [selectAttendance],
  (a) => a.sms_tasks
);

export const selectPunchesReport = createSelector(
  [selectAttendance],
  (a) => a.punches_report
);
export const selectSinglePunchesReport = createSelector(
  [selectAttendance],
  (a) => a.single_report
);
export const selectSingleStudentManAttReport = createSelector(
  [selectAttendance],
  (a) => a.single_man_report
);
export const selectCumulativeAttReport = createSelector(
  [selectAttendance],
  (a) => a.cumulative_man_att_report
);
