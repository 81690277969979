import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import { setAllExams } from "redux/exams/exams.slice";
import { setInitializationStatus } from "redux/app/app.slice";
// apis
import { fetchAllExams } from "APIs/adminPortal/basic";
// utils
import { addExamsListToStore, retrieveExamsList } from "utils/IndexedDB/utils";

export function* retriveExamsFromIndexDbAsync() {
  const exams = yield retrieveExamsList() || [];
  if (exams) {
    const sorted = [...exams].sort(
      (a, b) => (b?.TimeCreated || 0) - (a?.TimeCreated || 0)
    );
    yield put(setAllExams(sorted));
  }
}

export function* getAllExams() {
  const res = yield fetchAllExams();
  if (res) {
    let setExamsInStore = [];
    if (res.Items) {
      setExamsInStore = res.Items.filter((data) => data.levelIds);
    }
    yield addExamsListToStore(setExamsInStore);
    yield retriveExamsFromIndexDbAsync();
    yield put(setInitializationStatus({ fetchedAllExams: true }));
  }
}

export function* onFetchAllExams() {
  yield takeLatest("FETCH_ALL_EXAMS", getAllExams);
}

export function* onRetriveExamsFromIndexDb() {
  yield takeLatest("RETRIVE_EXAMS_FROM_INDEXDB", retriveExamsFromIndexDbAsync);
}

export default function* examsSaga() {
  yield all([call(onFetchAllExams), call(onRetriveExamsFromIndexDb)]);
}
