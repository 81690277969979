import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
// actions

import {
  fetchSummarySuccess,
  fetchSummaryFailure,
  fetchBasicAttendanceSummarySuccess,
  fetchBasicFinanceSummarySuccess,
  fetchBasicFnyFinanceSummarySuccess,
  fetchBasicAdmissionsSummarySuccess,
  fetchBasicSummaryFailure,
  fetchDescSummarySuccess,
  fetchDescSummaryFailure,
  fetchStudentAyInfoSummarySuccess,
  fetchBasicCurrentAdmissionsSummarySuccess,
  fetchBasicCreatedSummarySuccess,
} from "./dashboard.slice";
// redux store
import storeRedux from "../../store";
// apis
import fetchSummaryList, {
  fetchBasicSummaryList,
  fetchDescSummaryList,
} from "../../../APIs/dashboard/summaryServices";
// import { fetchExpBrnachSummary } from "../../../APIs/expense/pettyCash";

const getSearchParams = () => {
  const { dashboard } = storeRedux.getState();
  const params = dashboard.searchParams;
  return params;
};

export function* fetchSummaryAsync() {
  const params = getSearchParams();
  try {
    const res = yield fetchSummaryList(params);
    if (res) yield put(fetchSummarySuccess(res.result.docs));
  } catch (error) {
    yield put(fetchSummaryFailure(error.message));
  }
}
export function* fetchBasicSummaryAsync({ payload }) {
  try {
    const res = yield fetchBasicSummaryList(payload);

    if (res) {
      if (payload.type === "ADMISSIONS") {
        yield put(fetchBasicAdmissionsSummarySuccess(res.result));
      }
      if (payload.type === "ATTENDACE") {
        yield put(fetchBasicAttendanceSummarySuccess(res.result));
      }
      if (payload.type === "FINANCE") {
        yield put(fetchBasicFinanceSummarySuccess(res.dashboardSummary));
      }
      if (payload.type === "CURRENT_ADMISSIONS") {
        yield put(fetchBasicCurrentAdmissionsSummarySuccess(res.result));
      }
      if (payload.type === "student_ay_info") {
        yield put(fetchStudentAyInfoSummarySuccess(res.result));
      }
      if (payload.type === "Fn_Years_FINANCE") {
        yield put(fetchBasicFnyFinanceSummarySuccess(res.fnSummary));
      }
      if (payload.type === "created_admissions") {
        yield put(fetchBasicCreatedSummarySuccess(res.result));
      }
    }
  } catch (error) {
    yield put(fetchBasicSummaryFailure(error.message));
  }
}
export function* fetchDescSummaryAsync() {
  const params = getSearchParams();
  try {
    const res = yield fetchDescSummaryList(params);
    if (res) yield put(fetchDescSummarySuccess(res.result));
  } catch (error) {
    yield put(fetchDescSummaryFailure(error.message));
  }
}
export function* onFetchSummaryStart() {
  yield takeLatest("dashboard/fetchSummaryStart", fetchSummaryAsync);
}
export function* onFetchBasicSummaryStart() {
  yield takeEvery("dashboard/fetchBasicSummaryStart", fetchBasicSummaryAsync);
}
export function* onFetchDescSummaryStart() {
  yield takeLatest("dashboard/fetchDescSummaryStart", fetchDescSummaryAsync);
}
export default function* dashboardSaga() {
  yield all([
    call(onFetchSummaryStart),
    call(onFetchBasicSummaryStart),
    call(onFetchDescSummaryStart),
  ]);
}
