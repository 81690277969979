import { useEffect, useState } from "react";
// redux
import { useSelector } from "react-redux";
// material ui
import Grid from "@mui/material/Grid";
// myComponents
import SoftButton from "myComponents/SoftButton";
// selectors
import { selectAcademicYear } from "redux/app/app.selectors";
// subscription
import { subscribe } from "helpers/pubsub/utils";
import TriggerGetClick from "helpers/pubsub/events/triggerGetClick";
// utils
import fieldsMap, { dependentFieldNames } from "utils/Fields/fieldsMap";
import { FIELD_NAMES, SELECT_TYPES } from "config/globalConsts";
// components
import MyFilter from "./MyFilter";
import FiltersContainer from "./Components";

const Filters = ({
  fields,
  getValues,
  handleGetClick,
  runGetClickOnFirstRender,
  buttonLabel,
  runGetClick,
  renderProps,
  btn = true,
  includeChildren = false,
  children,
  initialValues,
  small = false,
  noClearFilterBtn,
  handleClose,
  disabled = false,
}) => {
  const [values, setValues] = useState({});
  // const currentAcademicYear = useSelector(selectCurrentAcademicYear);
  const currentAcademicYear = useSelector(selectAcademicYear);
  const names = fields?.map((item) => {
    if (
      item.field === "DATE" ||
      item.field === "TEXT_FIELD" ||
      item.field === "DROPDOWN_FIELD" ||
      item.field === "CHECKBOX"
    )
      return item.name;

    return item.field;
  });
  const getClick = () => {
    handleGetClick(values);
    handleClose();
  };
  useEffect(() => {
    const obj = names?.reduce((ac, a) => {
      if (a === FIELD_NAMES.DATE) return { ...ac, [a]: new Date() };

      return { ...ac, [a]: null };
    }, {});
    setValues((prev) => ({ ...prev, ...obj, ...initialValues }));
  }, []);

  const resetChildren = (c) => {
    const childrenValues = {};
    c.forEach((child) => {
      if (Object.prototype.hasOwnProperty.call(values, child))
        childrenValues[child] = null;
    });
    setValues((prev) => ({ ...prev, ...childrenValues }));
  };

  useEffect(() => {
    if (getValues) getValues(values);
  }, [values]);

  useEffect(() => {
    const haveAcademicYear = names?.includes(FIELD_NAMES.ACADEMIC_YEAR);
    const haveExpenseType = names?.includes("expensesType");
    if (haveExpenseType) {
      setValues((preValues) => ({ ...preValues, expensesType: "Cash" }));
    }
    if (haveAcademicYear)
      setValues((preValues) => ({
        ...preValues,
        [FIELD_NAMES.ACADEMIC_YEAR]: currentAcademicYear,
      }));
    if (runGetClickOnFirstRender && handleGetClick)
      handleGetClick({ academicYear: currentAcademicYear });
  }, [currentAcademicYear]);

  useEffect(() => {
    if (runGetClick) handleGetClick(values);
  }, [runGetClick]);

  useEffect(() => {
    const handle = subscribe(TriggerGetClick, () => {
      if (handleGetClick) {
        handleGetClick(values);
      }
    });
    return function cleanup() {
      handle.unsubscribe();
    };
  });

  useEffect(() => {
    const haveExpenseType = names?.includes("expensesType");

    if (haveExpenseType) getValues(values);
  }, [values]);

  return (
    <>
      <Grid container padding={1} paddingLeft={2}>
        <FiltersContainer>
          {fields?.map(
            (
              {
                options,
                field,
                selectType,
                virtualList,
                getLabelInVirtualList,
                ...rest
              },
              index
            ) => {
              const details = fieldsMap[field]({ selectType, ...rest });
              const haveFilters = details.filters?.length > 0;
              const filters = haveFilters
                ? details.filters?.reduce((a, c) => {
                    a[c] = values[c];
                    return a;
                  }, {})
                : {};

              if (selectType === SELECT_TYPES.MULTI) {
                const displayList =
                  options ||
                  (details && details.getList && details.getList(filters)) ||
                  [];

                if (displayList && displayList.length === 1) {
                  values[field] = displayList;
                }
              }
              if (selectType === SELECT_TYPES.SINGLE) {
                const displayList =
                  options ||
                  (details && details.getList && details.getList(filters)) ||
                  [];
                const list = displayList[0];

                if (displayList && displayList.length === 1) {
                  values[field] = list;
                }
              }

              return (
                <Grid item xs={12} sm={5}>
                  <MyFilter
                    small={small}
                    details={details}
                    name={names[index]}
                    options={options || null}
                    value={values?.[names[index]] || null}
                    values={values}
                    virtualList={virtualList}
                    getLabelInVirtualList={getLabelInVirtualList}
                    setValue={(item) => {
                      setValues((prev) => ({
                        ...prev,
                        [names[index]]: item,
                      }));
                      if (dependentFieldNames[names[index]]?.length > 0)
                        resetChildren(dependentFieldNames[names[index]]);
                    }}
                    filters={filters}
                  />
                </Grid>
              );
            }
          )}

          <Grid item xs={12} sm={12}>
            {btn ? (
              <SoftButton
                onClick={getClick}
                color="info"
                size="small"
                disabled={disabled}
              >
                {buttonLabel || "Get Details"}
              </SoftButton>
            ) : null}
            &nbsp;
            {noClearFilterBtn ? null : (
              <SoftButton
                onClick={() => setValues({})}
                color="dark"
                size="small"
              >
                Clear Filters
              </SoftButton>
            )}
          </Grid>
        </FiltersContainer>
      </Grid>

      {includeChildren ? children(values) : null}

      {renderProps && !includeChildren ? children(values) : null}
    </>
  );
};

export default Filters;
