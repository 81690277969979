import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  process: "",
  error: "",
  status: "",
  summary: [],
  admissionsBasicSummary: [],
  financeBasicSummary: [],
  financeFnySummary: [],
  attendanceBasicSummary: [],
  financeFetchedDate: null,
  admissionsFetchedDate: null,
  attendanceFetchedDate: null,
  admissionsDescDate: null,
  attendanceDescDate: null,
  financeDescDate: null,
  admissionsDesc: [],
  financeDesc: [],
  attendanceDesc: [],
  financeLoading: false,
  financeFnyLoading: false,
  financeDescLoading: false,
  admissionsLoading: false,
  attendanceLoading: false,
  currentAdmissionsLoading: false,
  admissionsInfoLoading: false,
  admissionsCurrentFetchedDate: null,
  admissionsCurrentBasicSummary: [],
  admissionscreatedBySummary: [],
  admissionStudentAyInfo: [],
  searchParams: {},
  loading: false,
  createdByAdmissionsDate: null,
  createdByAdmissionsLoading: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchSummaryStart(state, action) {
      state.searchParams = action.payload;
      state.process = "fetch";
      state.status = "info";
      state.loading = true;
    },

    fetchSummarySuccess(state, action) {
      state.status = "success";

      state.summary = action.payload;

      state.loading = false;
    },
    fetchBasicSummaryStart(state, action) {
      state.process = "fetch";
      state.status = "info";
      if (action?.payload?.type === "ADMISSIONS") {
        state.admissionsLoading = true;
      }
      if (action?.payload?.type === "FINANCE") {
        state.financeLoading = true;
      }
      if (action?.payload?.type === "ATTENDACE") {
        state.attendanceLoading = true;
      }
      if (action?.payload?.type === "CURRENT_ADMISSIONS") {
        state.currentAdmissionsLoading = true;
      }
      if (action?.payload?.type === "student_ay_info") {
        state.admissionsInfoLoading = true;
      }
      if (action?.payload?.type === "Fn_Years_FINANCE") {
        state.financeFnyLoading = true;
      }
    },
    fetchDescSummaryStart(state, action) {
      state.searchParams = action.payload;
      state.process = "fetch";
      state.status = "info";
      state.financeDescLoading = true;
    },
    fetchBasicAttendanceSummarySuccess(state, action) {
      state.status = "success";
      state.attendanceBasicSummary = action.payload;
      state.attendanceFetchedDate = new Date();
      state.attendanceLoading = false;
    },
    fetchBasicAdmissionsSummarySuccess(state, action) {
      state.status = "success";
      state.admissionsBasicSummary = action.payload;
      state.admissionsFetchedDate = new Date();
      state.admissionsLoading = false;
    },
    fetchBasicCurrentAdmissionsSummarySuccess(state, action) {
      state.status = "success";
      state.admissionsCurrentBasicSummary = action.payload;
      state.admissionsCurrentFetchedDate = new Date();
      state.currentAdmissionsLoading = false;
    },
    fetchStudentAyInfoSummarySuccess(state, action) {
      state.status = "success";
      state.admissionStudentAyInfo = action.payload;
      state.admissionsInfoLoading = false;
    },
    fetchBasicFinanceSummarySuccess(state, action) {
      state.status = "success";
      state.financeBasicSummary = action.payload;
      state.financeFetchedDate = new Date();
      state.financeLoading = false;
    },
    fetchBasicFnyFinanceSummarySuccess(state, action) {
      state.status = "success";
      state.financeFnySummary = action.payload;
      state.financeFetchedDate = new Date();
      state.financeFnyLoading = false;
    },
    fetchBasicCreatedSummarySuccess(state, action) {
      state.status = "success";
      state.admissionscreatedBySummary = action.payload;
      state.createdByAdmissionsDate = new Date();
      state.createdByAdmissionsLoading = false;
    },

    fetchDescSummarySuccess(state, action) {
      state.status = "success";
      // state.financeFetchedDate = new Date();
      if (state.searchParams.type === "ADMISSIONS") {
        state.admissionsDesc = action.payload;
        state.admissionsDescDate = new Date();
      }
      if (state.searchParams.type === "ATTENDACE") {
        state.attendanceDesc = action.payload;
        state.attendanceDescDate = new Date();
      }
      if (state.searchParams.type === "FINANCE") {
        state.financeDesc = action.payload;
        state.financeDescDate = new Date();
      }

      state.loading = false;
    },

    fetchSummaryFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    fetchBasicSummaryFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    fetchDescSummaryFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },

    clearDashboardSummary(state) {
      state.admissionsBasicSummary = [];
      state.financeBasicSummary = [];
      state.attendanceBasicSummary = [];
      state.financeFetchedDate = null;
      state.admissionsFetchedDate = null;
      state.attendanceFetchedDate = null;
      state.financeFnySummary = [];
    },
  },
});

export const {
  fetchSummarySuccess,
  fetchBasicSummarySuccess,
  fetchBasicSummaryStart,
  fetchAdmissionsSummaryFailure,
  fetchSummaryFailure,
  fetchSummaryStart,
  clearDashboardSummary,
  fetchBasicSummaryFailure,
  fetchDescSummarySuccess,
  fetchDescSummaryFailure,
  fetchBasicAttendanceSummarySuccess,
  fetchBasicFinanceSummarySuccess,
  fetchBasicAdmissionsSummarySuccess,
  fetchDescSummaryStart,
  fetchStudentAyInfoSummarySuccess,
  fetchBasicCurrentAdmissionsSummarySuccess,
  fetchBasicFnyFinanceSummarySuccess,
  fetchBasicCreatedSummarySuccess,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
