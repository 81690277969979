import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  timetables: [],
};
const timeTableSlice = createSlice({
  name: "TIMETABLE",
  initialState,
  reducers: {
    fetchTimeTablesStart() {},
    fetchTimeTableSuccess(state, action) {
      state.timetables = action.payload;
    },
    createTimeTableStart() {},
    createTimeTableSuccess(state, action) {
      state.timetables = [...state.timetables, action.payload];
    },
    updateTimeTableStart() {},
    updateTimeTableSuccess(state, action) {
      state.timetables = getUpdatedList(action.payload, state.timetables);
    },
  },
});

export const {
  fetchTimeTablesStart,
  fetchTimeTableSuccess,
  createTimeTableStart,
  createTimeTableSuccess,
  updateTimeTableStart,
  updateTimeTableSuccess,
} = timeTableSlice.actions;

export default timeTableSlice.reducer;
