import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  localOperations: [],
};

const historySlice = createSlice({
  name: "NOTIFICATIONS",
  initialState,
  reducers: {
    pushOperation(state, action) {
      state.localOperations?.push(action.payload);
    },
    removeOperation(state, action) {
      state.localOperations = state.localOperations?.filter(
        (operation) => operation.id !== action.payload
      );
    },
    clearOperations(state) {
      state.localOperations = [];
    },
  },
});

export const { pushOperation, removeOperation, clearOperations } =
  historySlice.actions;

export default historySlice.reducer;
