import * as yup from "yup";

const signinSchema = yup.object({
  // email: yup
  //   .string("Enter your email")
  //   .email("Enter a valid email")
  //   .required("Email is required"),
  employeeId: yup
    .string("Enter your Employee Id")
    .required("employee Id is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
export default signinSchema;
