import { Grid } from "@mui/material";
import moment from "moment";
import SoftTypography from "myComponents/SoftTypography";

export default function CustomLI({ message = "", createdAt }) {
  const notificationTime = moment().isSame(createdAt, "day")
    ? moment(createdAt).startOf("milliseconds").fromNow()
    : moment(createdAt).format("ll");
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <SoftTypography
        variant="button"
        sx={{ textWrap: "wrap", overflow: "hidden", textOverflow: "ellipsis" }}
      >
        {message}
      </SoftTypography>
      {createdAt && (
        <SoftTypography variant="caption">{notificationTime}</SoftTypography>
      )}
    </Grid>
  );
}
