import { Outlet } from "react-router-dom";
// styled components
import { Wrapper, ContentContainer } from "./styles";

const BodyWrapper = () => (
  <Wrapper>
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </Wrapper>
);
export default BodyWrapper;
