import store from "store";
import lambda from "APIs/lambdaConfig";
import baseAxios from "../baseAxios";

const COMMON = "/admissions/student";

export async function createStudent(obj) {
  const { phase, ...rest } = obj;
  try {
    return (await baseAxios.post(`${COMMON}/${phase}`, { ...rest })).data;
  } catch (error) {
    console.log(error);

    // if (error.message.includes("E11000 duplicate key error collection")) {
    //   const a = {
    //     message: "Application Number missing",
    //     result: { message: "PLEASE Check Application Number" },
    //   };
    //   return Promise.reject(a);
    // }
    if (error.message === "SEQUENCE_NOT_FOUND") {
      const a = {
        message: "PLEASE ADD SEQUENCE  FOR SELECTED BRANCH",
        result: { message: "SEQUENCE_NOT_FOUND" },
      };
      return Promise.reject(a);
    }
    return Promise.reject(error);
  }
}

export async function fetchStudent(obj) {
  try {
    return (await baseAxios.get(COMMON, { params: { ...obj } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchSingleStudent(body) {
  try {
    return (await baseAxios.post(`${COMMON}/get-student`, { ...body })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchEnrollmentRecords(body) {
  try {
    return (
      await baseAxios.post(`${COMMON}/fetch-enrollment-records`, { ...body })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchStudentAliasesMCB(body) {
  try {
    return (
      await baseAxios.post(`${COMMON}/fetch-student-aliases-mcb`, { ...body })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function wildQueryStudent(term) {
  try {
    return (await baseAxios.get(`${COMMON}/wild/${term}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateManyStudents(body) {
  try {
    const params = {
      FunctionName: "BulkOp-Student-0TZICzB86yXq",
      Payload: JSON.stringify(body),
    };
    const res = await lambda.invoke(params).promise();
    return JSON.parse(res.Payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchStudentCount(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/count`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchBatchStatistics(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/getBatchStatistics`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchBatchPromote(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/promoteStudents`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchBatchRevertPromote(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/revertPromoteStudents`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentAyInfo(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/ay-info`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentDetails(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/sDetails`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
// export async function fetchStudentDetails(obj) {
//   try {
//     return (
//       await baseAxios.get(`${COMMON}/sDetails`, {
//         params: {
//           branch: obj.branch,
//           admissionNos: obj.admissionNos.join(","), // Convert the array to a comma-separated string
//         },
//       })
//     ).data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }
export async function UploadStudentImage(obj) {
  const {
    _id,
    avatar,
    applicationDoc,
    admissionDoc,
    pathName,
    indexVal,
    mode,
  } = obj;
  const formData = new FormData();

  // Append the appropriate form data based on the pathName
  if (pathName === "avatar") {
    formData.append("avatar", avatar);
  } else if (pathName === "application") {
    formData.append("applicationDoc", applicationDoc);
  } else if (pathName === "admission") {
    formData.append("admissionDoc", admissionDoc);
  }

  try {
    // Construct the PATCH request URL with the _id and index as query parameters
    const { data } = await baseAxios.patch(
      `${COMMON}/new-image/${pathName}/${_id}`,
      formData,
      { params: { index: indexVal, mode } } // Pass the index as a query parameter
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updateStudent(obj) {
  const { _id, phaseChange, ...rest } = obj; // Destructure the avatar field
  const params = phaseChange ? { phaseChange: true } : null;

  try {
    const { data } = await baseAxios.patch(
      `${COMMON}/${_id}`,
      {
        ...rest,
      },
      { params }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteStudent(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateMultipleStudents(body) {
  try {
    return (await baseAxios.put(`${COMMON}/`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updateStudentsSections(body) {
  try {
    return (await baseAxios.put(`${COMMON}/section-update`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function bulkUpload(obj) {
  const { phase, body, verified } = obj;
  try {
    return (
      await baseAxios.post(
        `${COMMON}/add?phase=${phase}&instituteId=${store.get(
          "instituteId"
        )}&verified=${verified}`,
        body
      )
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function bulkUpdate(obj) {
  const { phase, body } = obj;

  try {
    const res = await baseAxios.post(
      `${COMMON}/update?phase=${phase}&instituteId=${store.get("instituteId")}`,
      body
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchDoc(obj) {
  const { phase, ...rest } = obj;
  try {
    return (
      await baseAxios.get(`${COMMON}/doc/${phase}`, { params: { ...rest } })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchMultiDocs(obj) {
  const { phase, ...rest } = obj;
  try {
    return (
      await baseAxios.post(`${COMMON}/multi-docs`, {
        params: { ...rest },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function studentExists(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/exist`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentBedStatus(obj) {
  try {
    const res = (await baseAxios.get(`${COMMON}/bedNo`, { params: { ...obj } }))
      .data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
// const getStudentsByBed = async (obj) => {
//   try {
//     return( await baseAxios.get(`${COMMON}/bedNo?bedNo=${bedNos}`))
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching student data:", error);
//     return [];
//   }
// };

export async function getBasicDetailOfStudent(id) {
  try {
    const res = (await baseAxios.get(`${COMMON}/basic/${id}`)).data;

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchStudentProfile(id, params) {
  try {
    const res = (await baseAxios.get(`${COMMON}/profile/${id}`, { params }))
      .data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentByPhoneNo(obj) {
  try {
    const res = (await baseAxios.post(`${COMMON}/ByPhoneNo`, obj)).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function studentsBranchTranser(body) {
  try {
    const params = {
      FunctionName: "BulkOp-Student-0TZICzB86yXq",
      Payload: JSON.stringify(body),
    };
    const res = await lambda.invoke(params).promise();
    return JSON.parse(res.Payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchApplicationCommissionItems(body) {
  const { id, query, params } = body;
  const path = id ? `${COMMON}/commissions/${id}` : `${COMMON}/commissions`;
  try {
    const res = (await baseAxios.post(path, { ...query }, { params })).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function UpdateHallTicketStuff(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/hall-tickets-and-stuff`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getHallTicketStuff(obj) {
  try {
    const response = await baseAxios.post(
      `${COMMON}/hall-tickets-and-stuff1`,
      obj
    );
    // console.log(response.data);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function withdrawComission(id) {
  try {
    const res = (await baseAxios.post(`${COMMON}/widhdraw/${id}`)).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createParentAccount(params) {
  try {
    const res = (
      await baseAxios.post(`${COMMON}/createParentAccount/${params.id}`, params)
    ).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchParentAccount(params) {
  try {
    const res = (await baseAxios.post(`${COMMON}/fetchParentAccount`, params))
      .data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function checkValidityOfApplicationRegistration(params) {
  try {
    const res = (
      await baseAxios.post(`${COMMON}/check-application-validity`, params)
    ).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function registerApplication(params) {
  try {
    const res = (await baseAxios.post(`${COMMON}/register-application`, params))
      .data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchStudentPhoneNumber(params) {
  try {
    const res = (
      await baseAxios.post(`${COMMON}/ph`, { ...params, timeout: 60000 })
    ).data;
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
