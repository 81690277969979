import MuiAccordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
// reusable components

const FiltersContainer = ({ children }) => (
  <Grid
    container
    spacing={{ xs: 1, md: 1 }}
    columns={{ xs: 2, sm: 6, md: 8, lg: 10 }}
  >
    {children}
  </Grid>
);

export default FiltersContainer;

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  background: "transparent",
  "&:before": {
    display: "none",
  },
  margin: 0,
  "&.MuiPaper-root": {
    borderRadius: "8px",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "40px",
    height: "43px",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "30px",
    height: "35px",
  },
});
