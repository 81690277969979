import store from "store";
import baseAxios from "../baseAxios";

export async function createZone(body) {
  try {
    return (await baseAxios.post("/organization/zones", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchZonesList(params) {
  try {
    const { data } = await baseAxios.get("organization/zones", {
      params: { ...params, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateZone(obj) {
  const { _id, ...rest } = obj;
  try {
    const { data } = await baseAxios.patch(`organization/zones/${_id}`, {
      ...rest,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reportError(body) {
  try {
    return (await baseAxios.post("/organization/zones/reportError", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
