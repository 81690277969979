import store from "store";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
// actions
import {
  pushOperation,
  removeOperation,
} from "redux/notifcations/notifications.slice";
// pusub
import { publish } from "helpers/pubsub/utils";
// events
import UpdateResult from "helpers/pubsub/events/updateStudents";
// apis
import { updateManyStudents } from "APIs/admissions/student";

// function imitationPromise(delay, value) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(value);
//     }, delay);
//   });
// }

const useAsyncEvent = () => {
  const dispatch = useDispatch();
  const instituteId = store.get("instituteId");
  const emp = store.get("user")?.employeeId;

  const callHandler = async (body) => {
    try {
      if (!emp || !instituteId)
        return Promise.reject(new Error("Invalid employee or institute"));
      if (body?.name === "UNASSIGN_FIELD_ON_STUDENT") {
        const { item = "section", value, grade, batchId, branchCode } = body;
        const text = (() => {
          if (item === "section") return body?.sectionName;
          if (item === "batch") return body?.batchName;
          if (item === "class") return body?.className;
          return "Invalid";
        })();
        const op = enqueueSnackbar(`Unassigning student on ${text}...`, {
          variant: "info",
        });
        const params = {
          instituteId,
          action: "UPDATE_FIELDS_ON_STUDENT",
          filter: {
            instituteId,
            branch: branchCode,
          },
          update: {},
          emp,
        };

        if (item === "section") {
          params.filter.class = grade;
          params.filter.batch = batchId;
          params.filter.section = value;
          params.update.section = null;
        }

        if (item === "batch") {
          params.filter.class = grade;
          params.filter.batch = value;
          params.update.batch = null;
          params.update.section = null;
        }

        if (item === "class") {
          params.filter.class = grade;
          params.update.class = null;
          params.update.batch = null;
          params.update.section = null;
        }

        dispatch(pushOperation({ id: op, name: "UNASSIGN_FIELD_ON_STUDENT" }));
        // const res = await imitationPromise(5000, {
        //   statusCode: 200,
        //   message: "success",
        // });
        const res = await updateManyStudents(params);
        enqueueSnackbar("Successfully unassigned students", {
          variant: "success",
        });
        dispatch(removeOperation(op));
        publish(new UpdateResult(body));
        return res;
      }
    } catch (error) {
      return error.message;
    }
    return "INVALID NAME";
  };

  return [callHandler];
};

export default useAsyncEvent;
