import { useRef, useEffect } from "react";

export default (bool = true) => {
  if (!bool) return null;
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
