import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  createUserSuccess,
  fetchUsersSuccess,
  fetchUsersFailure,
  updateUsersList,
  fetchCounsellorsSuccess,
  fetchCounsellorsFailure,
  createSuperAdminSuccess,
} from "./userManagement.slice";
import { setInitializationStatus } from "../../app/app.slice";
// utils
import { create, update } from "../../app/app.utils";
// redux store
import storeRedux from "../../store";
// apis
import {
  registerEmployee,
  updateEmployee,
  getEmployees,
  registerEmployeeForInstituteSetUp,
} from "../../../APIs/organization/UserManagement";

const getUser = () => {
  const { userManagement } = storeRedux.getState();
  const newUser = userManagement.user;
  return newUser;
};

const getSearchParams = () => {
  const { userManagement } = storeRedux.getState();
  const params = userManagement.searchParams;
  return params;
};

export function* createUserAsync({ payload }) {
  yield create({
    callName: "create user",
    inputs: payload,
    api: registerEmployee,
    *handleRes() {
      yield put(createUserSuccess());
    },
    resName: "newEmployee",
  });
}
export function* createUserForInstituteSetUpAsync() {
  const user = getUser();

  yield create({
    callName: "create user",
    inputs: user,
    api: registerEmployeeForInstituteSetUp,
    *handleRes() {
      yield put(createSuperAdminSuccess());
    },
    resName: "newEmployee",
  });
  // try {
  //   const res = yield registerEmployee(user);
  //   if (res) yield put(createUserSuccess());
  // } catch (error) {
  //   yield put(createUserFailure(error.message));
  // }
}
export function* updateUserAsync({ payload }) {
  yield update({
    callName: "update_user",
    updates: payload,
    api: updateEmployee,
    *handleRes(res) {
      yield put(updateUsersList(res));
    },
    updatedName: "updatedEmployee",
  });
}

export function* fetchUsersAsync() {
  const params = getSearchParams();
  try {
    const res = yield getEmployees(params);
    if (res) yield put(fetchUsersSuccess(res.result.docs));
  } catch (error) {
    yield put(fetchUsersFailure(error.message));
  }
}
export function* fetchCounsellorsAsync() {
  const params = getSearchParams();
  try {
    const res = yield getEmployees(params);
    if (res) {
      yield put(fetchCounsellorsSuccess(res.result.docs));
      yield put(setInitializationStatus({ fetchCounsellors: true }));
    }
  } catch (error) {
    yield put(fetchCounsellorsFailure(error.message));
  }
}
export function* onRegisterUserStart() {
  yield takeLatest("userManagement/createUserStart", createUserAsync);
}
export function* onRegisterSuperAdminStart() {
  yield takeLatest(
    "userManagement/createSuperAdminStart",
    createUserForInstituteSetUpAsync
  );
}
export function* onUpdateUserStart() {
  yield takeLatest("userManagement/updateUserStart", updateUserAsync);
}

export function* onFetchUsersStart() {
  yield takeLatest("userManagement/fetchUsersStart", fetchUsersAsync);
}
export function* onFetchCounsellorsStart() {
  yield takeLatest(
    "userManagement/fetchCounsellorsStart",
    fetchCounsellorsAsync
  );
}

export default function* userManagementSaga() {
  yield all([
    call(onRegisterUserStart),
    call(onUpdateUserStart),
    call(onFetchUsersStart),
    call(onFetchCounsellorsStart),
    call(onRegisterSuperAdminStart),
  ]);
}
