import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";

export default function Spinner({
  props,
  styles,
  height,
  width,
  color,
  title,
}) {
  return (
    <SoftBox
      sx={{
        display: "flex",
        width: width || "100%",
        height: height || "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        ...styles,
      }}
      {...props}
    >
      <CircularProgress color={color || "info"} />
      {title && (
        <SoftTypography
          align="center"
          p={1}
          variant="caption"
          fontWeight="bold"
        >
          {title}
        </SoftTypography>
      )}
    </SoftBox>
  );
}
