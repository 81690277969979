import flatten from "lodash/flatten";
// mui
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// layout
import BodyWrapper from "../../layout/bodyWrapper";
// component
const OfflineUpload = lazy(() =>
  import("../../views/OfflineAnalysis/OfflineUploadResults")
);

const ScanOMR = lazy(() => import("../../views/OfflineAnalysis/OMRScanner"));
const ViewOfflineSummary = lazy(() =>
  import("../../views/OfflineAnalysis/ExamSummary")
);

export default {
  component: <BodyWrapper />,
  title: "Offline Utilities",
  basePath: "app",
  path: "offline-utilities",
  authorizedRoles: [],
  features: flatten(Object.values(features?.["offline-utilities"])),
  icon: <WifiOffRoundedIcon />,
  children: [
    // {
    //   component: <AdminPortalComponent />,
    //   title: "Offline Analysis",
    //   basePath: "offline-utilities",
    //   adminPath: true,
    //   path: "offline-analysis",
    //   icon: <WifiOffRoundedIcon />,
    //   authorizedRoles: [],
    // },
    {
      component: <ScanOMR />,
      title: "OMR Scan",
      features: features?.["offline-utilities"].omr_scan,
      basePath: "offline-utilities",
      path: "scanOMR",
      authorizedRoles: [],
    },
    {
      component: <OfflineUpload />,
      title: "Upload Results",
      basePath: "offline-utilities",
      path: "offline-results",
      features: features?.["offline-utilities"].upload_results,
      authorizedRoles: [],
    },
    {
      component: <ViewOfflineSummary />,
      title: "Exam Summary",
      basePath: "offline-utilities",
      path: "exam-summary",
      features: features?.["offline-utilities"].exam_summary,
      authorizedRoles: [],
    },
  ],
};
