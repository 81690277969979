import { useState, useEffect } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { enqueueSnackbar } from "notistack";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PortraitRoundedIcon from "@mui/icons-material/PortraitRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// myComponents
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import { useNavigate } from "react-router-dom";
// hooks
import useFetch from "hooks/useFetch";
// store
import store from "store";
import {
  updateReadBy,
  archiveNotification,
} from "APIs/organization/operations";

export const cat = {
  FINANCE: {
    color: "#A0E9FF",
    icon: <CurrencyRupeeRoundedIcon sx={{ color: "#00A9FF" }} />,
    iconColor: "#00A9FF",
  },
  DOWNLOAD: {
    color: "#A2FF86",
    icon: <FileDownloadRoundedIcon sx={{ color: "#164B60" }} />,
    iconColor: "#164B60",
  },
  STUDENT: {
    color: "#FBEEAC",
    icon: <GroupRoundedIcon sx={{ color: "#1D5D9B" }} />,
    iconColor: "#1D5D9B",
  },
  ADMISSIONS: {
    color: "#F2FFE9",
    icon: <PeopleAltIcon sx={{ color: "#FA7070" }} />,
    iconColor: "#FA7070",
  },
};

const ITEM_HEIGHT = 48;
const NotificationCard = ({
  category,
  text,
  emp,
  title,
  time,
  handleCardClik,
  _id,
  selected,
  isFromNotificationBell,

  archivedBy,
  readBy,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = store.get("user").userId;
  const [, error, response, updateData, resetFetch] = useFetch(updateReadBy);
  const [, errorAr, responseAr, archiveData, resetFetchAr] =
    useFetch(archiveNotification);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar("error in updating", { variant: "error" });
    }
  }, [error]);
  useEffect(() => {
    if (errorAr) {
      enqueueSnackbar("error in archive", { variant: "error" });
    }
  }, [errorAr]);

  useEffect(() => {
    if (response) {
      enqueueSnackbar("notification moved to read", { variant: "success" });
      const newUrl = `${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      window.location.reload();
    }
  }, [response]);
  useEffect(() => {
    if (responseAr) {
      enqueueSnackbar("notification moved to archive", { variant: "success" });
      const newUrl = `${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      window.location.reload();
    }
  }, [responseAr]);
  const handleClickRead = () => {
    updateData({ id: _id });
    return () => resetFetch();
  };
  const handleArchived = () => {
    archiveData({ id: _id, isArchived: true });
    return () => resetFetchAr();
  };

  return (
    <Card
      sx={({ breakpoints }) => ({
        mb: 2,
        flexShrink: 0,
        maxWidth: 600,
        width: "100%",
        cursor: "pointer",
        border: selected ? "2px solid #00A9FF" : "none",
        // m: 2,

        [breakpoints.between("sm", "xxl")]: {
          maxWidth: 400,
        },

        [breakpoints.up("xxl")]: {
          maxWidth: 500,
        },

        [breakpoints.down("sm")]: {
          width: `calc(100vw - 2rem)`,
        },
      })}
      onClick={() => {
        if (isFromNotificationBell) {
          navigate("/app/communications/app-notifications", {
            replace: true,
            state: { id: _id },
          });
        } else {
          handleCardClik(_id);
        }
      }}
    >
      <CardHeader
        avatar={
          <Avatar variant="rounded" sx={{ bgcolor: cat[category]?.color }}>
            {cat[category].icon}
          </Avatar>
        }
        title={
          <Grid container>
            <Grid item xs={11} paddingTop={2}>
              <SoftTypography color="dark" variant="body2" fontWeight="bold">
                {title}
              </SoftTypography>
            </Grid>
            <Grid item xs={1}>
              <div>
                {readBy?.includes(user) && archivedBy?.includes(user) ? null : (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                )}

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "30ch",
                    },
                  }}
                >
                  {!readBy?.includes(user) && (
                    <MenuItem onClick={handleClickRead}>
                      <ListItemIcon>
                        <VisibilityOutlinedIcon
                          fontSize="medium"
                          color="warning"
                        />
                      </ListItemIcon>
                      <ListItemText fontSize="12px"> Mark as read</ListItemText>
                    </MenuItem>
                  )}

                  {!archivedBy?.includes(user) && (
                    <MenuItem onClick={handleArchived}>
                      <ListItemIcon>
                        <ArchiveOutlinedIcon fontSize="medium" color="info" />
                      </ListItemIcon>
                      <ListItemText fontSize="12px"> Archive</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </Grid>
          </Grid>
        }
        subheader={
          <SoftBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <Icon color="primary">
                <PortraitRoundedIcon />
              </Icon>
              &nbsp;
              <SoftTypography color="secondary" variant="caption">
                {emp}
              </SoftTypography>
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <Icon color="warning">
                <AccessTimeRoundedIcon />
              </Icon>
              &nbsp;
              <SoftTypography color="secondary" variant="caption">
                {moment(time).format("DD MMM YYYY hh:mm A")}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        }
        sx={{ pb: 0, pt: 1 }}
      />
      <CardContent
        sx={{ pb: (theme) => `${theme.spacing(1)} !important`, pt: 1 }}
      >
        <SoftTypography variant="body2" pl={6} sx={{ whiteSpace: "pre-line" }}>
          {text}
        </SoftTypography>
        {readBy?.includes(user) && (
          <Grid container>
            <Grid item xs={12} container justifyContent="flex-end">
              <IconButton>
                <DoneAllIcon fontSize="small" color="success" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default NotificationCard;
