import { Card, useMediaQuery } from "@mui/material";
import { getEMPDetails } from "APIs/UserServices";
import indexImg from "assets/images/indexImg.png";
import { useFormik } from "formik";
import useFetch from "hooks/useFetch";
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftNotification from "myComponents/SoftNotification";
import SoftTypography from "myComponents/SoftTypography";
import { useEffect, useState } from "react";
import store from "store";
import RequestOTP from "./RequestOTP";

const initialValues = {
  employeeId: "",
};
const ForgotMPIN = () => {
  const inst = store.get("instituteId");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isOpen, setOpen] = useState(false);
  const [isLoading, err, result, getUser, resetFetch] = useFetch(getEMPDetails);

  const formik = useFormik({
    initialValues,
    onSubmit: async (credentials) => {
      getUser({
        empId: credentials.employeeId,
        institute: inst,
      });
    },
  });
  const msgs = {
    error: err || "something went wrong.",
    success: "User details fetched.",
    loading: "Loading please wait...",
  };
  const getFeedback = () => {
    if (isLoading) return { msg: msgs.loading, type: "info" };
    if (result) return { msg: msgs.success, type: "success" };
    return { msg: msgs.error, type: "error" };
  };

  useEffect(() => {
    if (isLoading) setOpen(true);
  }, [isLoading]);

  const handleClose = () => {
    resetFetch();
    setOpen(false);
  };

  const renderSignInFields = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <SoftBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <SoftBox mb={1}>
          <SoftBox mb={0.5} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Enter employee id / email id
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="employeeId"
            type="text"
            placeholder="Employee Id"
            value={formik.values.employeeId}
            error={
              formik.touched.employeeId && Boolean(formik.errors.employeeId)
            }
            onChange={formik.handleChange}
          />
          {formik.touched.employeeId && Boolean(formik.errors.employeeId) ? (
            <SoftTypography
              variant="caption"
              color={
                formik.touched.employeeId && Boolean(formik.errors.employeeId)
              }
            >
              {formik.errors.employeeId}
            </SoftTypography>
          ) : null}
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="contained" color="info" fullWidth>
            Next
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );

  if (result && result.emp) return <RequestOTP response={result.emp} />;

  return (
    <CoverLayout
      ifLogoRequired
      title="Reset MPIN"
      description="Enter following details to start reset process"
      image={indexImg}
    >
      {isMobile ? renderSignInFields() : <Card>{renderSignInFields()}</Card>}
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </CoverLayout>
  );
};

export default ForgotMPIN;
