import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  createZoneSuccess,
  fetchZonesSuccess,
  resetZoneProcess,
  updateZonesList,
  fetchZonesAySuccess,
} from "./zones.slice";
import { setInitializationStatus } from "../../app/app.slice";
// apis
import {
  createZone,
  fetchZonesList,
  updateZone,
} from "../../../APIs/organization/ZoneServices";
// utils
import { fetch, create, update } from "../../app/app.utils";
import { getState } from "../../selector.utils";
// selectors
import { selectZone, selectQuery } from "./zones.selectors";
import { selectIsAppInitialized } from "../../app/app.selectors";

export function* createZoneAsync() {
  const zone = yield selectZone(getState());

  yield create({
    callName: "create zone",
    inputs: zone,
    api: createZone,
    *handleRes(item) {
      yield put(createZoneSuccess(item));
      yield put(resetZoneProcess());
    },
    resName: "savedZone",
  });
}

export function* fetchZonesAsync() {
  const { showFeedback = true, flag, ...rest } = selectQuery(getState());

  yield fetch({
    callName: "fetch zone[s]",
    query: { ...rest },
    api: fetchZonesList,
    *handleRes(items) {
      if (flag) {
        yield put(fetchZonesAySuccess(items));
      } else {
        yield put(fetchZonesSuccess(items));
      }

      const isAppInitialized = selectIsAppInitialized(getState());
      if (!isAppInitialized)
        yield put(setInitializationStatus({ fetchedZones: true }));
      yield put(resetZoneProcess());
    },
    showFeedback,
  });
}

export function* updateZoneAsync() {
  const updates = yield selectZone(getState());

  yield update({
    callName: "update zone",
    updates,
    api: updateZone,
    *handleRes(zone) {
      yield put(updateZonesList(zone));
      yield put(resetZoneProcess());
    },
    updatedName: "updatedZone",
  });
}

export function* onFetchZonesStart() {
  yield takeLatest("ZONES/fetchZonesStart", fetchZonesAsync);
}

export function* onZoneCreationStart() {
  yield takeLatest("ZONES/createZoneStart", createZoneAsync);
}

export function* onUpdateZoneSatrt() {
  yield takeLatest("ZONES/updateZoneStart", updateZoneAsync);
}

export default function* zonesSaga() {
  yield all([
    call(onZoneCreationStart),
    call(onFetchZonesStart),
    call(onUpdateZoneSatrt),
  ]);
}
