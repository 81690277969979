import baseAxios from "../baseAxios";

const COMMON = "/academics/timings";

export async function createAttendanceTimings(obj) {
  try {
    return (await baseAxios.post(COMMON, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendanceTimings(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateAttendanceTimings(obj) {
  const { _id, ...rest } = obj;
  const path = _id ? `${COMMON}/${_id}` : COMMON;
  try {
    return (await baseAxios.patch(path, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
