import * as yup from "yup";

const changepasswordSchema = yup.object({
  password: yup
    .string("Enter your  old password")
    .required("old Password is required"),
  newPassword: yup
    .string("Enter your new password")
    .required("new Password is required"),
  confirmPassword: yup
    .string("Enter your confirm password")
    .required("confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Your passwords do not match."),
});
export default changepasswordSchema;
