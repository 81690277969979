import store from "store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// apis
import { logout } from "APIs/UserServices";
// actions
import { clearLoginUser } from "redux/app/app.slice";
// utils
// import { removeAllExams } from "utils/IndexedDB/utils";

const useSignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = store.get("user");
  const signOut = async ({ handleClose }) => {
    if (handleClose) handleClose();
    logout(user.userId);
    store.remove("user");
    store.remove("academicYear");
    store.remove("accessToken");
    store.remove("instituteId");
    dispatch(clearLoginUser());
    if (
      user.mPin &&
      user.addedPrivileges &&
      user.addedPrivileges.includes("enable_MPIN")
    ) {
      store.set("employeeId", user.userId);
      store.set("instituteId", user.instituteId);
    }
    if (navigator.userAgent.includes("Android") && window.Android && user._id) {
      window.Android?.clearFCMToken(user._id);
    }
    dispatch({ type: "CLEAR_APP_DATA" });
    navigate("/");
  };

  return signOut;
};

export default useSignOut;
