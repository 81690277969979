import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const useErrorAlert = (processStatus, resetBackdropProcess) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name, result, message, showFeedback } = processStatus;
  const [open, setOpen] = useState(false);
  const [mesg, setMesg] = useState();
  const show = useMemo(() => {
    if (
      location.pathname === "/app/finance/make-payment" &&
      result === "success" &&
      name === "fetch fee plan[s]"
    )
      return false;
    return showFeedback !== false;
  }, [location, showFeedback, name, result]);

  const getMessage = () => {
    if (message && (result === "success" || result === "info")) {
      return message;
    }
    if (!message) return null;
    const index = message?.indexOf("{");
    const duplicateString = message?.includes("duplicate")
      ? " already exists"
      : "";
    const m = `${String(message).substring(index)} ${duplicateString || ""}`;
    const mess = m.replace(/[{}]/g, " ");
    return mess;
  };
  useEffect(() => {
    const str = getMessage();
    setMesg(str);
    if (show && name) {
      setOpen(true);
    }
    if (show === false) dispatch(resetBackdropProcess());
  }, [processStatus]);

  return [mesg, result, open, setOpen, show];
};
export default useErrorAlert;
