// import isEmpty from "lodash/isEmpty";

export function getObjectValues(obj = {}) {
  return Object.values(obj);
}

export function getObjectKeys(obj = {}) {
  return Object.keys(obj);
}

export function objectExists(obj) {
  return (
    obj !== null && obj !== undefined && obj && getObjectKeys(obj).length > 0
  );
}

export function objectHasAtleastOneTrue(obj) {
  return getObjectValues(obj).some((v) => Boolean(v) === true);
}

export function removeEmptyProps(obj) {
  const newObj = {};
  getObjectKeys(obj).forEach((k) => {
    if (obj[k]) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function getObjectEntries(obj = {}) {
  return Object.entries(obj);
}
