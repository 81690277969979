import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  setStartOrEndOfBackdropProcess,
  setProcessStatus,
  setProcessType,
} from "../app/app.slice";
import { setTempData } from "./temp.slice";
// apis
import {
  getStatesOfIndia,
  getDistrictsOfState,
  getCitiesOfPostalCode,
  getStatesAndDistrictsOfIndia,
} from "../../APIs/thirdParyAPIs/geoNames";
import { fetchBranchesList } from "../../APIs/organization/BranchServices";
// redux store
import storeRedux from "../store";

const apiMap = {
  states: getStatesOfIndia,
  districts: getDistrictsOfState,
  zipCode: getCitiesOfPostalCode,
  allBranches: fetchBranchesList,
};

const getQueryName = () => {
  const { temp } = storeRedux.getState();
  const name = temp.query;
  return name;
};

export function* fetchSomeStuffAsync() {
  const query = getQueryName();
  const { queryName, params, key } = query;
  try {
    yield put(setProcessType("noBackdrop"));
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield apiMap[queryName](params);
    if (res) yield put(setTempData({ key, data: res }));
  } catch (error) {
    yield put(
      setProcessStatus({
        name: "fetching temp data",
        message: error.message,
        result: "error",
      })
    );
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* fetchStatesAndDistrictsAsync() {
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield getStatesAndDistrictsOfIndia();
    if (res) yield put(setTempData({ key: "temp1", data: res }));
  } catch (error) {
    yield put(
      setProcessStatus({
        name: "fetching temp data",
        message: error.message,
        result: "error",
      })
    );
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* onFetchTempData() {
  yield takeLatest("TEMP/fetchTempData", fetchSomeStuffAsync);
}

export function* onFetchStatesAndDistricts() {
  yield takeLatest(
    "TEMP/fetchStatesAndDistricts",
    fetchStatesAndDistrictsAsync
  );
}

export default function* tempSaga() {
  yield all([call(onFetchTempData), call(onFetchStatesAndDistricts)]);
}
