import { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// mui
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Pagination from "@mui/material/Pagination";
// myComponents
import SoftBox from "myComponents/SoftBox";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
// components
import Spinner from "components/Spinner";
// hooks
import useFetch from "hooks/useFetch";
// apis
import {
  fetchNotificationList,
  updateReadBy,
} from "APIs/organization/notification";
// local components
import NotificationItem from "./NotificatoinItem";

export const cat = {
  FINANCE: {
    color: "#A0E9FF",
    icon: <CurrencyRupeeRoundedIcon sx={{ color: "#00A9FF" }} />,
    iconColor: "#00A9FF",
  },
  DOWNLOAD: {
    color: "#A2FF86",
    icon: <FileDownloadRoundedIcon sx={{ color: "#164B60" }} />,
    iconColor: "#164B60",
  },
  STUDENT: {
    color: "#FBEEAC",
    icon: <GroupRoundedIcon sx={{ color: "#1D5D9B" }} />,
    iconColor: "#1D5D9B",
  },
  ADMISSIONS: {
    color: "#F2FFE9",
    icon: <PeopleAltIcon sx={{ color: "#FA7070" }} />,
    iconColor: "#FA7070",
  },
};

const categories = ["ALL", ...Object.keys(cat)];

const NotificationsView = ({ handleClose, setUnreadCount }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [category, setCategory] = useState("FINANCE");
  const [loading, err, res, fetch, reset] = useFetch(fetchNotificationList);
  const [pageProps, setPageProps] = useState({
    totalDocs: 1,
    limit: 5,
    totalPages: 1,
    page: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });

  useEffect(() => {
    if (res) {
      const { docs = [], ...rest } = res?.result || {};
      setNotifications(docs);
      setPageProps(rest);
      if (setUnreadCount) setUnreadCount(res.unread_count);
    }

    if (err) {
      enqueueSnackbar(err, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }

    return () => {
      reset();
    };
  }, [res, err]);

  useEffect(() => {
    const payload = {
      page: pageProps?.page || 1,
      limit: pageProps?.limit || 10,
    };
    if (category !== "ALL") {
      payload.channel = category;
    }
    fetch(payload);
  }, [category]);

  const handleNotificationClick = (_id, url) => {
    if (!url) return;
    if (window.location.href === url) return;
    const urlObj = new URL(url);
    const path = `${urlObj.pathname}${urlObj.search}`;
    navigate(path);
    if (handleClose) {
      handleClose();
    }
    updateReadBy(_id);
  };

  return (
    <SoftBox>
      <SoftBox p={0.5} display="flex" width={500}>
        <SoftBox width={200}>
          <SoftAutoComplete
            list={categories}
            selected={category}
            handleSelect={(event, value1) => setCategory(value1)}
            label="Category"
            simpleOptions
            size="small"
            width="100px"
            noHeaderLabel
          />
        </SoftBox>
        &nbsp;&nbsp;
        <SoftBox width={300} pt={0.7}>
          <Pagination
            variant="outlined"
            padding={1}
            size="small"
            shape="rounded"
            color="info"
            count={pageProps?.totalPages || 1}
            page={pageProps?.page || 1}
            sx={{ justifyContent: "space-around" }}
            onChange={(_, page) => {
              setPageProps((prev) => ({
                ...prev,
                page,
              }));
              const payload = {
                page,
                limit: pageProps?.limit || 10,
                channel: category,
              };
              fetch(payload);
            }}
          />
        </SoftBox>
      </SoftBox>
      {loading ? (
        <SoftBox height={300}>
          <Spinner />
        </SoftBox>
      ) : null}
      {notifications.map((notification, i) => (
        <NotificationItem
          key={i}
          {...notification}
          handleNotificationClick={handleNotificationClick}
        />
      ))}
    </SoftBox>
  );
};

export default NotificationsView;
