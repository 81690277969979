// react
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
// material ui
import Grid from "@mui/material/Grid";
// my components
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import Card from "@mui/material/Card";
import SoftDataGrid from "myComponents/SoftDataGrid";
import SoftTypography from "myComponents/SoftTypography";
// selectors
import { selectAcademicYears } from "redux/organization/academicYear/academicYear.selectors";
import { selectBranchesList } from "redux/organization/branches/branches.selectors";
import { selectCurrentAcademicYear } from "redux/app/app.selectors";
import { fetchStudentAliasesMCB } from "APIs/admissions/student";
// components
import useFetch from "hooks/useFetch";
// columns
import columns from "./columns";

const Report = () => {
  const [studentsData, setStudentsData] = useState([]);
  const cy = useSelector(selectCurrentAcademicYear);
  const [Branch, setBranch] = useState("");
  const [type, setType] = useState("");
  const [loading, error, response, getDetails, reset] = useFetch(
    fetchStudentAliasesMCB
  );
  const years = useSelector(selectAcademicYears);
  const [selectedYear, setSelectedYear] = useState(cy?.code);

  const handleGetClick = () => {
    let payload;
    if (!Branch) {
      enqueueSnackbar("Branch is required", {
        variant: "error",
        autoHideDuration: 1500,
      });
      return;
    }
    if (!type) {
      enqueueSnackbar("Type is required", {
        variant: "error",
        autoHideDuration: 1500,
      });
      return;
    }
    if (type === "To") {
      payload = {
        from_branc_code: Branch?.branchCode,
        academic_year: selectedYear,
      };
    } else {
      payload = {
        to_branch_code: Branch?.branchCode,
        academic_year: selectedYear,
      };
    }

    getDetails(payload);
  };

  useEffect(() => {
    if (response) {
      enqueueSnackbar("Fetched successfully", {
        variant: "success",
        autoHideDuration: 1500,
      });
      setStudentsData(response?.result);
    }
    if (error) {
      enqueueSnackbar(error, { variant: "error", autoHideDuration: 1500 });
    }
    return () => reset();
  }, [response, error]);

  useEffect(() => {
    if (loading) {
      enqueueSnackbar("Please wait...", {
        variant: "warning",
        autoHideDuration: 1500,
      });
    }
  }, [loading]);
  useEffect(() => {
    if (
      response &&
      response?.result?.length === 0 &&
      studentsData?.length === 0
    ) {
      enqueueSnackbar("No Data Available", {
        variant: "info",
        autoHideDuration: 2000,
      });
    }
  }, [studentsData, response]);

  const branches = useSelector(selectBranchesList);
  return (
    <>
      <Card sx={{ padding: "5px", margin: "2px" }}>
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12} sm={3}>
            <SoftAutoComplete
              list={years.map((item) => item.code)}
              selected={selectedYear}
              handleSelect={(e, i) => setSelectedYear(i)}
              simpleOptions
              label="Academic Year"
              noHeaderLabel
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftAutoComplete
              label="Branch"
              list={branches}
              optionLabelPropName="branchName"
              optionValuePropName="branchCode"
              selected={Branch}
              handleSelect={(_, value) => setBranch(value)}
              noHeaderLabel
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftAutoComplete
              label="Type"
              list={["From", "To"]}
              selected={type}
              handleSelect={(_, value) => setType(value)}
              noHeaderLabel
              simpleOptions
            />
          </Grid>
          <Grid container item xs={12} sm={3} paddingTop={1}>
            <SoftButton color="info" size="small" onClick={handleGetClick}>
              Get Details
            </SoftButton>
          </Grid>
        </Grid>
      </Card>

      {studentsData?.length !== 0 ? (
        <Grid item xs={12} paddingTop={1}>
          <SoftBox
            bgColor="white"
            borderRadius="lg"
            shadow="lg"
            overflow="hidden"
            p={1}
          >
            <SoftDataGrid
              columns={columns}
              rows={studentsData || []}
              loading={loading}
              getRowId={(row) => row._id}
              elevation={0}
              latestDataToDisplay={false}
              showCellVerticalBorder
              showColumnVerticalBorder
              gridPagination
              AdditionalComponent={SoftTypography}
              toolBar
              getRowHeight={() => "auto"}
            />
          </SoftBox>
        </Grid>
      ) : null}
    </>
  );
};

export default Report;
