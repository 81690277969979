// mui
import Snackbar from "@mui/material/Snackbar";
// Custom styles for the SoftAlert
import SoftAlertRoot from "myComponents/SoftAlert/SoftAlertRoot";
import SoftAlertCloseIcon from "myComponents/SoftAlert/SoftAlertCloseIcon";
import SoftBox from "myComponents/SoftBox";

export default function SoftNotification({
  color,
  open,
  close,
  children,
  dismissible,
  vertical = "top",
  horizontal = "center",
  ...rest
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={close}
      key={vertical + horizontal}
    >
      <SoftAlertRoot ownerState={{ color }} {...rest}>
        <SoftBox display="flex" alignItems="center" color="white">
          {children}
        </SoftBox>
        <SoftAlertCloseIcon onClick={close}>&times;</SoftAlertCloseIcon>
      </SoftAlertRoot>
    </Snackbar>
  );
}
