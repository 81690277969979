import { Suspense } from "react";
import { Route } from "react-router-dom";
import store from "store";
// components
import Spinner from "components/Spinner";
// utils
import { getAllowedRoutes } from "utils/getAllowedRoutes";
// selectors

const AllowedRoutes = (allRoutes, initialized, privileges, attendanceConf) => {
  const user = store.get("user");
  const { instituteId, addedPrivileges, removedPrivileges } = user || {};
  const assignedRoles =
    user && Object.keys(user).length > 0 ? user.assignedRoles : ["SUPER_ADMIN"];
  const allowedRoutes = getAllowedRoutes(
    allRoutes,
    assignedRoles,
    privileges,
    attendanceConf,
    instituteId || "",
    addedPrivileges,
    removedPrivileges
  );

  return allowedRoutes.map((route, i) => {
    const { path, component, children, index } = route;

    if (children && children.length > 0) {
      return (
        <Route
          key={`${path}-${i}`}
          path={path}
          element={<Suspense fallback={<Spinner />}>{component}</Suspense>}
        >
          {index ? (
            <Route
              key={`${path}-${i}`}
              index
              element={<Suspense fallback={<Spinner />}>{index}</Suspense>}
            />
          ) : null}
          {AllowedRoutes(children, initialized, privileges, attendanceConf)}
        </Route>
      );
    }
    return <Route key={`${path}-${i}`} path={path} element={component} />;
  });
};

export default AllowedRoutes;
