import { createSlice } from "@reduxjs/toolkit";
// utils
import {
  getUnionOfLists,
  getUpdatedList,
} from "../../../utils/general.utils/array.utils";

const initialState = {
  zone: null,
  zonesList: [],
  query: null,
};

const zoneSlice = createSlice({
  name: "ZONES",
  initialState,
  reducers: {
    resetZoneProcess(state) {
      state.zone = null;
      state.query = null;
    },
    createZoneStart(state, action) {
      state.zone = action.payload;
    },
    createZoneSuccess(state, action) {
      const list = state.zonesList;
      state.zonesList = [...list, action.payload];
    },
    fetchZonesStart(state, action) {
      state.query = action.payload;
    },
    fetchZonesSuccess(state, action) {
      const oldList = state.zonesList;
      const newList = action.payload;
      state.zonesList = getUnionOfLists(oldList, newList);
    },
    fetchZonesAySuccess(state, action) {
      state.zonesList = action.payload;
      // const newList = action.payload;
    },
    updateZoneStart(state, action) {
      state.zone = action.payload;
    },
    updateZoneFailure(state, action) {
      state.error = action.payload;
    },
    updateZonesList(state, action) {
      const list = state.zonesList;
      state.zonesList = getUpdatedList(action.payload, list);
    },
    clearZonesList(state) {
      state.zonesList = [];
    },
  },
});

export const {
  resetZoneProcess,
  createZoneStart,
  createZoneSuccess,
  fetchZonesStart,
  fetchZonesSuccess,
  updateZoneStart,
  updateZoneFailure,
  updateZonesList,
  clearZonesList,
  fetchZonesAySuccess,
} = zoneSlice.actions;

export default zoneSlice.reducer;
