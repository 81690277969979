// import store from "store";
import baseAxios from "../baseAxios";

const COMMON = `organization/approval-request`;

export async function postApprovalRequest(body) {
  try {
    return (await baseAxios.post(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function bulkStatusApproval(body) {
  try {
    return (await baseAxios.post(`${COMMON}/bulkStatusApproval`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function bulkApproveStatusRequest(body) {
  try {
    return (await baseAxios.post(`${COMMON}/bulkApproveStatusRequest`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchConcessionRequestsOfStudent(body) {
  try {
    return (await baseAxios.get(`${COMMON}/concession`, { params: body })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchTransferRequestsOfStudent(body) {
  try {
    return (await baseAxios.get(`${COMMON}/branch-transfer`, { params: body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchApprovalRequests(body) {
  try {
    return (await baseAxios.get(COMMON, { params: body })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function approveOrDisapproveConcessionRequest(body) {
  try {
    return (await baseAxios.post(`${COMMON}/approve-concession`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateRemark(body) {
  try {
    return (await baseAxios.post(`${COMMON}/updateRemark`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function approveBranchTransferReqeust(body) {
  try {
    return (await baseAxios.post(`${COMMON}/approve-bt-request`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBatchTransferRequestsOfStudent(body) {
  try {
    return (await baseAxios.get(`${COMMON}/batch-transfer`, { params: body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function approveOrDisapproveWithinBranchTransferRequest(body) {
  try {
    return (await baseAxios.post(`${COMMON}/approve-wbt-request`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
