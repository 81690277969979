import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  academicYears: [],
  error: "",
  status: "",
  process: "",
  stuffToUpdate: {},
  updateCount: 0,
  year: "",
};

const academicYearSlice = createSlice({
  name: "year",
  initialState,
  reducers: {
    resetAcademicYearProcess(state) {
      state.process = "";
      state.status = "";
      state.error = "";
      state.updates = "";
    },
    createAcademicYearStart(state, action) {
      state.year = action.payload;
      state.status = "info";
      state.process = "create";
    },
    createAcademicYearSuccess(state) {
      state.status = "success";
    },
    fetchAcademicYearsStart(state) {
      state.process = "fetch";
      state.status = "info";
    },
    fetchAcademicYearsSuccess(state, action) {
      state.status = "success";
      state.academicYears = action.payload;
    },
    fetchAcademicYearsFailure(state, action) {
      state.status = "error";
      state.error = action.payload;
    },
    updateAcademicYearMapping(state, action) {
      state.stuffToUpdate = action.payload;
    },
    updateAcademicYearStart(state, action) {
      state.process = "update";
      state.status = "info";
      state.stuffToUpdate = action.payload;
    },
    updateAcademicYearSuccess(state) {
      state.status = "success";
    },
    updateAcademicYearFailure(state, action) {
      state.status = "success";
      state.error = action.payload;
    },
    updateAcademicYearsList(state, action) {
      const list = state.academicYears;
      state.academicYears = getUpdatedList(action.payload, list);
      state.updateCount += 1;
    },
    clearAcademicYearsList(state) {
      state.academicYears = [];
    },
  },
});

export const {
  fetchAcademicYearsStart,
  fetchAcademicYearsSuccess,
  fetchAcademicYearsFailure,
  updateAcademicYearMapping,
  resetAcademicYearProcess,
  updateAcademicYearsList,
  updateAcademicYearStart,
  updateAcademicYearSuccess,
  updateAcademicYearFailure,
  createAcademicYearStart,
  createAcademicYearSuccess,
  clearAcademicYearsList,
} = academicYearSlice.actions;

export default academicYearSlice.reducer;
