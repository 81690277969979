import store from "store";
import { takeLatest, put, all, call } from "redux-saga/effects";
// actions
import {
  setIfAppInitialized,
  resetApp,
  approveConcessionReqSuccess,
  fetchingEmployeesSuccess,
  clearEmployees,
  startFetchingEmployees,
} from "redux/app/app.slice";
import { clearAllExams } from "redux/exams/exams.slice";
import {
  fetchAcademicYearsStart,
  clearAcademicYearsList,
} from "redux/organization/academicYear/academicYear.slice";
import {
  // fetchFeePlanStart,
  clearFeePlansList,
  fetchApplicationNoRangesStart,
} from "redux/finance/feePlan/feePlan.slice";
import {
  clearInstituteBatches,
  fetchBatchesStart,
} from "redux/organization/batches/batches.slice";
import {
  clearBranchesList,
  fetchBranchesStart,
} from "redux/organization/branches/branches.slice";
import {
  clearZonesList,
  fetchZonesStart,
} from "redux/organization/zones/zones.slice";
import { clearInstituteConfig } from "redux/settings/settings.slice";
import { clearCounsellorsList } from "redux/organization/userManagement/userManagement.slice";
import { clearDashboardSummary } from "redux/dashboard/Summary/dashboard.slice";
// apis
import { approveOrDisapproveConcessionRequest } from "APIs/organization/approvalRequest";
import { getBasicDetialsOfAllEmployees } from "APIs/organization/UserManagement";
// utils
import { update, fetch } from "redux/app/app.utils";
import { openDb, removeAllExams } from "utils/IndexedDB/utils";

// redux store
import storeRedux from "../store";

const getInitializingStatus = () => {
  const { app } = storeRedux.getState();
  const status = app.initializingStatus;
  return status;
};

export function* onInitializeIndexedDb() {
  yield openDb();
  yield put({ type: "RETRIVE_EXAMS_FROM_INDEXDB" });
}

export function* fetchData() {
  yield put(fetchAcademicYearsStart());
  yield put({
    type: "userManagement/fetchCounsellorsStart",
    payload: { designation: "counsellor", noTimeFilter: true },
  });
  yield put({
    type: "SETTINGS/fetchConfigStart",
    payload: {
      instituteId: store.get("instituteId"),
      initial: true,
      noTimeFilter: true,
    },
  });

  // fetchBranchesAndBatchesStart
  // yield put(
  //   fetchFeePlanStart({
  //     instituteId: store.get("instituteId"),
  //     initial: true,
  //     noTimeFilter: true,
  //   })
  // );
  // yield openDb();
  yield put({ type: "FETCH_ALL_EXAMS" });
  yield put(startFetchingEmployees());
  yield put(
    fetchApplicationNoRangesStart({
      initial: true,
    })
  );

  const statusInterval = yield setInterval(() => {
    const status = getInitializingStatus();
    if (Object.values(status).every((item) => item === true)) {
      storeRedux.dispatch(setIfAppInitialized());
      clearInterval(statusInterval);
    }
  }, 1000);
}
export function* fetchBranchesAndBtachesAsync({ payload }) {
  yield put(fetchZonesStart(payload));
  yield put(fetchBranchesStart(payload));
  yield put(fetchBatchesStart(payload));
}
export function* approveConcessionReqAsync({ payload }) {
  yield update({
    callName: "CONCESSION_REQ",
    updates: payload,
    api: approveOrDisapproveConcessionRequest,
    *handleRes() {
      yield put(approveConcessionReqSuccess());
    },
    needAdditionalInfo: true,
    updatedName: "approved",
  });
}

export function* fetchEmployeesAsync() {
  yield fetch({
    callName: "fetch_employees",
    api: getBasicDetialsOfAllEmployees,
    query: {},
    *handleRes(res) {
      yield put(fetchingEmployeesSuccess(res));
    },
    targetPath: "result",
  });
}

export function* reInitalizeApp() {
  yield put(clearAllExams());
  yield removeAllExams();
  yield put(clearAcademicYearsList());
  yield put(clearFeePlansList());
  yield put(clearInstituteBatches());
  yield put(clearBranchesList());
  yield put(clearZonesList());
  yield put(clearInstituteConfig());
  yield put(clearCounsellorsList());
  yield put(clearEmployees());
  yield put(resetApp());
  yield put({ type: "START_APP_INITIALIZATION" });
}

export function* clearAppData() {
  yield put(clearAllExams());
  yield removeAllExams();
  yield put(clearDashboardSummary());
  yield put(clearAcademicYearsList());
  yield put(clearFeePlansList());
  yield put(clearInstituteBatches());
  yield put(clearBranchesList());
  yield put(clearZonesList());
  yield put(clearInstituteConfig());
  yield put(clearCounsellorsList());
  yield put(clearEmployees());
  yield put(resetApp());
}

export function* fetchMetaData({ payload }) {
  if (payload.app_ranges) {
    yield put(
      fetchApplicationNoRangesStart({
        initial: true,
      })
    );
  }
}

export function* onAppInitializationStart() {
  yield takeLatest("START_APP_INITIALIZATION", fetchData);
}

export function* onReInitializeApp() {
  yield takeLatest("RE_INITIALIZE_APP", reInitalizeApp);
}

export function* onClearAppData() {
  yield takeLatest("CLEAR_APP_DATA", clearAppData);
}

export function* onFetchMetaData() {
  yield takeLatest("FETCH_METADATA", fetchMetaData);
}

export function* onApproveConcessionReq() {
  yield takeLatest("APP/approveConcessionReqStart", approveConcessionReqAsync);
}

export function* intializeIndexedDbAsync() {
  yield takeLatest("APP/intializeIndexedDb", onInitializeIndexedDb);
}

export function* onFetchEmployeesAsync() {
  yield takeLatest("APP/startFetchingEmployees", fetchEmployeesAsync);
}
export function* onfetchBranchesAndBtachesAsync() {
  yield takeLatest(
    "APP/fetchBranchesAndBatchesStart",
    fetchBranchesAndBtachesAsync
  );
}
export default function* appSaga() {
  yield all([
    call(onAppInitializationStart),
    call(onReInitializeApp),
    call(onClearAppData),
    call(onApproveConcessionReq),
    call(intializeIndexedDbAsync),
    call(onFetchEmployeesAsync),
    call(onFetchMetaData),
    call(onfetchBranchesAndBtachesAsync),
  ]);
}
