import { useState } from "react";
// @mui material components
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsNone from "@mui/icons-material/NotificationsNone";
import Fade from "@mui/material/Fade";
// view
import NotificationsView from "views/Communications/AppNotification/NotificationsView";

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="white" onClick={handleClick}>
        <Badge badgeContent={count} color="error">
          <NotificationsNone />
        </Badge>
      </IconButton>
      <Menu
        id="notfication-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "scroll",
              minWidth: "320px",
              maxWidth: "500px",
              minHeight: "400px",
            },
          },
        }}
      >
        <NotificationsView
          handleClose={handleClose}
          setUnreadCount={setCount}
        />
      </Menu>
    </>
  );
};

export default Notifications;
