import { Link } from "react-router-dom";
// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
// import IconButton from "@mui/material/IconButton";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
// import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";

function Breadcrumbs({ icon, title, route, light }) {
  // const isMobile = useMediaQuery("(max-width:700px)");
  const routes = route.slice(0, -1);
  const getPath = (i) => routes.slice(0, i + 1).join("/");
  // const navigate = useNavigate();

  return (
    <SoftBox
      mr={{ xs: 0, xl: 8 }}
      ml={{ xs: 1, xl: 0 }}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      alignContent="center"
    >
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        <Link to="/">
          <SoftTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </SoftTypography>
        </Link>
        {routes.map((el, index) => (
          <Link to={`/${getPath(index)}`} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </SoftTypography>
          </Link>
        ))}
        <SoftTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </SoftTypography>
        {/* <Tooltip title="Back">
          <IconButton
            color={light ? "white" : "dark"}
            onClick={() => navigate(-1)}
            size="small"
            sx={{ mr: 1 }}
          >
            <Icon>
              <ArrowBackIosNewRoundedIcon />
            </Icon>
          </IconButton>
        </Tooltip> */}
      </MuiBreadcrumbs>
    </SoftBox>
  );
}

export default Breadcrumbs;
