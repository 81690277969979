import { checkboxClasses } from "@mui/material/Checkbox";
import { formLabelClasses } from "@mui/material";
// import theme from "./appTheme";

export default {
  // Name of the component
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },

  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...((ownerState.variant === "contained" &&
          (ownerState.color === "primary" || ownerState.color === "info") && {
            color: "#fff",
          }) ||
          (ownerState.variant === "outlined" &&
            ownerState.color === "secondary" && {
              color: "#202040",
              borderColor: "#DFE4E7",
              backgroundColor: "#fff",
            }) ||
          (ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              color: "#A2A2A2",
              borderColor: "#DFE4E7",
              backgroundColor: "#DFE4E7",
              "&:hover": {
                backgroundColor: "#D2CECE",
              },
            })),
      }),
    },
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant === "filled" && {
          color: "#fff",
        }),
      }),
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: "#3BBDED",

        [`&.${checkboxClasses.checked}`]: {
          color: "#3BBDED",
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        // color: "#3BBDED",

        [`&.${formLabelClasses.focused}`]: {
          // color: "#3BBDED",
          // border: 1,
          // borderColor: "#3BBDED",

          color: "#3BBDED",
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiFilledInput-root": {
          border: "1px solid #e2e2e1",
          overflow: "hidden",
          borderRadius: 4,
          // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
          // transition: theme.transitions.create([
          //   "border-color",
          //   "background-color",
          //   "box-shadow",
          // ]),
          "&:hover": {
            backgroundColor: "#3BBDED",
          },
          "&.Mui-focused": {
            backgroundColor: "#3BBDED",
            boxShadow: "#3BBDED",
            borderColor: "#3BBDED",
          },
        },
        "& .MuiFormLabel-root": {
          color: "#202040",
          fontWeight: 500,
          fontSize: 13,
        },
        "& 	.MuiInputBase-root": {
          fontWeight: 500,
          fontSize: 13,
        },

        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "#3BBDED",
            background: "#3BBDED0F",
          },
          "& fieldset": {
            borderColor: "#DFE4E7",
          },
          "&.Mui-focused": {
            backgroundColor: "transparent",
            // boxShadow: `${alpha(theme.palette.info.main, 0.25)} 0 0 0 2px`,
            borderColor: "#3BBDED",
          },
          "&.Mui-focused fieldset": {
            // - Set the Input border when parent is focused
            borderColor: "#3BBDED",
            background: "#3BBDED0F",

            color: "#3BBDED",
          },
        },
      },
    },
  },
};
