const formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumFractionDigits: 0,
});

export default (inr) => formatter.format(Number(inr));

const formatter2 = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumFractionDigits: 2,
});

export const formatNumberWithFr = (number) =>
  formatter2.format(Number(number))?.replace("₹", "");

export const formatNumber = (number) =>
  new Intl.NumberFormat("en-IN").format(Number(number));
