// react
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import { enqueueSnackbar } from "notistack";
// material ui
import Typography from "@mui/material/Typography";
// my components
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";
// selectors
import { selectBatchesMap } from "redux/organization/batches/batches.selectors";
import { selectStudents } from "redux/admissions/student/student.selectors";
// helpers
import ModuleIndex from "helpers/ModuleIndex";
// actions
import {
  fetchStudent,
  clearStudents,
} from "redux/admissions/student/student.slice";
// components
import { FIELD_NAMES, SELECT_TYPES } from "config/globalConsts";
import { documentList } from "views/Admissions/utils/options";
import columns from "./columns";

const Docs = () => {
  const dispatch = useDispatch();
  const [studentsData, setStudentsData] = useState([]);
  const students = useSelector(selectStudents);
  const batchesMap = useSelector(selectBatchesMap);
  const [vals, setVals] = useState(null);
  const [reportName, setReportName] = useState("");
  const getValues = (values) => {
    setVals(values);
  };

  const handleGetClick = (values) => {
    const { ACADEMIC_YEAR, BRANCH, BATCH, documents } = values;
    if (!ACADEMIC_YEAR) {
      enqueueSnackbar(
        { message: "Please select academic year.", variant: "error" },
        { autoHideDuration: 2000 }
      );
      return;
    }
    const query = {};
    if (!BRANCH) {
      enqueueSnackbar("Branch is required", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    if (BRANCH) query.branches = BRANCH?.map((item) => item?.branchCode);
    if (BATCH) {
      query.batches = BATCH?.map((item) => item?.batchId);
    }

    setReportName(documents);

    if (!documents) {
      enqueueSnackbar("document is required", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    query.documents = [documents];
    query.instituteId = store.get("instituteId");
    query.phase = "admission";
    if (ACADEMIC_YEAR) query.current_ay = ACADEMIC_YEAR.code;
    query.joiningYear = "all";
    dispatch(fetchStudent(query));
  };

  useEffect(() => {
    setStudentsData(students.filter((item) => item));
  }, [students]);
  useEffect(() => () => dispatch(clearStudents()), []);

  const dataset = {};
  dataset.rows = studentsData || [];
  dataset.columns = columns(batchesMap);
  dataset.actions = { edit: false, del: false };
  dataset.autoHeight = true;
  console.log("studentsData", studentsData);
  return (
    <SoftBox p={2}>
      {studentsData.length !== 0 && (
        <SoftTypography variant="h6" color="dark" paddingTop={7}>
          {reportName} Submitted List
        </SoftTypography>
      )}

      <ModuleIndex
        initialValues={{
          ...vals,
        }}
        runGetClickOnFirstRender={false}
        fields={[
          { field: FIELD_NAMES.ACADEMIC_YEAR, selectType: SELECT_TYPES.SINGLE },

          {
            field: FIELD_NAMES.BRANCH,
            selectType: SELECT_TYPES.MULTI,
          },

          { field: FIELD_NAMES.CLASS, selectType: SELECT_TYPES.MULTI },
          {
            field: FIELD_NAMES.BATCH,
            selectType: SELECT_TYPES.MULTI,
          },
          {
            field: FIELD_NAMES.DROPDOWN_FIELD,
            selectType: SELECT_TYPES.SINGLE,
            name: "documents",

            simpleOptions: true,
            options: documentList.map((item) => item.documentName),
          },
        ]}
        handleGetClick={handleGetClick}
        dataSetX={dataset}
        title="students_list"
        Component={<Typography />}
        getValues={getValues}
      />
    </SoftBox>
  );
};

export default Docs;
