import { useEffect } from "react";
import { Formik, Form } from "formik";
// mui
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
//  my components
import SoftButton from "myComponents/SoftButton";
// components
import FormFieldsContainer from "./FormFieldsContainer";

function calculateDateOfBirth(classYear, age) {
  // Get the current year
  const currentYear = dayjs().year();

  // Calculate the birth year based on the class year and age
  const birthYear = currentYear - age;

  // Assuming a specific date for the birthdate (e.g., January 1st), you can create a date object
  const birthDate = dayjs(`${birthYear}-01-01`);

  return birthDate.format("YYYY-MM-DD");
}

const FormIndex = ({
  children,
  initialValues,
  validationSchema,
  mode,
  title,
  modal,
  dialog,
  handleOpen,
  handleClose,
  handleFormikValues,
  handleSubmit,
  editModeValues,
  additionalPropsToSubmit,
  submitRef,
  btnLabel,
  btnContainerSize,
  AccessYears,
  academicYear,
  saveButton,

  ...rest
}) => (
  <Formik
    initialValues={
      mode === "edit" ? { ...editModeValues } : { ...initialValues }
    }
    validationSchema={validationSchema}
    onSubmit={(values) => handleSubmit(values, additionalPropsToSubmit)}
    {...rest}
  >
    {({ values }) => {
      useEffect(() => {
        if (handleFormikValues) {
          handleFormikValues(values);
        }
      }, [values]);
      useEffect(() => {
        values.branch = "";
        values.batch = "";
        values.section = "";
        values.class = "";
      }, [academicYear?.code]);
      useEffect(() => {
        if (values?.branch && values?.branch?.branchType.includes("Day")) {
          values.admissionType = "Day scholars";
        }
        if (
          values?.branch &&
          values?.branch?.branchType.includes("Residential")
        ) {
          values.admissionType = "Residential";
        }
      }, [values.branch]);

      // useEffect(() => {
      //   if (mode !== "edit") {
      //     const classAges = {
      //       Nursery: 3,
      //       LKG: 4,
      //       UKG: 5,
      //       1: 6,
      //       2: 7,
      //       3: 8,
      //       4: 9,
      //       5: 10,
      //       6: 11,
      //       7: 12,
      //       8: 13,
      //       9: 14,
      //       10: 15,
      //       11: 16,
      //       12: 17,
      //     };
      //     const birthdates = {};
      //     for (const classYear in classAges) {
      //       if (Object.prototype.hasOwnProperty.call(classAges, classYear)) {
      //         const age = classAges[classYear];
      //         const birthDate = calculateDateOfBirth(classYear, age);
      //         birthdates[classYear] = birthDate;
      //       }
      //     }
      //     const classValue = values?.class?.value;
      //     values.dateOfBirth = birthdates?.[classValue];
      //   }
      // }, [values.class]);

      return (
        <Form ref={submitRef}>
          <Grid container spacing={1} paddingBottom={1}>
            {title ? (
              <Grid
                container
                item
                xs={11}
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Typography component="h1" fontWeight={600} fontSize={14}>
                  {`${mode === "edit" ? "Update" : "Add"} ${title}`}
                </Typography>
              </Grid>
            ) : null}

            <Grid
              container
              item
              xs={1}
              justifyContent="flex-end"
              alignContent="flex-end"
              alignItems="flex-end"
            >
              {modal ? (
                <IconButton onClick={handleOpen}>
                  <CancelIcon color="info" size="large" />
                </IconButton>
              ) : null}
              {dialog ? (
                <IconButton onClick={handleClose}>
                  <CancelIcon color="info" size="large" />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>

          <FormFieldsContainer>{children}</FormFieldsContainer>

          {!submitRef && saveButton !== "NO" ? (
            <Grid
              item
              xs={btnContainerSize || 12}
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              padding={1}
            >
              <SoftButton type="submit" color="info">
                {btnLabel || (mode === "create" ? "Save" : "Save")}
              </SoftButton>
            </Grid>
          ) : null}
        </Form>
      );
    }}
  </Formik>
);

export default FormIndex;
