import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { Box, Grid, Tooltip } from "@mui/material";
import { reportError } from "APIs/organization/ZoneServices";
import broken from "assets/broke.png";
import useFetch from "hooks/useFetch";
import SoftButton from "myComponents/SoftButton";
import SoftNotification from "myComponents/SoftNotification";
import SoftTypography from "myComponents/SoftTypography";
import { useEffect, useState } from "react";
import store from "store";
import ReplayIcon from "@mui/icons-material/Replay";

const ErrorFallback = ({ error }) => {
  const user = store.get("user");
  const errorMessage = {
    error: error.message,
    stack: error.stack,
  };
  const [isOpen, setOpen] = useState(false);

  const [isLoading, err, result, report, reset] = useFetch(reportError);

  const handleClick = () => {
    const redirectUrl = window.location.origin;
    if (
      navigator.userAgent.includes("Android") &&
      window.Android &&
      window.Android.navigateFromWebView
    ) {
      window.Android?.navigateFromWebView(redirectUrl);
    } else {
      window.location.replace(redirectUrl);
    }
  };
  const msgs = {
    error: err || "something went wrong.",
    success: "Issue reported successfully.",
    loading: "Loading please wait...",
  };
  const getFeedback = () => {
    if (isLoading) return { msg: msgs.loading, type: "info" };
    if (result) return { msg: msgs.success, type: "success" };
    return { msg: msgs.error, type: "error" };
  };
  useEffect(() => {
    if (isLoading) setOpen(true);
    return () => {
      reset();
    };
  }, [isLoading]);
  const handleError = () => {
    report({
      ...errorMessage,
      url: window.location.href,
      userId: user.userId,
      institute: user.instituteId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRetry = () => {
    if (
      navigator.userAgent.includes("Android") &&
      window.Android &&
      window.Android.navigateFromWebView
    ) {
      const path = window.location.href;
      window.Android?.navigateFromWebView(path);
    } else {
      window.location.reload();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="90vh"
      p={2}
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <img
            src={broken} // Add the path to your image
            alt="Error Illustration"
            style={{
              width: "100%",
              maxWidth: 150,
              height: "auto",
            }}
          />
        </Grid>
        <Grid item>
          <SoftTypography
            align="center"
            color="error"
            variant="h5"
            gutterBottom
          >
            Oops! Something went wrong.
          </SoftTypography>
          <SoftTypography align="center" variant="body2" paragraph>
            We&apos;re sorry, but it seems there was an error while loading the
            content.
          </SoftTypography>
        </Grid>
        <Grid item>
          <Tooltip title="Please try again. If the UI still fails to load, please report the issue.">
            <SoftButton
              size="small"
              variant="text"
              color="dark"
              onClick={handleRetry}
              startIcon={<ReplayIcon />}
            >
              Retry
            </SoftButton>
          </Tooltip>
        </Grid>
        {/* {!result && (
          <Tooltip
            title="Send crash report to the developer team."
            placement="top"
          >
            <Grid item>
              <SoftButton
                size="small"
                variant="outlined"
                color="error"
                disabled={result}
                onClick={handleError}
                startIcon={<BugReportIcon />}
              >
                Report this issue
              </SoftButton>
            </Grid>
          </Tooltip>
        )} */}
        <Grid item>
          <SoftButton
            size="small"
            variant="contained"
            color="info"
            onClick={handleClick}
            startIcon={<SpaceDashboardIcon />}
          >
            goto dashboard
          </SoftButton>
        </Grid>
      </Grid>
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
    </Box>
  );
};

export default ErrorFallback;
