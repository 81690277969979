import { takeLatest, all, call, put } from "redux-saga/effects";
// utils
import { create, fetch, update, fetchFile, remove } from "redux/app/app.utils";
// actions
import {
  setStudents,
  resetStudnetProcess,
  updateStdentLocal,
  deleteStdentLocal,
  setRegistrationParams,
  createStudentSuccess,
  studentBulkOpSuccess,
  fetchStudentBedStatusSuccess,
  branchTransferSuccess,
  applicationCommFetchSuccess,
  withdrawCommissionSuccess,
} from "./student.slice";
// redux store
import storeRedux from "../../store";
// // apis
import {
  createStudent,
  fetchStudent,
  updateStudent,
  deleteStudent,
  bulkUpload,
  bulkUpdate,
  updateMultipleStudents,
  fetchDoc,
  UploadStudentImage,
  fetchStudentBedStatus,
  studentsBranchTranser,
  fetchApplicationCommissionItems,
  withdrawComission,
  fetchEnrollmentRecords,
} from "../../../APIs/admissions/student";
// // utils
// import getTimeFromDate from "../../../utils/getTime";

const getUpdates = () => {
  const { student } = storeRedux.getState();
  const u = student.updates;
  return u;
};

export function* createStudentAsync({ payload }) {
  yield create({
    callName: "create_student",
    inputs: payload,
    api: createStudent,
    *handleRes(item) {
      yield put(createStudentSuccess(item));
    },
    resName: "savedStudent",
    needAdditionalInfo: true,
  });
}

export function* fetchStudentAsync({ payload }) {
  yield fetch({
    callName: "fetch student[s]",
    query: payload,
    targetPath: "result",
    api: fetchEnrollmentRecords,
    *handleRes(items) {
      yield put(setStudents(items));
      yield put(resetStudnetProcess());
    },
  });
}

// export function* fetchStudentBedStatusAsync({ payload }) {
//   yield fetch({
//     callName: "fetch student[s] Bed status ",
//     query: payload,
//     api: fetchStudentBedStatus,
//     *handleRes(items) {
//       console.log(items.result);
//       yield put(fetchStudentBedStatusSuccess(items.result));
//     },
//   });
// }
export function* fetchStudentBedStatusAsync({ payload }) {
  try {
    const items = yield call(fetchStudentBedStatus, payload);
    // console.log(items.result);
    yield put(fetchStudentBedStatusSuccess(items.result));
  } catch (error) {
    // Handle any errors here
    console.error(error);
    // You can also dispatch an error action if needed
    // yield put(fetchStudentBedStatusFailure(error));
  }
}
export function* bulkUploadStudentsAsync({ payload }) {
  yield create({
    callName: "bulk upload student[s]",
    inputs: payload,
    api: bulkUpload,
    *handleRes(res) {
      yield put(studentBulkOpSuccess(res.result));
      yield put(resetStudnetProcess());
    },
    needAdditionalInfo: true,
  });
}

export function* bulkUpdateStudentsAsync({ payload }) {
  yield create({
    callName: "bulk update student[s]",
    inputs: payload,
    api: bulkUpdate,
    *handleRes(res) {
      yield put(studentBulkOpSuccess(res.result));
      yield put(resetStudnetProcess());
    },
    needAdditionalInfo: true,
  });
}

export function* fetchStudentToChangeAsync({ payload }) {
  const { mode, phaseChange, ...rest } = payload;
  yield fetch({
    callName: "fetch student",
    query: { ...rest },
    api: fetchStudent,
    *handleRes(items) {
      yield put(
        setRegistrationParams({
          phase: payload.phase,
          mode,
          phaseChange,
          student: items[0],
        })
      );
    },
  });
}

export function* updateStudentAsync({ payload }) {
  yield update({
    callName: payload.phaseChange ? "phase_change" : "update_student",
    updates: payload,
    api:
      payload.avatar ||
      payload.applicationDoc ||
      payload.admissionDoc ||
      payload.mode
        ? UploadStudentImage
        : updateStudent,
    *handleRes(stu) {
      yield put(updateStdentLocal(stu));
    },
    updatedName: "updatedStudent",
    needAdditionalInfo: true,
  });
}

export function* deleteStudentAsync({ payload }) {
  yield remove({
    callName: "delete_student",
    id: payload,
    api: deleteStudent,
    *handleRes(stu) {
      yield put(deleteStdentLocal(stu));
    },
    deletedName: "deleteStudent",
  });
}
export function* assignCounsellorAsync() {
  const updates = yield getUpdates();
  yield update({
    callName: "assign counsellor",
    updates,
    api: updateMultipleStudents,
    handleRes: () => {},
    // updatedName: "updatedStudent",
  });
}

export function* fetchDocAsync({ payload }) {
  yield fetchFile({
    callName: "fetch doc",
    query: payload,
    api: fetchDoc,
  });
}

export function* branchTransferAsync({ payload }) {
  yield update({
    callName: "branch_transfer",
    updates: payload,
    api: studentsBranchTranser,
    *handleRes() {
      yield put(branchTransferSuccess());
    },
  });
}

export function* fetchApplicationCommissionItemsAsync({ payload }) {
  yield fetch({
    callName: "fetch_application_commission_items",
    query: payload,
    api: fetchApplicationCommissionItems,
    *handleRes(items) {
      yield put(applicationCommFetchSuccess(items));
    },
  });
}

export function* withdrawCommissionAsync({ payload }) {
  yield update({
    callName: "withdraw_commission",
    updates: payload,
    api: withdrawComission,
    *handleRes(items) {
      yield put(withdrawCommissionSuccess(items));
    },
  });
}

export function* onStudentCreationStart() {
  yield takeLatest("STUDENT/createStudentStart", createStudentAsync);
}
export function* onfetchStudentBedStatusStart() {
  yield takeLatest(
    "STUDENT/fetchStudentBedStatusStart",
    fetchStudentBedStatusAsync
  );
}
export function* onFetchStudentStart() {
  yield takeLatest("STUDENT/fetchStudent", fetchStudentAsync);
}

export function* onBulkUploadStart() {
  yield takeLatest("STUDENT/bulkUpload", bulkUploadStudentsAsync);
}

export function* onBulkUpdateStart() {
  yield takeLatest("STUDENT/bulkUpdate", bulkUpdateStudentsAsync);
}

export function* onStartPhaseChange() {
  yield takeLatest("STUDENT/startPhaseChange", fetchStudentToChangeAsync);
}

export function* onUpdateStudentStart() {
  yield takeLatest("STUDENT/updateStudentStart", updateStudentAsync);
}

export function* onDeleteStudentStart() {
  yield takeLatest("STUDENT/deleteStudentStart", deleteStudentAsync);
}
export function* onAssignCounsellorStart() {
  yield takeLatest("STUDENT/assignCounsellor", assignCounsellorAsync);
}

export function* onfetchDocStart() {
  yield takeLatest("STUDENT/fetchDoc", fetchDocAsync);
}

export function* onBranchTransferStudent() {
  yield takeLatest("STUDENT/branchTransferStart", branchTransferAsync);
}

export function* onfetchApplicationCommissionItems() {
  yield takeLatest(
    "STUDENT/startApplicationCommFetch",
    fetchApplicationCommissionItemsAsync
  );
}

export function* onwithdrawCommissionStart() {
  yield takeLatest("STUDENT/withdrawCommissionStart", withdrawCommissionAsync);
}

export default function* studentSaga() {
  yield all([
    call(onStudentCreationStart),
    call(onFetchStudentStart),
    call(onBulkUploadStart),
    call(onBulkUpdateStart),
    call(onStartPhaseChange),
    call(onUpdateStudentStart),
    call(onDeleteStudentStart),
    call(onAssignCounsellorStart),
    call(onfetchDocStart),
    call(onfetchStudentBedStatusStart),
    call(onBranchTransferStudent),
    call(onfetchApplicationCommissionItems),
    call(onwithdrawCommissionStart),
  ]);
}
