import get from "lodash/get";
import { put } from "redux-saga/effects";
// actions
import {
  setStartOrEndOfBackdropProcess,
  setProcessStatus,
  setInitializationErrors,
} from "./app.slice";
// selectors
// import { selectIsAppInitialized } from "./app.selectors";

function* setStatus(status) {
  yield put(setProcessStatus(status));
}

export function* create({
  callName,
  inputs,
  api,
  handleRes,
  processType,
  resName,
  showFeedback = true,
  needAdditionalInfo,
}) {
  const isBackdropProcess = !(processType === "noBackdrop");
  if (isBackdropProcess) {
    yield put(setStartOrEndOfBackdropProcess(true));
  }
  try {
    const res = yield api(inputs);
    if (res) {
      yield handleRes(resName ? get(res, resName) : res);
      const obj = {
        name: callName,
        message: res.message,
        result: "success",
        showFeedback,
      };
      if (needAdditionalInfo) {
        if (resName) obj.info = get(res, resName);
        else obj.info = res;
      }
      yield setStatus(obj);
    }
  } catch (error) {
    yield setStatus({
      name: callName,
      message: error.message,
      result: "error",
    });
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* fetch({
  callName,
  query,
  api,
  handleRes,
  showFeedback = true,
  targetPath = "result.docs",
  backdrop = true,
  initial = false,
  readLength = true,
}) {
  // app intialization fetch
  if (initial) {
    try {
      const res = yield api(query);
      if (res && res.message) {
        const items = get(res, targetPath);
        yield handleRes(items);
      }
    } catch (error) {
      yield put(setInitializationErrors({ [callName]: error }));
    }
  } else {
    try {
      if (backdrop) yield put(setStartOrEndOfBackdropProcess(true));
      const res = yield api(query);
      if (res && res.message) {
        const items = get(res, targetPath);
        yield handleRes(items);
        if (items.length === 0 && readLength)
          yield setStatus({
            name: callName,
            message: `No results`,
            result: "info",
            showFeedback,
          });
        else
          yield setStatus({
            name: callName,
            message: res.message,
            result: "success",
            showFeedback,
          });
      }
    } catch (error) {
      yield setStatus({
        name: callName,
        message: error.message,
        result: "error",
      });
    }
    if (backdrop) yield put(setStartOrEndOfBackdropProcess(false));
  }
}

export function* fetchFile({ callName, query, api }) {
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield api(query);
    if (res) {
      yield setStatus(callName, "document fetched successfully", "success");
      const binaryString = window.atob(res);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i += 1) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: "application/pdf" });
      // const title = `Form_${new Date().getTime()}.pdf`;
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    }
  } catch (error) {
    yield setStatus({
      name: callName,
      message: error.message,
      result: "error",
    });
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* update({
  callName,
  updates,
  api,
  handleRes,
  updatedName,
  needAdditionalInfo,
}) {
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield api(updates);
    if (res) {
      yield handleRes(res[updatedName || "result"]);
      const obj = {
        name: callName,
        message: res.message,
        result: "success",
      };
      if (needAdditionalInfo) {
        obj.info = res[updatedName || "result"];
      }
      yield setStatus(obj);
    }
  } catch (error) {
    yield setStatus({
      name: callName,
      message: error.message,
      result: "error",
    });
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

export function* remove({ callName, id, api, handleRes, deletedName }) {
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const res = yield api(id);
    if (res) {
      if (res[deletedName]) yield handleRes(res[deletedName || "result"]);
      else handleRes({ _id: id });
      yield setStatus({
        name: callName,
        message: res.message,
        result: "success",
      });
    }
  } catch (error) {
    yield setStatus({
      name: callName,
      message: error.message,
      result: "error",
    });
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}

function* fetchSingleQuery(q) {
  const { query, api, targetPath = "result.docs" } = q;
  const res = yield api(query);
  if (res && res.message) {
    const items = get(res, targetPath);
    return items;
  }
  return 0;
}

export function* fetchMulti({
  callName,
  query1,
  query2,
  showFeedback = true,
  handleRes,
  des,
}) {
  try {
    yield put(setStartOrEndOfBackdropProcess(true));
    const obj = {};
    if (query1) {
      obj.query1 = yield fetchSingleQuery(query1);
    }
    if (query2) {
      obj.query2 = yield fetchSingleQuery(query2);
    }
    const check = yield handleRes(obj);

    if (check?.success)
      yield setStatus({
        name: callName,
        message: `fetched ${des} successfully`,
        result: "success",
        showFeedback,
      });
    if (check?.info)
      yield setStatus({
        name: callName,
        message: check.message,
        result: check.result,
      });
  } catch (error) {
    console.log(error);
    yield setStatus({
      name: callName,
      message: error.message,
      result: "error",
    });
  }
  yield put(setStartOrEndOfBackdropProcess(false));
}
