import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import components from "./components";
import palette from "./palette";
import breakpoints from "./breakpoints";

const theme = createTheme({
  palette,
  components,
  breakpoints,
  typography: {
    button: {
      textTransform: "capitalize",
    },
  },
  shape: {
    borderRadius: 5,
  },
});

const appTheme = responsiveFontSizes(theme);

export default appTheme;
