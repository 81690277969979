// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme }) => {
  const { boxShadows, functions } = theme;
  const drawerWidth = 274;

  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  return {
    zIndex: 1200,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      height: "100vh",
      margin: 0,
      padding: `0 ${pxToRem(10)}`,
      borderRadius: 0,
      boxShadow: xxl,
      overflowY: "auto",
      boxSizing: "border-box",
      width: `${drawerWidth}px`,
    },
  };
});
