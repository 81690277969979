// this componet is to override defalut check box  styles in datagrid
import { forwardRef } from "react";
import Checkbox from "@mui/material/Checkbox";

const CheckboxWrapper = ({ icon, checkedIcon, iconColor }) =>
  forwardRef((props, ref) => (
    <Checkbox
      ref={ref}
      icon={icon}
      checkedIcon={checkedIcon}
      sx={{ color: iconColor }}
      {...props}
    />
  ));

export default CheckboxWrapper;
