import Grid from "@mui/material/Grid";

const FormFieldsContainer = ({ children, childIsFullWidth }) => (
  <Grid
    container
    direction="row"
    display="flex"
    spacing={2}
    columns={childIsFullWidth ? 12 : { xs: 2, sm: 4, md: 6, lg: 8 }}
  >
    {children}
  </Grid>
);

export default FormFieldsContainer;
