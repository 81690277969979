export const counsellors = [
  { _id: "6295c3a16d203518a5098f5a", name: "Ram", employeeId: "emp002" },
];
export const classNames = [
  "Nursery",
  "LKG",
  "UKG",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
export const sectionList = ["A", "B", "C", "D", "E", "F", "Other"];
export const admissionTypes = ["Day", "Residential"];
export const admissionToAcademicYear = [
  "Current Academic Year",
  "Next AcademicYear",
];
export const howtoknowaboutUs = ["Campus walk-in", "Through Website", "Others"];
export const enquiryStatus = ["open", "Intrested", "Not Interested"];
export const branchList = [
  { branchName: "alpha", _id: "62ee0a98ea845c119ae959e7", branchCode: "SB01" },
];
export const bloodGroupList = [
  "A+",
  "A-",
  "B",
  "B+",
  "B-",
  "AB+",
  "AB-",
  "O+",
  "O-",
];
export const nationalityList = ["Indian"];

export const motherTongueList = [
  "Telugu",
  "Hindi",
  "Rajasthani",
  "Bhojpuri",
  "Odia",
  "Sindhi",
  "Nepali",
  "Chhattisgarhi",
  "Sinhala",
  "Romani",
  "Bengali",
  "Maithili",
  "Nepalese",
  "Sanskrit",
  "Tamil",
  "Urdu",
  "Assamese",
  "Dogri",
  "Kannada",
  "Gujarati",
  "Bodo",
  "Manipur (also known as Meitei)",
  "Oriya",
  "Marathi",
  "Santali",
  "Punjabi",
  "Malayalam",
  "Konkani",
  "Kashmiri",
  "Marathi",
  "Mathura",
  "patkari",
];
export const casteList = ["SC", "BC", "OC", "OBC", "General"];
export const religionList = [
  "Hindus",
  "Muslims",
  "Christians",
  "Sikhs",
  "Buddhists",
  "Jains",
  "Other religions	",
];
export const documentList = [
  { id: 112, documentName: "Tc Certificate" },
  { id: 1, documentName: "BloodGroup Certificate" },
  { id: 2, documentName: "Health Certificate" },
  { id: 3, documentName: "Bonafied" },
  { id: 4, documentName: "Father Photograph" },
  { id: 5, documentName: "Mother Photograph" },
  { id: 6, documentName: "Guardian Authorization" },
  { id: 7, documentName: "Caste Certificate" },
  { id: 8, documentName: "Pancard" },
  { id: 9, documentName: "Intermediate Certificate" },
  { id: 10, documentName: "10th PassMarksSheet" },
  { id: 11, documentName: "Application Form" },
  { id: 12, documentName: "SchoolPolicy Certificate" },
  { id: 13, documentName: "Age Undertaking Certificate" },
  { id: 14, documentName: "Resource Form" },
  { id: 15, documentName: "Discount Form" },
  { id: 16, documentName: "Transport Policy" },
  { id: 17, documentName: "Address Proof" },
  { id: 18, documentName: "Parent Photo Release" },
  { id: 19, documentName: "Attested Caste Certificate" },
  { id: 20, documentName: "Attested Transfer" },
  { id: 21, documentName: "Attested Marks Card" },
  { id: 22, documentName: "Attested Address" },
  { id: 23, documentName: "Letter of Undertaking" },
  { id: 24, documentName: "Service Certificate" },
  { id: 25, documentName: "Child Certificate" },
  { id: 26, documentName: "Registration Card" },
  { id: 27, documentName: "Child Passport" },
  { id: 28, documentName: "Emiratesid" },
  { id: 29, documentName: "Father Passport" },
  { id: 30, documentName: "Insurance Certificate" },
  { id: 31, documentName: "Ration Card" },
  { id: 32, documentName: "Electricity Bill" },
  { id: 33, documentName: "Telephone Bill" },
  { id: 34, documentName: "Proof of Sibling" },
  { id: 35, documentName: "Photo Copy Ressibling" },
  { id: 36, documentName: "Alumnus" },
  { id: 37, documentName: "Mother Name Marriage" },
  { id: 38, documentName: "Marriage Certificate" },
  { id: 39, documentName: "Student Aadhar Card" },
  { id: 40, documentName: "Others Certificate" },
  { id: 41, documentName: "Migration Certificate" },
  { id: 42, documentName: "Re-Admission Form" },
  { id: 43, documentName: "Affidavit" },
  { id: 44, documentName: "CollegePolicy Certificate" },
  { id: 45, documentName: "Bachelor Degree Certificate" },
  { id: 46, documentName: "Bonafide Certificate" },
  { id: 47, documentName: "Father Aadhar Card" },
  { id: 48, documentName: "Mother Aadhar Card" },
  { id: 49, documentName: "10thAdmitCard" },
  { id: 50, documentName: "Parent Income Proof" },
  { id: 51, documentName: "Parent Occupation Proof" },
  { id: 52, documentName: "Student CBSE Registrationno Proof" },
  { id: 53, documentName: "Fee Document" },
  { id: 54, documentName: "Education Certificates of Parent" },
  { id: 55, documentName: "Adoption Certificate" },
  { id: 56, documentName: "Special Needs" },
  { id: 57, documentName: "University Application form" },
  { id: 58, documentName: "MidTermReports" },
  { id: 59, documentName: "TermReports" },
  { id: 60, documentName: "Teacher Remarks" },
  { id: 61, documentName: "Parent Letter" },
  { id: 62, documentName: "Social and Personality Traits" },
  { id: 63, documentName: "Outstanding Achievements" },
  { id: 64, documentName: "Disability" },
  { id: 65, documentName: "Pan Card Father" },
  { id: 66, documentName: "Pan Card Mother" },
  { id: 67, documentName: "Confession Letter" },
  { id: 68, documentName: "Letter of Reprimand" },
  { id: 69, documentName: "Miscellaneous" },
  { id: 70, documentName: "Student Enquiry Form" },
  { id: 71, documentName: "Induction Program" },
  { id: 72, documentName: "Essential Agreements" },
  { id: 73, documentName: "Latest Marks Card" },
  { id: 74, documentName: "Domicile Certificate" },
  { id: 75, documentName: "Payment Details" },
  { id: 76, documentName: "Online admission form Part I" },
  { id: 77, documentName: "Online admission form Part II" },
  { id: 78, documentName: "Physically handicapped certificate(If applicable)" },
  { id: 79, documentName: "Student Signature" },
  { id: 80, documentName: "Leaving Certificate" },
  { id: 81, documentName: "Educational Certificate of Father" },
  { id: 82, documentName: "Educational Certificate of Mother" },
  { id: 83, documentName: "Mother Passport" },
  { id: 84, documentName: "Gazette copy" },
  { id: 85, documentName: "Declaration form" },
  { id: 86, documentName: "Friends from KK" },
  {
    id: 87,
    documentName:
      "Baptism certificate or Priest Letter (Christian Minority) (only if applicable)",
  },
  { id: 88, documentName: "Proof of Alumni if parent is Alumni of School" },
  { id: 89, documentName: "9th Class Report Card" },
  { id: 90, documentName: "Subject Option Selection Document" },
  { id: 91, documentName: "Consolidated Marks Memo" },
  { id: 92, documentName: "Provisional Certificate" },
  { id: 93, documentName: "Original Degree" },
  { id: 94, documentName: "RCD Declaration form" },
  { id: 95, documentName: "SSC Declaration form" },
  { id: 96, documentName: "Father Resident Card" },
  { id: 97, documentName: "Mother Resident Card" },
  { id: 98, documentName: "Father Visa" },
  { id: 99, documentName: "Mother Visa" },
  { id: 100, documentName: "Vaccination Report" },
  { id: 101, documentName: "Medical Records" },
  { id: 102, documentName: "Guardian Photograph" },
  { id: 103, documentName: "Father Visa" },
  { id: 104, documentName: "Mother Visa" },
  { id: 105, documentName: "Vaccination Report" },
  { id: 106, documentName: "Medical Records" },
  { id: 107, documentName: "Guardian Photograph" },
  { id: 108, documentName: "Parent Signature" },
  { id: 109, documentName: "Application Form" },
  { id: 110, documentName: "Mother Passbook" },
  { id: 111, documentName: "Community Certificate" },
];
export const SelecteddocumentList = [
  { id: 1, documentName: "BloodGroup Certificate" },
  { id: 2, documentName: "Health Certificate" },
  { id: 3, documentName: "Bonafied" },
  { id: 4, documentName: "Father Photograph" },
  { id: 5, documentName: "Mother Photograph" },
  { id: 6, documentName: "Guardian Authorization" },
  { id: 7, documentName: "Caste Certificate" },
  { id: 8, documentName: "Pancard" },
  { id: 9, documentName: "Intermediate Certificate" },
  { id: 10, documentName: "10th PassMarksSheet" },
  { id: 11, documentName: "Application Form" },
  { id: 12, documentName: "SchoolPolicy Certificate" },
  { id: 13, documentName: "Age Undertaking Certificate" },
  { id: 14, documentName: "Resource Form" },
  { id: 15, documentName: "Discount Form" },
  { id: 16, documentName: "Transport Policy" },
  { id: 17, documentName: "Address Proof" },
  { id: 18, documentName: "Parent Photo Release" },
  { id: 19, documentName: "Attested Caste Certificate" },
  { id: 20, documentName: "Attested Transfer" },
  { id: 21, documentName: "Attested Marks Card" },
  { id: 22, documentName: "Attested Address" },
  { id: 23, documentName: "Letter of Undertaking" },
  { id: 24, documentName: "Service Certificate" },
  { id: 25, documentName: "Child Certificate" },
  { id: 26, documentName: "Registration Card" },
  { id: 27, documentName: "Child Passport" },
  { id: 28, documentName: "Emiratesid" },
  { id: 29, documentName: "Father Passport" },
  { id: 30, documentName: "Insurance Certificate" },
  { id: 31, documentName: "Ration Card" },
  { id: 32, documentName: "Electricity Bill" },
  { id: 33, documentName: "Telephone Bill" },
  { id: 34, documentName: "Proof of Sibling" },
  { id: 35, documentName: "Photo Copy Ressibling" },
];
