// mui
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { styled } from "@mui/system";
// config
import { RELOCATE } from "config/globalConsts";
import logoURLMap from "layout/primeLayout/logoURLMap";

const StyledContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f9f9f9",
  textAlign: "center",
});

const StyledBox = styled(Box)({
  backgroundColor: "#fff",
  padding: "30px",
  borderRadius: "10px",
  boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
  maxWidth: "500px",
});

const SiteRelocationNotice = () => {
  const newLocation = RELOCATE[window.location.hostname];

  return (
    <>
      {/* <CssBaseline /> */}
      <StyledContainer>
        <StyledBox>
          <img
            src={logoURLMap["5d679d49c136660a09596d85"].url}
            alt="Company Logo"
            style={{ width: "150px", marginBottom: "20px" }}
          />
          <Typography variant="h4" component="h1" gutterBottom>
            Site Relocation Notice
          </Typography>
          <Typography variant="body1" gutterBottom color="ThreeDShadow">
            We have moved our site to a new address to better serve you. Please
            update your bookmarks and visit us at our new location.
          </Typography>
          <Link
            href={newLocation}
            variant="body1"
            underline="hover"
            color="purple"
          >
            Go to New Site
          </Link>
        </StyledBox>
      </StyledContainer>
    </>
  );
};

export default SiteRelocationNotice;
