import baseAxios from "../baseAxios";

const COMMON = "/organization/op";

export async function checkIfBranchOrBatchOrSectionIsRemovable(obj = {}) {
  try {
    return (
      await baseAxios.get(`${COMMON}/check_p`, {
        params: { ...obj },
        timeout: 1000 * 3,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchApplicationLogs(obj = {}) {
  try {
    return (
      await baseAxios.get(COMMON, {
        params: { ...obj },
        timeout: 1000 * 10,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function checkIfStudentsHasTransactionsOnBranch(obj) {
  try {
    return (
      await baseAxios.post("finance/txn/check_r", obj, {
        timeout: 1000 * 10,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postOperation(obj = {}) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function txnRevertNotificationParams(obj = {}) {
  try {
    return (
      await baseAxios.get(`${COMMON}/op-params-txn-revert`, { params: obj })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchSubscribedOperations(obj = {}) {
  try {
    return (await baseAxios.get(`${COMMON}/my`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateReadBy(obj) {
  try {
    return (await baseAxios.put(`${COMMON}/readBy`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function archiveNotification(obj) {
  try {
    return (await baseAxios.put(`${COMMON}/archive`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
