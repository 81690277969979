const DB_NAME = "ONESAZ";
const DB_VERSION = 1;
const EXAMS_STORE = "exams_store";
let db;

// export const openDb = () => {
//   console.log("openDb ...");
//   const req = indexedDB.open(DB_NAME, DB_VERSION);

//   req.onsuccess = (evt) => {
//     db = evt.target.result;
//     console.log("openDb DONE");
//   };

//   req.onerror = (evt) => {
//     console.error("openDb:", evt.target.errorCode);
//   };

//   req.onupgradeneeded = (evt) => {
//     console.log("openDb.onupgradeneeded");
//     db = evt.target.result;

//     db.createObjectStore(EXAMS_STORE, {
//       keyPath: "test_name",
//     });
//   };
// };

export const openDb = () =>
  new Promise((resolve, reject) => {
    console.log("==========================================");
    console.log("==       INITIALIZING INDEXEDDB ...     ==");
    const req = indexedDB.open(DB_NAME, DB_VERSION);

    req.onsuccess = (evt) => {
      db = evt.target.result;
      console.log("==                DONE                  ==");
      console.log("==========================================");
      resolve();
    };

    req.onerror = (evt) => {
      console.error("openDb:", evt.target.errorCode);
      reject(evt.target.errorCode);
    };

    req.onupgradeneeded = (evt) => {
      console.log("==             DONE UPGRADED            ==");
      console.log("==========================================");
      db = evt.target.result;
      db.createObjectStore(EXAMS_STORE, {
        keyPath: "test_name",
      });
      // resolve();
    };
  });

export const getDbPromise = () =>
  new Promise((resolve, reject) => {
    const req = indexedDB.open(DB_NAME, DB_VERSION);
    req.onsuccess = (evt) => {
      resolve(evt.target.result);
    };

    req.onerror = (evt) => {
      console.error("openDb:", evt.target.errorCode);
      reject(evt.target.errorCode);
    };

    req.onupgradeneeded = (evt) => {
      db = evt.target.result;
      db.createObjectStore(EXAMS_STORE, {
        keyPath: "test_name",
      });
      resolve(db);
    };
  });

export const getObjectStore = (storeName, mode) =>
  db?.transaction([storeName], mode)?.objectStore(storeName);

export const retrieveExamsList = () =>
  new Promise((resolve, reject) => {
    const store = getObjectStore(EXAMS_STORE, "readonly");
    if (store) {
      const req = store.getAll();
      req.onsuccess = (e) => resolve(e.target.result);
      req.onerror = (e) =>
        console.error("Error retrieving data:", e.target.error);
    } else {
      reject(new Error("Error removing exams: Store not found"));
    }
  });

export const removeAllExams = () =>
  new Promise((resolve, reject) => {
    const store = getObjectStore(EXAMS_STORE, "readwrite");
    if (store) {
      const req = store.clear();
      req.onsuccess = () => resolve("All exams removed successfully");
      req.onerror = (e) => {
        console.error("Error removing exams:", e.target.error);
        reject(new Error(`Error removing exams: ${e.target.error}`));
      };
    } else {
      reject(new Error("Error removing exams: Store not found"));
    }
  });

export const updateSingleExam = (item) =>
  new Promise((resolve, reject) => {
    const store = getObjectStore(EXAMS_STORE, "readwrite");
    if (store) {
      const updateReq = store.put(item);
      updateReq.onerror = (e) => {
        reject(e.target.errorCode);
      };
      updateReq.onsuccess = () => {
        resolve(`${item.test_name} updated`);
      };
    } else {
      reject(new Error("Error removing exams: Store not found"));
    }
  });

export const addExamsListToStore = async (list) => {
  try {
    const promises = list.map((item) => updateSingleExam(item));
    return await Promise.all(promises);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
