import { useState } from "react";
// material ui
import { Dialog, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
// my components
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
// components
import ActiveStatus from "components/Active";
import { Close } from "@mui/icons-material";
import SoftTypography from "myComponents/SoftTypography";
import Transition from "./transition";

const DialogPage = ({
  content,
  Component,
  componentProps,
  linkProps,
  buttonProps,
  iconButton,
  fullScreen,
  headerName,
  title = null,
  link,
  chipProps,
  chipLabel,
  label,
  labelWithButtonProps,
  icon,
  endIconButtonProps,
  maxWidth,
  width,
  height,
  inputProps,
  customButtonProps,
  iconProps,
  startIconButtonProps,
  displayProps,
  ifPendingApprovals = false,
  noAppBar,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  // useEffect(() => {
  //   console.log(open);
  // }, [open]);
  return (
    <SoftBox component="section">
      {displayProps ? (
        <ActiveStatus {...displayProps} handleClick={handleOpen} />
      ) : null}
      {iconProps ? (
        <IconButton
          {...iconProps}
          disabled={ifPendingApprovals}
          onClick={handleOpen}
        >
          {icon}
        </IconButton>
      ) : null}
      {/* <Container> */}
      {inputProps ? (
        <input {...inputProps} value={content} onClick={handleOpen} />
      ) : null}
      {linkProps ? (
        <Link href={link} {...linkProps} onClick={handleOpen}>
          {content}
        </Link>
      ) : null}
      {buttonProps ? (
        <SoftButton {...buttonProps} onClick={handleOpen}>
          {content}
        </SoftButton>
      ) : null}
      {startIconButtonProps ? (
        <SoftButton
          size="small"
          onClick={handleOpen}
          startIcon={icon}
          sx={{ maxWidth: "120px", maxHeight: "20px", fontSize: "10px" }}
          {...startIconButtonProps}
        >
          {content}
        </SoftButton>
      ) : null}
      {endIconButtonProps ? (
        <SoftButton
          size="small"
          onClick={handleOpen}
          iconOnly
          // sx={{
          //   color: "#0C6688",
          //   fontSize: "12px",
          //   // background: "white",
          // }}
          // endIcon={icon}
          {...endIconButtonProps}
        >
          {content || ""}
          {icon}
        </SoftButton>
      ) : null}
      {labelWithButtonProps ? (
        <Stack direction="row" justifyContent="center">
          <Box
            elevation={0}
            sx={{
              ...labelWithButtonProps,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "1.7rem",
              width: "1.7rem",
              borderRadius: "50%",
            }}
          >
            {label}
          </Box>
          <Box>
            <Button
              onClick={handleOpen}
              sx={{
                fontSize: "11px",
                color: (theme) => theme.palette.text.dark,
              }}
            >
              {content}
            </Button>
          </Box>
        </Stack>
      ) : null}
      {iconButton ? (
        <Button
          variant="outlined"
          startIcon={<LocalPrintshopIcon />}
          sx={{
            background: (theme) => theme.palette.background.grey,
            color: (theme) => theme.palette.text.dark,
            border: "none",
          }}
          onClick={handleOpen}
        >
          {content}
        </Button>
      ) : null}
      {customButtonProps ? (
        <SoftButton {...customButtonProps} onClick={handleOpen}>
          {content}
        </SoftButton>
      ) : null}
      {chipProps ? (
        <Chip
          label={chipLabel}
          // color="info"
          icon={icon}
          onClick={handleOpen}
          sx={{
            ...chipProps,
          }}
        />
      ) : null}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="create-user-modal"
        aria-describedby="create-user-modal"
        TransitionComponent={Transition}
        PaperProps={{ sx: { maxWidth, width, height } }}
      >
        <Box>
          {fullScreen && noAppBar && (
            <Grid
              sx={{ backgroundColor: "#3BBDED" }}
              container
              direction="row"
              justifyContent="Space-between"
              alignItems="center"
              pl={3}
              pr={3}
              pt={1}
              pb={1}
            >
              <Grid item>
                {title && (
                  <SoftTypography variant="h6" fontWeight="bold">
                    {title}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Component
            handleClose={handleClose}
            setOpen={setOpen}
            fade={open}
            headerName={headerName}
            {...componentProps}
          />
        </Box>
      </Dialog>
      {/* </Container> */}
    </SoftBox>
  );
};

export default DialogPage;
