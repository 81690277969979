import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
import store from "store";
// components
import Spinner from "components/Spinner";
// config
import routesConfig from "config/routesConfig";
// selectors
import { selectIsAppInitialized } from "redux/app/app.selectors";
import {
  selectPrivilegesOfRoles,
  selectAttendanceConfig,
} from "redux/settings/settings.selector";
// layout
import PrimeLayout from "layout/primeLayout";
// data
import AllowedRoutes from "./AllowedRoutes";
// config

const ChairmanDashBoard = lazy(() =>
  import("../views/Organization/ChairmanDashBoard")
);

const PrivateRoutes = () => {
  const user = store.get("user");
  const token = store.get("accessToken");
  const dispatch = useDispatch();
  const isAppInitialized = useSelector(selectIsAppInitialized);
  const privileges = useSelector(selectPrivilegesOfRoles);
  const attendanceConf = useSelector(selectAttendanceConfig);
  const isAuthenticated = user;
  const notJustSignedUp = token;
  const location = useLocation();

  const handleElement = () => {
    if (notJustSignedUp) return <PrimeLayout />;
    if (!isAuthenticated)
      return <Navigate to="/sign-in" state={{ path: location.pathname }} s />;
    return <Navigate to="/change-password" />;
  };

  useEffect(() => {
    if (!isAppInitialized && !!isAuthenticated && !!notJustSignedUp) {
      dispatch({ type: "START_APP_INITIALIZATION" });
    }
  }, [isAppInitialized, !!isAuthenticated, notJustSignedUp]);

  return (
    <Route path="app" element={handleElement()}>
      <Route
        index
        element={
          <Suspense fallback={<Spinner />}>
            <ChairmanDashBoard />
          </Suspense>
        }
      />

      {AllowedRoutes(
        routesConfig,
        isAppInitialized,
        privileges,
        attendanceConf
      )}
    </Route>
  );
};

export default PrivateRoutes;
