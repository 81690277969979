import { createSlice } from "@reduxjs/toolkit";
// utils
import {
  getUpdatedList,
  getUpdateListOfMulti,
} from "../../../utils/general.utils/array.utils";

const initialState = {
  updates: {},
  students: [],
  bulkData: {},
  regParams: {},
  txnHistory: null,
  bulkOpSummary: {},
  studentBedStatus: [],
  application_commissions: [],
};
const studentSlice = createSlice({
  name: "STUDENT",
  initialState,
  reducers: {
    setRegistrationParams(state, action) {
      state.regParams = action.payload;
    },
    resetStudnetProcess(state) {
      state.updates = "";
      state.bulkData = "";
    },
    createStudentSuccess(state, action) {
      state.students = [...state.students, action.payload];
    },
    fetchStudentBedStatusSuccess(state, action) {
      state.studentBedStatus = action.payload;
    },
    createStudentStart() {},

    updateStudentStart() {},
    deleteStudentStart() {},
    fetchStudentToChangePhase() {},
    fetchStudent() {},
    fetchDoc() {},
    fetchStudentBedStatusStart() {},
    setStudents(state, action) {
      state.students = action.payload;
    },
    updateStdentLocal(state, action) {
      const list = state.students;
      state.students = getUpdatedList(action.payload, list);
    },
    deleteStdentLocal(state, action) {
      const list = state.students;
      state.students = getUpdatedList(action.payload, list);
    },
    bulkUpload() {},
    bulkUpdate() {},
    studentBulkOpSuccess(state, action) {
      state.bulkOpSummary = action.payload;
    },
    clearStudents(state) {
      state.students = [];
    },
    clearBulkOpSummary(state) {
      state.bulkOpSummary = {};
    },
    startPhaseChange() {},
    assignCounsellor(state, action) {
      state.updates = action.payload;
    },
    setAdmissionTxnHistory(state, action) {
      state.txnHistory = action.payload;
    },
    branchTransferStart() {},
    branchTransferSuccess(state) {
      state.students = [];
    },
    startApplicationCommFetch() {},
    applicationCommFetchSuccess(state, action) {
      state.application_commissions = action.payload;
    },
    clearApplicationsCommisions(state) {
      state.application_commissions = [];
    },
    withdrawCommissionStart() {},
    withdrawCommissionSuccess(state, action) {
      const list = state.application_commissions;
      state.application_commissions = getUpdatedList(action.payload, list);
    },
    updateMultipleStudentsInlocal(state, action) {
      const list = state.students;
      const updateList = action.payload;
      state.students = getUpdateListOfMulti(updateList, list);
    },
  },
});

export const {
  createStudent,
  updateStudent,
  fetchStudent,
  setStudents,
  resetStudnetProcess,
  bulkUpdate,
  bulkUpload,
  startPhaseChange,
  assignCounsellor,
  updateStdentLocal,
  deleteStdentLocal,
  fetchDoc,
  createStudentSuccess,
  setRegistrationParams,
  createStudentStart,
  updateStudentStart,
  deleteStudentStart,
  setAdmissionTxnHistory,
  studentBulkOpSuccess,
  clearBulkOpSummary,
  clearStudents,
  fetchStudentBedStatusStart,
  fetchStudentBedStatusSuccess,
  branchTransferStart,
  branchTransferSuccess,
  startApplicationCommFetch,
  applicationCommFetchSuccess,
  clearApplicationsCommisions,
  withdrawCommissionStart,
  withdrawCommissionSuccess,
  updateMultipleStudentsInlocal,
} = studentSlice.actions;

export default studentSlice.reducer;
