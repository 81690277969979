import store from "store";
import baseAxios from "../baseAxios";

export async function createAcademicYear(body) {
  try {
    return (await baseAxios.post("/organization/academic-years", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAcademicYears(id, params) {
  const path = id
    ? `/organization/academic-years/${id}`
    : "/organization/academic-years";
  try {
    return (
      await baseAxios.get(path, {
        params: { ...params, instituteId: store.get("instituteId") },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatAcademicYear(id, body) {
  try {
    return (await baseAxios.patch(`/organization/academic-years/${id}`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
