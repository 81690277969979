import store from "store";
import { Navigate } from "react-router-dom";

const LoginRoute = ({ children }) => {
  const token = store.get("accessToken");
  if (token) return <Navigate to="/app" />;
  return children;
};

export default LoginRoute;
