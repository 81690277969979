import { TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SoftBox from "myComponents/SoftBox";

const MPINInput = ({ onMPINChange, title = "", ifMatching }) => {
  const [mpin, setMPIN] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, e) => {
    const { value } = e.target;
    if (value.length === 1) {
      setMPIN((prevMPIN) => {
        const newMPIN = [...prevMPIN];
        newMPIN[index] = value;
        return newMPIN;
      });

      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index >= 0) {
      setMPIN((prevMPIN) => {
        const newMPIN = [...prevMPIN];
        newMPIN[index] = "";
        return newMPIN;
      });

      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  useEffect(() => {
    const enteredMPIN = mpin.join("");
    onMPINChange(enteredMPIN);
  }, [mpin, onMPINChange]);

  return (
    <SoftBox pt={1}>
      <Typography pb={1} pt={1} variant="caption" component="p">
        <b>{title}</b>
      </Typography>
      {mpin.map((digit, index) => (
        <TextField
          key={index}
          inputRef={inputRefs[index]}
          variant="outlined"
          type="password"
          value={digit}
          onChange={(e) => handleInputChange(index, e)}
          onKeyDown={(e) => handleBackspace(index, e)}
          autoFocus={index === 0}
          style={{ width: "3.5rem", marginRight: "1rem" }}
        />
      ))}
      &nbsp;
      {ifMatching === "matched" && (
        <CheckCircleIcon
          sx={{
            verticalAlign: "-webkit-baseline-middle",
            color: (theme) => theme.palette.primary.main,
          }}
          fontSize="medium"
        />
      )}
      {ifMatching === "notMatched" && (
        <CancelIcon
          sx={{
            verticalAlign: "-webkit-baseline-middle",
            color: (theme) => theme.palette.error.main,
          }}
          fontSize="medium"
        />
      )}
    </SoftBox>
  );
};

export default MPINInput;
