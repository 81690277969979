import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textFieldStyles: {
    "& .MuiFilledInput-root": {
      // border: "0.0625rem solid #d2d6da",
      // overflow: "hidden",
      // borderRadius: 4,
      // transition: theme.transitions?.create([
      //   "border-color",
      //   "background-color",
      //   "box-shadow",
      // ]),
      "&:hover": {
        // backgroundColor: theme.palette?.info?.main,
      },
      "&.Mui-focused": {
        // backgroundColor: theme.palette?.info.main,
        // // boxShadow: `${alpha(theme.palette?.info.main, 0.25)} 0 0 0 2px`,
        // borderColor: theme.palette?.info.main,
      },
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        // borderColor: theme.palette?.info.main,
        // background: theme.palette?.info.lightest,
      },
      "& fieldset": {
        // borderColor: theme.palette.divider,
      },
      "&.Mui-focused": {
        // backgroundColor: "transparent",
        // boxShadow: `${alpha(theme.palette?.info.main, 0.25)} 0 0 0 3px`,
        // borderColor: theme.palette?.info.main,
      },
      "&.Mui-focused fieldset": {
        // - Set the Input border when parent is focused
        // borderColor: theme.palette?.info.main,
        // background: theme.palette?.info.lightest,
        // color: theme.palette?.info.main,
      },
    },
  },
  resize: {
    fontSize: 50,
  },
}));
export default useStyles;
