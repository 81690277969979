export function subscribe(eventClass, handler) {
  const sugar = function sugar(event) {
    handler(...event.detail);
  };
  document.addEventListener(eventClass.name, sugar, { passive: true });
  return {
    unsubscribe: function unsubscribe() {
      document.removeEventListener(eventClass.name, sugar);
    },
  };
}

export function publish(event) {
  const nativeEvent = new CustomEvent(event.constructor.name, {
    detail: event.args,
  });
  document.dispatchEvent(nativeEvent);
}
