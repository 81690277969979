import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "dashboard";
export default async function fetchSummaryList(obj) {
  const { id, ...rest } = obj;
  const path = id ? `dashboard/${id}` : "dashboard";
  try {
    const { data } = await baseAxios.get(path, {
      params: { ...rest, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBasicSummaryList(obj) {
  try {
    const { data } = await baseAxios.get(`${COMMON}/summary`, {
      params: { ...obj, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchDescSummaryList(obj) {
  try {
    const { data } = await baseAxios.get(`${COMMON}/desc`, {
      params: { ...obj, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
// export async function fetchAttenSummaryList(obj) {
//   const path = "/academics/attendance/dashboard-summary";
//   try {
//     const { data } = await baseAxios.get(path, {
//       params: obj,
//     });
//     return data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }
// export async function fetchAdmissionsSummaryList(obj) {
//   const path = "/academics/attendance/dashboard-summary";
//   try {
//     const { data } = await baseAxios.get(path, {
//       params: obj,
//     });
//     return data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }
