import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  device: [],
};
const deviceSlice = createSlice({
  name: "BM_DEVICE_DETAILS",
  initialState,
  reducers: {
    fetchAttendanceDeviceStart() {},
    fetchAttendanceDeviceSuccess(state, action) {
      state.device = action.payload;
    },
    deleteAttendanceDeviceStart() {},
    deleteAttendanceDeviceSuccess(state, action) {
      state.device = action.payload;
    },
    saveAttendanceDeviceStart() {},
    saveAttendanceDeviceSuccess(state, action) {
      state.device = [...state.device, action.payload];
    },
    updateAttendanceDeviceStart() {},
    updateAttendanceDeviceSuccesss(state, action) {
      state.device = getUpdatedList(action.payload, state.device);
    },
  },
});

export const {
  fetchAttendanceDeviceStart,
  fetchAttendanceDeviceSuccess,
  saveAttendanceDeviceStart,
  saveAttendanceDeviceSuccess,
  updateAttendanceDeviceStart,
  updateAttendanceDeviceSuccesss,
  deleteAttendanceDeviceSuccess,
  deleteAttendanceDeviceStart,
} = deviceSlice.actions;

export default deviceSlice.reducer;
