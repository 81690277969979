import baseAxios from "APIs/baseAxios";
import lambda from "APIs/lambdaConfig";

const COMMON = "/academics/timetable";

export async function generateTimeTable(body) {
  try {
    const params = {
      FunctionName: "generateTimetable",
      Payload: JSON.stringify(body),
    };
    const res = await lambda.invoke(params).promise();
    return JSON.parse(res.Payload);
  } catch (error) {
    return error;
  }
}

export async function createTimeTable(obj) {
  try {
    return (await baseAxios.post(COMMON, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchTimeTables(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTimeTable(obj) {
  const { _id, ...rest } = obj;
  const path = _id ? `${COMMON}/${_id}` : COMMON;
  try {
    return (await baseAxios.patch(path, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteTimeTable(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.delete(path, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function autoGenerateTimeTable(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/auto`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
