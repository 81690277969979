import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// formik
import { useFormik } from "formik";
// @mui material myComponents
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// Soft UI Dashboard React myComponents
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import SoftInput from "myComponents/SoftInput";
import SoftButton from "myComponents/SoftButton";
import SoftNotification from "myComponents/SoftNotification";
// Authentication layout myComponents
import CoverLayout from "layout/LayoutComponents/CoverLayout";
// password valiation
// custom hook
import useFetch from "hooks/useFetch";
// Images
import curved14 from "assets/images/curved-images/curved-6.jpg";
// password validation
import changepasswordSchema from "../../validation/changePasswordSchema";
// apis
import { changeMyPassword } from "../../APIs/UserServices";

function ChangePassword() {
  const navigateTo = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isAlertOpen, setIfAlertOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  // const [count, setCount] = useState(0);

  const [isChanging, error, response, changePassword, resetFetch] =
    useFetch(changeMyPassword);

  const handleSubmit = (credentials) => {
    const body = {};
    body.oldPassword = credentials.password;
    body.newPassword = credentials.newPassword;
    changePassword(body);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleAlertClose = () => {
    resetFetch();
    setIfAlertOpen(false);
  };

  // initialize formik and set initial values
  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changepasswordSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (error) {
      setFeedback(error || response.message);
      setIfAlertOpen(true);
    }
    if (response) {
      navigateTo("/sign-in", {
        replace: true,
        state: { path: location.pathname },
      });
    }
  }, [error, response]);

  useEffect(() => {
    setFeedback("change password with old password");
    setIfAlertOpen(true);
  }, []);

  const msgs = {
    start: "Change your password",
    chainging: "Changing Password Please wait",
    error: error || "something went wrong...",
    success: "Password Changed Successfully",
    expired: "Token expired, Login again",
    logingIn: "Logging In Pleas wait...",
  };

  const getFeedback = () => {
    if (isChanging) return { msg: msgs.chainging, type: "info" };
    if (response) return { msg: msgs.success, type: "success" };
    if (error) return { msg: msgs.error, type: "error" };
    return { msg: msgs.start, type: "info" };
  };

  return (
    <CoverLayout
      title="Change Password"
      description="Enter Old and new passwords"
      image={curved14}
    >
      <SoftNotification
        open={isAlertOpen}
        close={handleAlertClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
      <Card>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Old Password
                </SoftTypography>
              </SoftBox>
              <SoftInput
                name="password"
                type="text"
                placeholder="old password"
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                onChange={formik.handleChange}
              />
              {formik.touched.password && Boolean(formik.errors.password) ? (
                <SoftTypography
                  variant="caption"
                  color={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                >
                  {formik.errors.password}
                </SoftTypography>
              ) : null}
            </SoftBox>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  New Password
                </SoftTypography>
              </SoftBox>
              <SoftInput
                name="newPassword"
                type="password"
                placeholder="Password"
                value={formik.values.newPassword}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                onChange={formik.handleChange}
              />
              {formik.touched.newPassword &&
              Boolean(formik.errors.newPassword) ? (
                <SoftTypography
                  variant="caption"
                  color={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                >
                  {formik.errors.newPassword}
                </SoftTypography>
              ) : null}
            </SoftBox>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Confirm New Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              name="confirmPassword"
              type="password"
              placeholder="Password"
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              onChange={formik.handleChange}
            />
            {formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword) ? (
              <SoftTypography
                variant="caption"
                color={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
              >
                {formik.errors.confirmPassword}
              </SoftTypography>
            ) : null}

            {/* <SoftBox display="flex" alignItems="center">
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Remember me
            </SoftTypography>
          </SoftBox> */}
            <SoftBox mt={4} mb={1}>
              <SoftButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </CoverLayout>
  );
}

export default ChangePassword;
