import { useSelector } from "react-redux";
import storeRedux from "./store";

export default function mySelector(path1, path2) {
  const value = useSelector((state) => {
    const val = state[path1][path2];
    return val;
  });

  return value;
}

export function getState() {
  const state = storeRedux.getState();
  return state;
}
