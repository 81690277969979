import { takeLatest, put, all, call } from "redux-saga/effects";
// apis
import {
  createSequence,
  updateSequences,
  fetchSequences,
} from "APIs/admissions/sequence";
// actions
import {
  createSequenceSuccess,
  updateSequenceSuccess,
  fetchSequenceSuccess,
} from "./sequence.slice";
// utils
import { fetch, update, create } from "../../app/app.utils";

export function* createSequenceAsync({ payload }) {
  yield create({
    callName: "create_sequence",
    inputs: payload,
    api: createSequence,
    *handleRes(item) {
      yield put(createSequenceSuccess(item));
    },
    resName: "saved",
  });
}

export function* fetchSequencesAsync({ payload }) {
  yield fetch({
    callName: "fetch_sequence",
    query: payload,
    api: fetchSequences,
    *handleRes(items) {
      yield put(fetchSequenceSuccess(items));
    },
  });
}

export function* updateSequenceAsync({ payload }) {
  yield update({
    callName: "update_sequence",
    updates: payload,
    api: updateSequences,
    *handleRes(item) {
      yield put(updateSequenceSuccess(item));
    },
    updatedName: "updated",
  });
}

export function* onUpdateSequenceStart() {
  yield takeLatest("SEQUENCE/updateSequenceStart", updateSequenceAsync);
}

export function* onFetchSequnceStart() {
  yield takeLatest("SEQUENCE/fetchSequenceStart", fetchSequencesAsync);
}

export function* onCreateSequenceStart() {
  yield takeLatest("SEQUENCE/createSequenceStart", createSequenceAsync);
}

export default function* sequenceSaga() {
  yield all([
    call(onUpdateSequenceStart),
    call(onFetchSequnceStart),
    call(onCreateSequenceStart),
  ]);
}
