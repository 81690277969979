import { createSlice } from "@reduxjs/toolkit";
// utils
// import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  params: {},
  attendance: [],
  students: [],
  stuffToSave: [],
  saved: null,
  smsReport: null,
  report: null,
  summary: null,
  result: null,
  daily_att: null,
  sms_tasks: null,
  punches_report: null,
  single_report: null,
  single_man_report: null,
  cumulative_man_att_report: null,
};
const attendanceSlice = createSlice({
  name: "ATTENDANCE",
  initialState,
  reducers: {
    fetchAttendanceReportStart() {},
    fetchAttendanceSummaryStart() {},
    fetchDialyAttendanceStart() {},
    fetchDailyAttendanceSuccess(state, action) {
      state.daily_att = action.payload;
    },
    fetchAttendanceReportSuccess(state, action) {
      state.report = action.payload;
    },
    fetchAttendanceSummarySuccess(state, action) {
      state.summary = action.payload;
    },
    saveManualAttendanceStart() {},
    saveManualResult(state, action) {
      state.result = action.payload;
    },
    fetchAttendanceStart(state, action) {
      state.params = action.payload;
    },
    fetchAttendanceSuccess(state, action) {
      state.attendance = action.payload;
    },
    fetchStudentSuccess(state, action) {
      state.students = action.payload;
    },
    resetAttendance(state) {
      state.params = null;
      state.stuffToSave = null;
    },
    saveAttendanceStart(state, action) {
      state.stuffToSave = action.payload;
    },
    saveAttendanceSuccess(state, action) {
      state.attendance = action.payload;
    },
    saveSMSreport(state, action) {
      state.smsReport = action.payload;
    },
    clearAttendance(state) {
      state.students = [];
      state.attendance = [];
      state.smsReport = [];
      state.report = null;
      state.daily_att = null;
      state.sms_tasks = null;
      state.punches_report = null;
      state.single_man_report = null;
      state.single_report = null;
    },
    fetchSMSTasksStart() {},
    fetchSMSTasksSuccess(state, action) {
      state.sms_tasks = action.payload;
    },
    fetchPuncesReportStart() {},
    fetchPuncesReportSuccess(state, action) {
      state.punches_report = action.payload;
    },
    fetchSinglePuncesReportStart() {},
    fetchSinglePuncesReportSuccess(state, action) {
      state.single_report = action.payload;
    },
    fetchSingleStudentManAttReportStart() {},
    fetchSingleStudentManAttReportSuccess(state, action) {
      state.single_man_report = action.payload;
    },
    fetchCumulativeAttReportStart() {},
    fetchCumulativeAttReportSuccess(state, action) {
      state.cumulative_man_att_report = action.payload;
    },
  },
});

export const {
  fetchAttendanceStart,
  fetchAttendanceSuccess,
  resetAttendance,
  fetchStudentSuccess,
  saveAttendanceStart,
  saveAttendanceSuccess,
  clearAttendance,
  saveSMSreport,
  fetchAttendanceReportStart,
  fetchAttendanceSummaryStart,
  fetchAttendanceReportSuccess,
  fetchAttendanceSummarySuccess,
  saveManualAttendanceStart,
  saveManualResult,
  fetchDialyAttendanceStart,
  fetchDailyAttendanceSuccess,
  fetchSMSTasksStart,
  fetchSMSTasksSuccess,
  fetchPuncesReportStart,
  fetchPuncesReportSuccess,
  fetchSinglePuncesReportSuccess,
  fetchSinglePuncesReportStart,
  fetchSingleStudentManAttReportStart,
  fetchSingleStudentManAttReportSuccess,
  fetchCumulativeAttReportStart,
  fetchCumulativeAttReportSuccess,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
