import { createSlice } from "@reduxjs/toolkit";
// utils
import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  process: "",
  error: "",
  status: "",
  user: "",
  users: [],
  counsellors: [],
  searchParams: {},
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    resetUserManagement(state) {
      state.process = "";
      state.error = "";
      state.status = "";
      state.user = "";
    },
    fetchCounsellorsStart(state, action) {
      state.searchParams = action.payload;
    },
    fetchCounsellorsSuccess(state, action) {
      state.counsellors = action.payload;
    },
    fetchUsersStart(state, action) {
      state.searchParams = action.payload;
      state.process = "fetch";
      state.status = "info";
    },
    fetchUsersSuccess(state, action) {
      state.status = "success";
      state.users = action.payload;
    },
    fetchUsersFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    fetchCounsellorsFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    createUserStart(state, action) {
      state.user = action.payload;
      state.status = "info";
      state.process = "register";
    },
    createUserSuccess(state) {
      state.status = "success";
    },
    createSuperAdminStart(state, action) {
      state.user = action.payload;
      state.status = "info";
      state.process = "register";
    },
    createSuperAdminSuccess(state) {
      state.status = "success";
    },
    createUserFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    updateUserStart(state, action) {
      state.user = action.payload;
      state.status = "info";
      state.process = "update";
    },
    updateUserSuccess(state) {
      state.status = "success";
    },
    updateUserFailure(state, action) {
      state.error = action.payload;
      state.status = "error";
    },
    updateUsersList(state, action) {
      const list = state.users;
      state.users = getUpdatedList(action.payload, list);
    },
    clearCounsellorsList(state) {
      state.counsellors = [];
    },
  },
});

export const {
  resetUserManagement,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFailure,
  createUserStart,
  createUserFailure,
  createUserSuccess,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  updateUsersList,
  fetchCounsellorsStart,
  fetchCounsellorsSuccess,
  fetchCounsellorsFailure,
  createSuperAdminStart,
  createSuperAdminSuccess,
  clearCounsellorsList,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
