import AWS from "aws-sdk";

AWS.config.update({
  region: "ap-south-1",
  accessKeyId: "AKIA6GBMCSGHBX4DJK4G",
  secretAccessKey: "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",
});

AWS.config.apiVersions = {
  lambda: "2015-03-31", /// ignore if latest version
};

const lambda = new AWS.Lambda();

export const dynamoDBClient = new AWS.DynamoDB.DocumentClient({
  convertEmptyValues: true,
});

export default lambda;
